import { useState } from "react";
import styled from "styled-components";
import editIcon from "../../../assets/IconComponents/EditIcon.svg";
import unchecked from "../../../assets/IconComponents/retailerUnchecked.svg";
import checked from "../../../assets/IconComponents/retailerChecked.svg";
import saveIcon from "../../../assets/IconComponents/multipleEditionIcons/saveIcon.svg";
import { useEffect } from "react";

const PopUpContainer = styled.div`
  background-color: #f0eef2;
  border-radius: 7px;
  position: absolute;
  top: calc(100% + 10px);
  padding: 10px 14px;
  z-index: 10;

  .categories-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #817393;

      & + button {
        margin-left: 15px;
      }
    }

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &.category-container {
    width: 426px;
    left: 0;
  }

  &.chk-inputs {
    width: 534px;
    max-height: 200px;
    overflow: auto;
    right: 0;
    padding: 13px 20px;

    .retailers-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .retailer-container {
        flex: 1 0 25%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        input {
          display: none;

          &:checked {
            & + label {
              &:before {
                background-image: url(${checked});
              }
            }
          }
        }

        label {
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: Avenir Next;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 21px;
          color: #817393;
          position: relative;
          padding-left: 25px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(${unchecked});
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        & + * {
          margin-top: 5px;
        }

        &:nth-child(-n + 4) {
          margin-top: 0;
        }
      }

      button {
        position: absolute;
        right: 5px;
        background-color: transparent;
        border: none;
        width: 30px;
        height: 30px;
        cursor: pointer;

        &.save-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;

          img {
            background: #f0eef2;
            width: 17px;
            height: 17px;
          }
        }

        & + * {
          margin-left: 10px;
        }
      }
    }
  }
`;

const PopUpEdit = (props) => {
  const [retailersSelected, setRetailersSelected] = useState({});

  const updateRetailersSelected = (retailerId, isSelected) => {
    const isPreviouslySelected = retailersSelected.current.includes(retailerId);
    if (isSelected) {
      if (isPreviouslySelected) {
        const toDelete = retailersSelected.delete.filter(
          (f) => f !== retailerId
        );
        setRetailersSelected((prev) => ({ ...prev, delete: toDelete }));
        return;
      }
      const toAdd = retailersSelected.add.slice();
      toAdd.push(retailerId);
      setRetailersSelected((prev) => ({ ...prev, add: toAdd }));
    } else {
      if (isPreviouslySelected) {
        const toDelete = retailersSelected.delete.slice();
        toDelete.push(retailerId);
        setRetailersSelected((prev) => ({ ...prev, delete: toDelete }));
      } else {
        const toAdd = retailersSelected.add.filter((f) => f !== retailerId);
        setRetailersSelected((prev) => ({ ...prev, add: toAdd }));
      }
    }
  };

  useEffect(() => {
    const ids = props.retailers.filter((f) => f.checked).map(({ id }) => id);
    setRetailersSelected({ current: ids, add: [], delete: [] });
  }, [props.retailers]);

  return (
    <PopUpContainer id="popup-component" className={props.className}>
      <div className="elements-container">
        {props.categories && (
          <div className="categories-container">
            <p>{props.categories}</p>
            <button>
              <img src={editIcon} alt="Edit icon" />
            </button>
          </div>
        )}
        {props.retailers && (
          <div id={props.id} className="retailers-container">
            {props.retailers.map((retailer, index) => (
              <div key={index} className="retailer-container">
                <input
                  type="checkbox"
                  id={"chk" + index}
                  name={"chk" + index}
                  defaultChecked={retailer.checked}
                  onChange={(e) => {
                    updateRetailersSelected(retailer.id, e.target.checked);
                  }}
                />
                <label htmlFor={"chk" + index}>{retailer.name}</label>
              </div>
            ))}
            <button className="save-icon">
              <img
                src={saveIcon}
                alt="Save"
                onClick={() => props.handleUpdateCadena(retailersSelected)}
              />
            </button>
          </div>
        )}
      </div>
    </PopUpContainer>
  );
};

export default PopUpEdit;
