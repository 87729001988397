import { Alert, Snackbar } from "@mui/material";

export const NOTIFICATION_SUCCESSFUL = "success";
export const NOTIFICATION_ERROR = "error";

export default function CustomNotification({
  open, // boolean
  alerts = [], // [ { id , type , message } ]
  deleteNotification, // (idAlert) => {}
  ...props
}) {
  return (
    <Snackbar {...props} className={props.className} open={open}>
      <div className="notification-content">
        {alerts.map((a) => {
          return (
            <Alert
              className="alert-container"
              key={a.id}
              severity={a.type}
              onClose={() => {
                deleteNotification(a.id);
              }}
            >
              {a.message}
            </Alert>
          );
        })}
      </div>
    </Snackbar>
  );
}
