import styled from "styled-components";

export const Button = styled.div`
  background: #e33aa9;
  border-radius: 40px;
  color: white;
  width: 160px;
  height: 41px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerPrincipal = styled.div`
  width: 100%;
  padding: 10px 10px 10px 10px;
`;

export const ContainerIcon = styled.div`
  border: 1px solid #f0eef2;
  border-radius: 50%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  :hover {
    cursor: pointer;
  }
`;

export const ContainerPIcons = styled.div`
  width: 9vw;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 3.5rem;
  justify-content: space-between;
`;

export const HeaderImg = styled.img`
  max-width: 100%;
  height: auto;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

export const HeaderLogo = styled.a`
  color: #19181b;
  font-weight: 500;
  display: none;
  text-decoration: none;
`;

export const HeaderSearch = styled.div`
  display: flex;
  padding: 0.4rem 0.75rem;
  background-color: #f4f0fa;
  border-radius: 0.25rem;
`;

export const HeaderInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: #f4f0fa;
  &::placeholder {
    font-family: "Poppins", sans-serif;
    color: #58555e;
  }
`;

export const HeaderToggle = styled.div`
  color: #19181b;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  height: 93vh;
  width: 200px;
  padding: 1rem 1rem 0;
  background-color: #ffffff;
  z-index: 0;
  transition: 0.4s;
`;

export const Navbar = styled.nav`
  height: 100%;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  border-radius: 20px;
  background-color: #603888;
  scrollbar-width: none;
`;

export const ContainOrderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const InputFormSearch = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem 0 3.5rem;
  align-items: flex-end;
  position: relative;

  > input {
    width: 100%;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 30px;
    padding-left: 10px;
  }

  &:before {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-image: url(${(props) => props.search});
    background-repeat: none;
    right: 25px;
    bottom: 8px;

    position: absolute;
  }
`;

export const ButtonAdd = styled.div`
  background: #603888;
  cursor: pointer;
  border-radius: 30px;
  margin-top: 15px;
  width: 200px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
`;

export const NavLogo = styled.a`
  font-weight: 600;
  padding: 2rem 1rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #58555e;
`;

export const NavLogoName = styled.span`
  opacity: 0;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
`;

export const NavItems = styled.div`
  row-gap: 1.5rem;
  display: grid;
`;

export const NavList = styled.div`
  row-gap: 2.5rem;
`;

export const NavLinks = styled.div`
  margin: 0 15px 0 26px;
`;

export const NavSubtitle = styled.h3`
  font-size: 0.938rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #a5a1aa;
`;

export const NavLink = styled.a`
  display: flex;
  width: 190px;
  height: 34px;
  align-items: center;
  color: #58555e;
  &:hover {
    border-radius: 18px;
    background-color: #e33aa9;
  }
`;

export const NavName = styled.span`
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #fcfcfc;
`;

export const NavDropdownCollapse = styled.div`
  background-color: #a5a1aa;
  border-radius: 0.25rem;
  margin-top: 1rem;
`;

export const NavDropdown = styled.div`
  overflow: hidden;
  max-height: 21px;
  transition: 0.4s ease-in-out;
  &:hover {
    max-height: 100rem;
  }
`;

export const NavDropdownContent = styled.div`
  display: grid;
  row-gap: 0.5rem;
  padding: 0.75rem 2.5rem 0.75rem 1.8rem;
`;

export const NavDropdownItem = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: #58555e;
`;

export const Tables = styled.table`
  width: 98%;
`;

export const Containers = styled.div`
  display: flex;
`;

export const ContainerScroll = styled.div`
  height: 60vh;
  width: 100%;
  overflow-y: scroll;
`;

export const TablesHead = styled.thead`
  width: 100%;
`;

export const TablesHeadRow = styled.tr`
  width: 100%;
  border-bottom: 2px solid #f0eef2;
`;

export const TablesRowH = styled.th`
  border: none;
  border-right: 1px solid #f0eef2;
  height: 30px;
  box-sizing: border-box;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: -0.015em;
  /* Gray S5 */
  color: #503d66;
`;

export const TablesRowD = styled.td`
  border-left: 1px solid #f0eef2;
  border-right: 1px solid #f0eef2;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  height: 30px;
  text-align: center;
  letter-spacing: -0.015em;
  box-sizing: border-box;
  /* Gray S5 */
  color: #503d66;
  padding: 5px 10px 5px 10px;
  text-align: center;

  &.checkbox-item {
    max-width: 28px;
    padding: 0;
  }
`;

export const ContainerChart = styled.td`
  padding-right: 25px;
`;

export const TitleOrders = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #707070;
  &.work-orders-title {
    & + * {
      margin-top: 15px;
    }
  }
`;

export const TableInvoicing = styled.table`
  width: 100%;
  border: none;
  display: block;

  * {
    display: block;
  }

  .tr-row-detail {
    display: flex;
    border: none;
    font-style: normal;
    font-family: Avenir Next;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #503d66;
    text-align: left;
    padding: 10px 0;
  }

  th,
  td {
    cursor: text;
    user-select: text;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.article-name {
      max-width: 100px;
      overflow: hidden;
    }

    &:nth-child(1),
    &:nth-child(2) {
      min-width: 110px;
    }
  }

  tbody tr {
    display: flex;
    border-top: 1px solid #f0eef2;
    height: 25px;
    align-items: center;
  }
  tbody tr:last-child {
    border-bottom: 1px solid #f0eef2;
  }
  tbody tr td {
    border: none;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    text-align: left;
    letter-spacing: -0.015em;
    /* Gray S4 */
    color: #817393;
  }
`;

export const RowStatus = styled.div`
  background-color: ${(props) => props.bg};
  font-family: Lato;
  width: 50%;
  height: 20px;
  margin: 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: #ffffff;
  border-radius: 3px;
  text-align: center;
`;

export const RowStatus2 = styled.div`
  background-color: ${(props) => props.bg};
  font-family: Lato;
  width: 20px;
  height: 20px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */
  letter-spacing: -0.015em;
  color: #ffffff;
  border-radius: 3px;

  &.modal-status {
    width: 48px;
  }
`;

export const InputSearchRight = styled.input`
  margin-top: 10px;
  width: 173.2px;
  height: 30px;
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 30px;
`;

export const HeaderTitleRCont = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  padding-top: 1.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  justify-content: ${(props) => props.justifycontent};
  flex-direction: ${(props) => props.flexdirection};
  align-items: ${(props) => props.align};
  flex-wrap: ${(props) => props.flexwrap};
`;

export const Container2 = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  justify-content: space-between;
  flex-direction: ${(props) => props.flexdirection};
  align-items: ${(props) => props.align};
  flex-wrap: ${(props) => props.flexwrap};

  .btn-close-modal {
    border: none;
    background-color: transparent;
  }
`;

Container.defaultProps = {
  width: "100%",
  padding: "0",
  flexdirection: "row",
  justifycontent: "space-around",
  flexwrap: "nowrap",
};

export const HeaderEstatus = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: #817393;
  > div {
    background: ${(props) => props.bg};
    width: 10px;
    height: 10px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  padding: 1rem 3rem;
  background: #f0eef2;
  border-radius: 7px;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  div:first-child {
    padding: 0 0 1rem 0;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #817393;
  }
  div:nth-child(2) {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #817393;
  }
  div:nth-child(3) {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #817393;
  }
`;

export const ContainerTop = styled.div`
  height: calc(100vh - 320px);
  border-bottom: 1px solid #c4c4c4;
  padding: 14px 12px;
`;

export const Title = styled.span`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 12px;
  letter-spacing: -0.015em;
  color: #503d66;
`;

export const ButtonQuoteInvoicing = styled.div`
  border: 1px solid #603888;
  box-sizing: border-box;
  border-radius: 12px;
  background: #fbfbfb;
  color: #603888;
  font-family: Raleway;
  padding: 0 10px;
  width: 128px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonQuoteInvoicing2 = styled.div`
  border: 1px solid #603888;
  box-sizing: border-box;
  border-radius: 30px;
  background: #fbfbfb;
  font-family: Raleway;
  height: 30px;
  padding: 0 10px;
  width: 198px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  color: #503d66;
`;

export const Subtitle = styled.div`
  cursor: text;
  user-select: text;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: -0.015em;
  color: #503d66;
`;

export const ContainerBar = styled.div`
  width: 440px;
  height: 30px;
  background-color: #f0eef2;
  border-radius: 30px;
`;

export const Bar = styled.div`
  width: ${(props) => props.percentage}%;
  box-sizing: border-box;
  padding: 0 0.5rem;
  text-align: right;
  border: 1px solid #ed9a4d;
  height: 30px;
  background-color: #ffffff;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.015em;
  color: #817393;
  border-radius: 30px;
  &:hover {
    background: #ed9a4d;
    border-radius: 30px;
    cursor: pointer;
    color: #ffffff;
  }
`;

export const rowTable = styled.tr`
  background-color: red;
  &hover {
    cursor: pointer;
  }
`;

export const HeaderTitleR = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #b12d84;
`;

export const HeaderSubtitleR = styled.div`
  padding-top: 1rem;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.015em;
  color: #817393;
`;

export const ButtonInvoicing = styled.div`
  background: #e33aa9;
  border-radius: 40px;
  color: white;
  width: 195px;
  height: 41px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerImg = styled.img`
  width: 240px;
`;

export const Progressdiv = styled.div`
  width: 201.37px;
  height: 23px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
  border: 1px solid ${(props) => props.color};
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
`;

export const HeaderTitle = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  letter-spacing: -0.015em;
  color: #b12d84;
  float: left;
`;

export const NumberProduct = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  /* identical to box height, or 100% */
  text-align: center;
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;

export const TitleImages = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;

Container.defaultProps = {
  width: "100%",
  padding: "0",
  flexdirection: "row",
  justifycontent: "space-around",
};

export const TitleDate = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  /* or 86% */
  text-align: center;
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;

export const ContainerLeft = styled.div`
  padding: 0 10px 0 0;
`;

export const TitleContainCat = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;

export const SubtitleContainCat = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  /* or 108% */
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;

export const TitleCoantin = styled.div`
  background: #f0eef2;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 5px 5px 10px;
  line-height: 18px;
  /* identical to box height, or 129% */
  border-radius: 7px;
  letter-spacing: -0.015em;
  /* Gray S4 */
`;

export const TitleFooter = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 200% */
  letter-spacing: -0.015em;
  /* Gray S5 */
  color: #503d66;
`;

export const TitleComents = styled.div`
  background: #f0eef2;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  /* or 108% */
  box-sizing: border-box;
  padding: 10px 21px 10px 10px;
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
  border-radius: 7px;
  /* Gray S4 */
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const TitleCoantinVersion = styled.div`
  background: #f0eef2;
  min-height: 20px;
  width: 93px;
  display: flex;
  padding: 2px 5px;
  justify-content: space-around;
  border-radius: 15px;
  color: #817393;
  /* identical to box height, or 150% */
  /* Gray S4 */
`;

export const TitleVersion = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: -0.015em;
`;

export const ContainerPrincipalSide = styled.button`
  border: none;
  width: 592px;
  height: calc(100% - 100px);
  position: fixed;
  right: -592px;
  top: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  padding: 10px;
  display: flex;
  flex-direction: column;
  transition: right 0.5s;

  > div {
    display: flex;
  }
  .table {
    height: calc(100% - 360px);
    overflow-y: scroll;
  }
  .footer {
    position: absolute;
    bottom: 87px;
  }
  .footerBotton {
    position: absolute;
    bottom: 10px;
  }
  .btnClose {
    padding: 13px 20px 20px 20px;
    margin-left: 400px;
    div {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      display: flex;
      justify-content: center;
      color: #ffffff;
      background: #603888;
      border-radius: 30px;
      width: 102px;
      height: 44px;
    }
  }

  &.modal-container {
    right: 0;
    z-index: 10;
  }
`;

export const ContainProgress = styled.div`
  width: 103px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.015em;
  height: 20px;
  color: #ffffff;
  background: #ed9a4d;
  border-radius: 15px;
`;

///**** ArticleDetails ****/

export const ArticleDetails = styled.div`
  #containerDetail {
    width: 100%;
    height: auto;
    position: relative;
  }
`;

export const Switcher = styled.td`
  max-width: 12px;
  right: 10px;
  background-color: ${(props) => (!props.showDetails ? "#E33AA9" : "")};
  height: 100%;
  text-align: center !important;
  border: 1px solid #e33aa9;

  img {
    width: 10px;
    margin: auto;
    height: 10px;
    cursor: pointer;
    margin-top: 7px;
  }
`;
