export const getCategoriesString = (categoriesArray) => {
  const newArray = [];
  categoriesArray?.forEach(({ category }) => {
    newArray.push(...category.split("|"));
  });

  const categoriesString =
    newArray.length > 2
      ? newArray[0] +
        ", " +
        newArray[1] +
        " y " +
        (newArray.length - 2) +
        " más"
      : newArray.join("|");

  return categoriesString;
};
