import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { membershipLimitations } from "../../_utils/helper";

// components
import GenericModal from "../../general/GenericModal";
import { InviteUser } from "./InviteUser";
import Loading from "../../general/loading";
import { CheckboxCustom } from "../../customInputs/checkboxCustom";

// SVG assets
import trash from "../../../assets/IconComponents/trash.svg";
import face from "../../../assets/IconComponents/face.svg";
import { CustomSelect } from "contentoh-components-library";
import searchIcon from "../../../assets/IconComponents/search.svg";
import { getCategoriesString } from "./profileUtils";

const MainContainer = styled.div`
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .filters-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .filters {
      display: flex;
      align-items: center;

      .search-user-input {
        display: flex;
        align-items: center;
        border: 1px solid #f0f0f0;
        border-radius: 50px;
        padding: 9px;

        img {
          width: 14px;
          height: 14px;
          & + * {
            margin-left: 15px;
          }
        }
        input {
          font-family: Roboto;
          font-size: 12px;
          outline: none;
          border: none;
        }
      }

      & > * + * {
        margin-left: 20px;
      }
    }

    .buttons-container {
      display: flex;

      button {
        background-color: transparent;

        &.trash-icon {
          img {
            width: 32px;
          }
        }

        &.circular-button {
          border-radius: 50%;
          overflow: hidden;
          width: 34px;
          height: 34px;
          border: 1px solid #f0f0f0;

          & + * {
            margin-left: 5px;
          }
        }

        &.add-users-button {
          background-color: #e33aa9;
          border-radius: 50px;
          color: #ffffff;
          font-family: Roboto;
          font-size: 12px;
          padding: 10px 20px;
          border: none;
        }
      }
    }
  }
`;

const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 24px 0px;
  :last-child {
    border-bottom: 0px;
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }
`;

export const BasicData = styled(Section)`
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #817393;
  width: 100%;

  .text_center {
    text-align: center;
  }
  .space {
    justify-content: space-between;
  }
  .align_end {
    justify-content: flex-end;
  }
  .margin_row {
    .col.chk-cont {
      display: flex;
      justify-content: flex-start;
      & > div {
        & + * {
          margin-left: 5px;
        }
      }
      & > div:nth-child(2) {
        & > div {
          width: 90%;
        }
      }
    }
    .col + * {
      margin-left: 5px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;

    p {
      color: #817393;

      & + * {
        margin-top: 5px;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .col {
      max-width: 23%;
      flex: 1 1 20%;
      overflow: hidden;
      align-items: center;

      figure {
        & + * {
          margin-left: 10px;
        }
      }

      &:first-child {
        padding: 10px;
        align-items: center;
        overflow: inherit;
      }
    }

    &.header {
      background-color: #f0f0f0;
      height: 34px;

      .col {
        color: #262626;
        margin-left: 5px;
        text-align: left;
      }
    }
  }
  .center {
    justify-content: center;
    align-items: center;
  }
  input[type="email"] {
    width: 360px;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 7px;
    margin-right: 20px;
    ::placeholder {
      color: #d4d1d7;
    }
  }
  input[type="checkbox"]:checked {
    width: 75px;
    background-color: #e33aa9;
    color: green;
  }
  select {
    width: 116px;
    height: 30px;
    padding: 0 8px;
    border: 1px solid #e33aa9;
    border-radius: 7px;
    box-sizing: border-box;
    background: #fff;
  }
  button {
    width: 130px;
    height: 30px;
    font-size: 14px;
    line-height: 19px;
    color: #e33aa9;
    background: #fff;
    border: 1px solid #e33aa9;
    box-sizing: border-box;
    border-radius: 30px;
    :hover {
      cursor: pointer;
    }
  }
`;

export default function AddPeople(props) {
  const [listUsers, setListUsers] = useState([]);
  const [listUsersFilter, setListUsersFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colabLimitReached, setColabLimitReached] = useState(false);
  const [upgradePlanRedirect, setUpgradePlanRedirect] = useState(false);
  const [modalData, setModalData] = useState({});
  const [categories, setCategories] = useState([]);
  const [showInvite, setShowInvite] = useState(false);
  const [userName, setUserName] = useState("");
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [positions, setPositions] = useState([]);
  const [positionsSelected, setPositionsSelected] = useState([]);
  const [canUpdateRol, setCanUpdateRol] = useState(false);

  const getUsers = async () => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_USER_ENDPOINT}?isColaborators=1`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const collaborators = JSON.parse(response.data.body).data[2];
    const [positionResponse] = JSON.parse(response.data.body).data[3];
    setPositions(
      positionResponse.split(",").map((pos) => {
        return { id: pos.trim(), name: pos.trim() };
      })
    );
    setListUsers(collaborators);
    setListUsersFilter(collaborators);
    sessionStorage.setItem("collaborators", JSON.stringify(collaborators));
    setLoading(false);
  };

  const filterUsers = async (categoriesArray, martketStall) => {
    setLoading(true);
    const categoriesIds = categoriesArray.length
      ? `&categoriesIds=${categoriesArray.join(",")}`
      : "";
    const positionsIds = martketStall.length
      ? `&position=${martketStall.join(",")}`
      : "";
    const query = `${categoriesIds}${positionsIds}`;
    const response = await axios.get(
      `${process.env.REACT_APP_USER_ENDPOINT}?isColaborators=1${query}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const collaborators = JSON.parse(response.data.body).data[2];
    setListUsers(collaborators);
    setListUsersFilter(collaborators);
    sessionStorage.setItem("collaborators", JSON.stringify(collaborators));
    setLoading(false);
  };

  const getCategories = async () => {
    const company = JSON.parse(sessionStorage.getItem("company"));
    const user = JSON.parse(sessionStorage.getItem("user"));
    const key = company.financedRetailers ? "financedRetailers" : "retailers";
    const isRetailer = user.is_retailer === 0;
    const query =
      isRetailer || key === "financedRetailers"
        ? `?id=${encodeURIComponent(
            company[key].map((ret) => ret.id).join(",")
          )}`
        : "";
    const response = await axios.get(
      `${process.env.REACT_APP_CATEGORY_ENDPOINT}${query}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const categories = JSON.parse(response.data.body)?.data;
    const categoryValues = [];
    let categoryData = {};

    Object.entries(categories).forEach(
      ([categoryRoot, categoryBranch], index) => {
        categoryData = {};
        categoryData["id"] = index;
        categoryData["name"] = categoryRoot;
        const subOptions = [];
        const subCategoryBranch = categoryBranch.sub_category;
        // obtener el arreglo de las categorias principales
        const subCategoriesKeys = Object.keys(subCategoryBranch);
        // obtener el primer objeto que nos permita iterar las demas sub categorias
        const categoryleaf = subCategoriesKeys.map(
          (subCategory) => subCategoryBranch[subCategory]
        );
        categoryleaf?.forEach((element) => {
          element &&
            Object.keys(element || {}).forEach((key) => {
              const subCategory = element?.sub_category || {};
              Object.keys(subCategory)?.forEach((subKey) => {
                subOptions.push({
                  id: subCategory[subKey].id_category,
                  name: subKey,
                });
              });
            });
        });
        categoryData["subOptions"] = subOptions;
        categoryValues.push(categoryData);
      }
    );

    categoryValues.sort((a, b) => a.name.localeCompare(b.name));
    setCategories(categoryValues);
    setLoading(false);
  };

  const searchUser = (userName = "") => {
    const tempArray = listUsers.slice();
    if (userName.length > 0) {
      setListUsersFilter(
        tempArray.filter((item) =>
          item?.name?.toLowerCase().includes(userName.toLowerCase())
        )
      );
    } else {
      setListUsersFilter(tempArray);
    }
  };

  const loadData = async () => {
    await getUsers();
    await getCategories();
  };

  useEffect(() => {
    loadData();
    const { id_role, is_retailer } = JSON.parse(sessionStorage.getItem("user"));
    if (is_retailer === 1 && id_role === 0) {
      setCanUpdateRol(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (upgradePlanRedirect) {
      setColabLimitReached(false);
      props.history.push({ pathname: `/upgradeplan` });
      setUpgradePlanRedirect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upgradePlanRedirect]);

  useEffect(() => {
    if (categoriesSelected.length || positionsSelected.length)
      filterUsers(categoriesSelected, positionsSelected);
    else getUsers();
  }, [categoriesSelected, positionsSelected]);

  const onSubmit = async (emailCollaborator, department, position) => {
    console.log(emailCollaborator, department, position);
    try {
      const isValid = await membershipLimitations("colab");
      if (isValid) {
        let body = {
          emailCollaborator,
          department,
          position,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_USER_ENDPOINT}`,
          body,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );
        if (res.data.statusCode === 200) {
          setLoading(true);
          setModalData({
            img: face,
            message: `Se creó el usuario ${body.emailCollaborator} correctamente`,
            detail: "Se le envió un correo con una contraseña temporal",
            button2: { name: "Aceptar", action: () => setShow(false) },
          });
          loadData();
        } else {
          setModalData({
            message: `Ocurrió un error al crear la cuenta ${body.emailCollaborator}`,
            detail: "Asegúrese que no se encuentra registrado actualmente",
            button2: { name: "Aceptar", action: () => setShow(false) },
          });
        }
        setShow(true);
      } else {
        setColabLimitReached(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCollaborator = async () => {
    try {
      const responseArray = [];
      usersSelected.forEach((userId) => {
        responseArray.push(
          axios({
            method: "delete",
            url: process.env.REACT_APP_USER_ENDPOINT,
            data: { idUser: userId },
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          })
        );
      });
      await Promise.all(responseArray);
      getUsers();
      setModalData({
        img: face,
        message: `Se eliminó correctamente`,
        button2: { name: "Aceptar", action: () => setShow(false) },
      });
      setShow(true);
    } catch (err) {
      console.log(err);
    }
  };

  const chkOnChange = (e, id) => {
    const tempArray = usersSelected.slice();
    if (e.target.checked) {
      tempArray.push(id);
      setUsersSelected(tempArray);
    } else {
      setUsersSelected(tempArray.filter((item) => item !== id));
    }
  };

  const onChangeName = (e) => {
    setUserName(e.target.value);
    searchUser(e.target.value);
  };

  const onChangeRol = async (rol, user) => {
    await axios.put(
      `${process.env.REACT_APP_USER_ROL}?boolUser=1`,
      { rol, user },
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
  };

  const getFirstSecondColumn = (e, i) => {
    let first = (
      <p className="col">{e.name ? `${e.name} ${e.last_name || ""}` : "---"}</p>
    );
    let second;
    if (canUpdateRol) {
      second = first;
      const options = [
        {
          id: 0,
          name: "Administrador",
          isSelected: e.id_role === 0,
          function: () => onChangeRol(0, e.id_user),
        },
        {
          id: 11,
          name: "eCommerce",
          isSelected: e.id_role === 11,
          function: () => onChangeRol(11, e.id_user),
        },
        {
          id: 12,
          name: "Merchant",
          isSelected: e.id_role === 12,
          function: () => onChangeRol(12, e.id_user),
        },
      ];
      first = (
        <CustomSelect
          options={options}
          customSelectId="rol-select"
          isSingleSelect
          onClickItem={() => {}}
          parameterArray={[]}
          setParameterArray={() => {}}
        />
      );
    }
    return (
      <>
        <div className="col chk-cont">
          <CheckboxCustom
            id={"chk-" + i}
            htmlFor={"chk-" + i}
            onChange={(evt) => chkOnChange(evt, e.id_user)}
          />
          {first}
        </div>
        {second}
      </>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <MainContainer>
      <div className="filters-container">
        <div className="filters">
          <div className="search-user-input">
            <img src={searchIcon} alt="search-icon" />
            <input
              type="text"
              placeholder="Buscar usuarios"
              value={userName}
              onChange={onChangeName}
            />
          </div>
          <CustomSelect
            options={categories}
            showSearchBar={true}
            placeHolder={"Buscar departamento"}
            selectLabel={"Departamentos"}
            customSelectId={"category-select"}
            defaultOption={"Todos los departamentos"}
            parameterArray={categoriesSelected}
            setParameterArray={setCategoriesSelected}
          />
          <CustomSelect
            options={positions}
            showSearchBar={true}
            placeHolder={"Buscar puesto"}
            selectLabel={"Puesto"}
            customSelectId={"position-select"}
            defaultOption={"Todas los posiciones"}
            parameterArray={positionsSelected}
            setParameterArray={setPositionsSelected}
          />
        </div>
        <div className="buttons-container">
          <button
            className="trash-icon circular-button"
            onClick={() => {
              if (usersSelected.length > 0) {
                setModalData({
                  message: `¿Estás seguro que quieres eliminar estos usuarios?`,
                  button1: {
                    name: "Cancelar",
                    action: () => setShow(false),
                  },
                  button2: {
                    name: "Confirmar",
                    action: () => {
                      setShow(false);
                      deleteCollaborator();
                    },
                  },
                });
                setShow(true);
              }
            }}
          >
            <img src={trash} alt="trash icon" />
          </button>
          <button
            className="add-users-button"
            onClick={() => setShowInvite(true)}
          >
            Agregar usuarios
          </button>
        </div>
      </div>

      <BasicData canUpdateRol={canUpdateRol}>
        <div className="row header">
          {canUpdateRol ? <p className="col">Rol</p> : null}
          <p className="col">Nombre</p>
          <p className="col text_center">Correo</p>
          <p className="col">Puesto</p>
          <p className="col">Categorías</p>
        </div>
        {listUsersFilter.length > 0 &&
          listUsersFilter.map((e, i) => (
            <div className="row margin_row" key={i}>
              {getFirstSecondColumn(e, i)}
              <p className="col">{e.email ? e.email : "---"}</p>
              <p className="col">{e.position ? e.position : "---"}</p>
              <p className="col">
                {typeof e.categoryName !== "string"
                  ? getCategoriesString(e.categoryName)
                  : e.categoryName}
              </p>
            </div>
          ))}
      </BasicData>
      {show && <GenericModal close={() => setShow(false)} {...modalData} />}
      {colabLimitReached && (
        <GenericModal
          close={() => setColabLimitReached(!colabLimitReached)}
          message="Excediste el número máximo de colaboradores permitidos en tu plan"
          button1={{
            name: "Entendido",
            action: () => setColabLimitReached(!colabLimitReached),
          }}
          button2={{
            name: "Mejorar mi plan",
            action: () => {
              setUpgradePlanRedirect(true);
            },
          }}
        />
      )}
      {showInvite && (
        <InviteUser
          categories={categories}
          setShowInvite={setShowInvite}
          onSubmit={onSubmit}
        />
      )}
    </MainContainer>
  );
}
