import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 21px;
  line-height: 3;
  width: ${({ width }) => width || "100%"};
  overflow: hidden;
  border-radius: 0.25em;
  &::after {
    border-left: 1px solid #e33aa9;
    border-top: 1px solid #e33aa9;
    width: 4px;
    content: "";
    height: 4px;
    position: absolute;
    right: 7px;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    top: 35%;
  }
  &::before {
    border-left: 1px solid #e33aa9;
    width: 1px;
    content: "";
    height: 15px;
    right: 15px;
    position: absolute;
    z-index: 2;
  }
`;

export const BackgroundEnd = styled.div`
  position: absolute;
  left: calc(100% - 18px);
  height: 100%;
  width: 18px;
  background-color: #fafafa;
`;

export const SelectCustom = styled.select`
  background: #fafafa;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
  width: ${({ width }) => width || "100%"};
  cursor: pointer;
  height: 21px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  padding: 0 5px;
  box-sizing: border-box;
  &::-ms-expand {
    display: none;
  }
  &:option {
    background-color: blue;
    &:disabled {
      color: #d4d1d7;
    }
    &:invalid {
      color: gray;
    }
  }
`;
