import React, { useState } from "react";
import { ValidationContainer } from "./styles";
import roundedChecked from "../../../assets/IconComponents/rounded-checked.svg";
import rejectedChecked from "../../../assets/IconComponents/rejected-checked.svg";
import nullChecked from "../../../assets/IconComponents/null-checked.svg";

const ValidationMenu = (props) => {
  const [validation, seValidation] = useState({
    status: "validado",
    icon: roundedChecked,
  });

  const validationStatus = [
    { status: "validado", icon: roundedChecked },
    { status: "rechazado", icon: rejectedChecked },
    { status: null, icon: nullChecked },
  ];

  return (
    <ValidationContainer className={props.className}>
      <div className="validation-selected">
        <p className="validation-title">{validation.status}</p>
        <figure className="validation-icon">
          <img src={validation.icon} alt={validation.status} />
        </figure>
      </div>
      <div className="validation-menu">
        <ul className="validation-options">
          {validationStatus.map((validation) => (
            <li className="validation-option">
              <p className="validation-title">
                {validation.status ? validation.status : "Nulo"}
              </p>
              <figure className="validation-icon">
                <img src={validation.icon} alt={validation.status} />
              </figure>
            </li>
          ))}
        </ul>
      </div>
    </ValidationContainer>
  );
};

export default ValidationMenu;
