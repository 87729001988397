import { handleFilterKey } from "../../collaborators/utils/handleFiltrKey";

const statusArray = [
  { name: "Capturando", value: "CA" },
  { name: "Asignado", value: "AS" },
  { name: "QA Facilitador", value: "IE" },
  { name: "Aprobado Coordinador", value: "AC" },
  { name: "Rechazado Coordinador", value: "RC" },
  { name: "Aprobado Auditor", value: "AA" },
  { name: "Rechazado Auditor", value: "RA" },
  { name: "Aprobado Proveedor", value: "AP" },
  { name: "Rechazado Proveedor", value: "RP" },
];

export const tableStyle = {
  className: "table-products",
  rowClassName: "table-row",
  headerStyle: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Avenir Next",
    fontWeight: 600,
    fontSize: "12px",
    color: "#503D66",
    margin: 0,
  },
  height: "670",
  width: "1440",
  headerHeight: 20,
  rowHeight: 30,

  tableStyle: {
    overflow: "unset",
  },
  rowStyle: {
    display: "flex",
    overflow: "unset",
    textAlign: "center",
    // fontFamily: "Avenir Next",
    fontSize: 13,
    width: "100%",
    color: "#817393",
  },
};

export const checkboxColumn = {
  className: "table-column",
  flexGrow: 1,
  minWidth: "3.5%",
  maxWidth: "3.5%",
  dataKey: "checked",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const orderColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "7.4%",
  minWidth: "7.4%",
  dataKey: "orderId",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const nameColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "7.7%",
  minWidth: "7.7%",
  dataKey: "article.name",
  style: {
    justifyContent: "flex-start",
    textAlign: "left",
    margin: "0",
    paddingLeft: "10px",
  },
};

export const categoryColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "8.7%",
  minWidth: "8.7%",
  dataKey: "article.category",
  style: {
    justifyContent: "flex-start",
    textAlign: "left",
    margin: "0",
    paddingLeft: "10px",
  },
};

export const retailersColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "7.4%",
  minWidth: "7.4%",
  dataKey: "retailers",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const estatusColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "7.1%",
  minWidth: "7.1%",
  dataKey: "status",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const marcaColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "7.7%",
  minWidth: "7.7%",
  dataKey: "brand",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const descServiceColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "19.3%",
  minWidth: "19.3%",
  style: {
    textAlign: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px",
    margin: "0",
  },
};

export const setFilters = (productsList, setFilterArray, setFiltersArray) => {
  let uniqueOrders = [...new Set(productsList.map((item) => item.orderId))];
  uniqueOrders = uniqueOrders.map((order) => ({
    name: order,
    value: order,
  }));

  let uniqueCategories = [
    ...new Set(productsList.map((item) => item.article.category)),
  ];
  uniqueCategories = uniqueCategories.map((cat) => ({
    name: cat,
    value: cat,
  }));

  let uniqueBrands = [...new Set(productsList.map((item) => item.brand))];
  uniqueBrands = uniqueBrands.filter((brand) => !!brand);
  uniqueBrands = uniqueBrands.map((brand) => ({
    name: brand,
    value: brand,
  }));
  const servicesOptions = [
    { name: "Datos", value: "datasheet_status" },
    { name: "Descripción", value: "description_status" },
    { name: "Imagenes", value: "images_status" },
  ];

  setFiltersArray({
    Orden: uniqueOrders,
    Estatus: statusArray,
    Marca: uniqueBrands,
    Categoria: uniqueCategories,
    Alcance: servicesOptions,
  });
  const tempArray = [];

  tempArray.push(
    { name: "Orden", active: false },
    { name: "Marca", active: false },
    { name: "Categoria", active: false },
    { name: "Estatus", active: false },
    { name: "Alcance", active: false }
  );
  setFilterArray(tempArray);
};

export const filterProducts = async (
  filtersObject,
  updateProducts,
  initialList
) => {
  const filters = Object.values(filtersObject);
  const userCheckedProducts = initialList.filter((item) => item.checked);
  let listToFilter = userCheckedProducts
    ? initialList.slice().map((item) => {
        item.checked = false;
        return item;
      })
    : initialList.slice();

  filters?.forEach((filterParams) => {
    if (filterParams.filter === "Alcance") {
      const filterScope = filterParams.values;
      listToFilter = listToFilter.filter((product) => {
        const filterAttr = filterScope.includes("datasheet_status")
          ? product.datasheet_status !== "NS"
          : product.datasheet_status === "NS";

        const filterDesc = filterScope.includes("description_status")
          ? product.description_status !== "NS"
          : product.description_status === "NS";

        const filterImages = filterScope.includes("images_status")
          ? product.images_status !== "NS"
          : product.images_status === "NS";

        return filterAttr && filterDesc && filterImages;
      });
    } else {
      const filterKey = handleFilterKey(filterParams.filter);
      listToFilter = listToFilter.filter((product) =>
        filterParams.values.includes(
          product[filterKey] || product.article[filterKey]
        )
      );
    }
  });
  updateProducts(listToFilter);
};
