import { useState } from "react";
import { ProviderProductEdition } from "contentoh-components-library";
import ContentohRequestModal from "../contentohRequestModal/ContentohRequestModal";
import SurveyPopUp from "../../popUp/SurveyPopUp/index.js";

export default function HOCProviderProductEdition(props) {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [modalSurvey, setModalSurvey] = useState(false);
  return (
    <>
      {showRequestModal ? (
        <ContentohRequestModal
          setShowContentohRequestModal={setShowRequestModal}
          setInCart={() => {}}
          concept="servicios"
        />
      ) : null}
      {modalSurvey && (
        <SurveyPopUp
          close={() => setModalSurvey(false)}
          order={props.productSelected.id_order}
          article={props.productSelected.article.id_article}
        />
      )}
      <ProviderProductEdition
        setShowContentohRequestModal={setShowRequestModal}
        {...props}
        showSurvey={setModalSurvey}
      />
    </>
  );
}
