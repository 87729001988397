import styled from "styled-components";
import { grayS2, grayS4, grayS7, grayS6, grayS5, greenS1 } from "../../variables";


// css del componente <ListCard />
export const Container = styled.div`
	width: 100%;
	grid-auto-rows: 250px;
	//overflow: hidden;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	padding-bottom: 12px;
	cursor: pointer;
	height: 100%;
	border: 2px solid ${grayS2};
	position: relative;
	//border: 1px solid red;

	.divBackground {
		//display: none;
		position: absolute;
		z-index: 10;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background-color: ${grayS6};
		opacity: 0;
		border-radius: inherit;
		transition: opacity 0.25s;
	}


	.div-imgs {
		width: 100%;
		height: auto;
		min-height: 175px;
		flex-grow: 2;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		overflow: hidden;
		padding: 7px;
		grid-gap: 7px;
		background-color: ${grayS2};
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
		//border: 2px solid blue;

		img{
			width: 100%;
			object-fit: scale-down;
			border-radius: 3px;
		}

		.transparent-img{
			opacity: 0;
		}
	}

	.container-submenu-total {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 9px;
		width: 100%;
		margin: 8px 0px 4px 0px;
		padding: 0px 3px 0px 8px;
		//border: 1px solid red;

		.btn-submenu {
			width: 22px;
			height: 22px;
			border: none;
			border-radius: 3px;
			cursor: pointer;
			background-color: transparent;
			z-index: 11;
			//border: 1px solid red;
			&:hover {
				background-color: ${grayS2};
			}
			img {
				width: 100%;
				height: 100%;
			}
		}

		.tooltip-submenu {
			border-radius: 6px;
			background-color: white;
			box-shadow: 0px 0px 6px 3px ${grayS7};
			padding: 0px;
			//border: 1px solid red;
			
			.submenu-listCard {
				flex-direction: column;
				gap: 0px;
				//border: 1px solid red;

				li {
					display: flex;
					flex-direction: row;
					gap: 6px;
					align-items: center;
					min-width: 80px;
					font-family: "Roboto Regular" , 'Segoe UI', sans-serif;
					font-size: 12px;
					font-weight: 500;
					color: #262626;
					line-height: 1.1;
					padding: 6px 11px;
					//border: 1px solid blue;
					cursor: pointer;
					&:hover {
						background-color: ${grayS2};
					}

					img{
						width: 25px;
						height: 25px;
					}
				}
			}
		}

		// div que contiene label con su tooltip
		.label-name {
			position: relative;
			flex-grow: 2;
			display: grid;
			place-items: center;
			//border: 1px solid red;
			cursor: pointer;
			z-index: 11;


			// div tooltip
			.tooltip {
				position: absolute;
				display: none;
				width: 100%;
				bottom: calc(100% + 12px );
				left: 0px;
				border-radius: 5px;
				background-color: white;
				z-index: 11;
				cursor: default;
				box-shadow: 0px 0px 6px 3px ${grayS7};
				padding: 5px 8px;
				//border: 1px solid blue;

				// triangulo inferor
				&::after {
					position: absolute;
					top: 100%;
					left: calc(50% - 10px);
					content: "";
					border-left: 9px solid transparent;
					border-right: 9px solid transparent;
					border-top: 8px solid white;	
				}

				// contenido label
				> label {
					width: 100%;
					text-align: center;
					font-family: "Roboto Medium" , 'Segoe UI' , sans-serif;
					line-height: 1.25;
					font-size: 12px;
					font-weight: bold;
					//border: 1px solid blue;
				}
			}

			// label nombre a 1 linea max
			> label {
				font-family:  "Roboto Medium" , 'Segoe UI' , sans-serif;
				line-height: 1.3;
				font-size: 12px;
				font-weight: bold;
				cursor: inherit;
				width: 100%;
				color: #262626;
				//border: 1px solid blue;

				overflow: hidden; 
				text-overflow: ellipsis; 
				display: -webkit-box; 
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;

				// cuando tenga el hover activar tooltip
				&:hover + .tooltip {
					display: flex;
				}
			}

		}
		
	}

	.label-articlesCount {
		font-family: "Roboto Regular" , 'Segoe UI' , sans-serif;
		line-height: 1.1;
		font-size: 12px;
		font-weight: 500;
		padding: 0px 8px 0px 8px;
		color: #808080;
		cursor: inherit;

		overflow: hidden; 
		text-overflow: ellipsis; 
		display: -webkit-box; 
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		//border: 1px solid blue;
	}

	// cuando la tarjeta tenga el hover
	&:hover .divBackground {
		//display: block;
		opacity: 0.3;
	}
`;