import styled from "styled-components";

export const CheckStyle = styled.input`
  width: 17px;
  height: 16px;
  cursor: pointer;
  background: #f0eef2;
  border: 1px solid #d4d1d7;
  box-sizing: border-box;
  border-radius: 2px;
`;
export const ContainerPrice = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 9px 10px 15px 20px;
`;
export const SubtitleFooter = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #817393;
  width: 369px;
`;
export const ContainerFooters = styled.div`
  display: flex;
  flex: 2;
  height: 60px;
  box-sizing: border-box;
  justify-content: space-between;
  /* Gray S2 */
  align-items: center;
  padding: 5px 0;
  background: #f0eef2;
  border-radius: 7px;
  align-items: center;
  div {
    border-right: 1px solid #d4d1d7;
    justify-content: center;
    width: 100%;
  }
  div:last-child {
    border: none;
  }
`;
export const ButtonContentoh = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: 40px;
  box-sizing: border-box;
  width: 195px;
  justify-content: center; /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
  background: #d43594;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
`;
export const TextCategory = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 20px;
  line-height: 33px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #e33aa9;
`;

export const TextCheckbox = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  padding-left: 5px;
  /* identical to box height, or 175% */
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;
export const ContainerChecks = styled.div`
  display: flex;
  width: 70%;
  padding: 16px 30px 10px 21px;
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  flex-wrap: wrap;
  margin: auto auto;
  margin-bottom: 42px;
`;
export const ContainerCheckbox = styled.div`
  display: flex;
  width: 25%;
  padding: 5px 0 5px 0;
`;
export const ContainerFormat = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 211px);
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
`;
export const ContainerTableScroll = styled.div`
  overflow: hidden;
  flex: auto;
  display: -webkit-flex;
  .col-sticky {
    border-right: 3px solid #bbb;
    overflow: hidden;
  }
`;
export const TableScroll = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  tr:first-child {
    background-color: #ccc;
  }
  tr:nth-child(2n+1) {
    background-color: #FBFBFB;
  }
  tr:last-child {
    background-color: #ccc;
  }
  td,
  th {
    height: 30px;
    font-size: 13px;
    padding: 3px;
    text-transform: capitalize;
  }
  thead tr:first-child, tr:first-child{
    border-bottom: 1px solid #C4C4C4;
   
  };
  thead tr:first-child td{
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    text-align: center;
    letter-spacing: -0.015em;
    /* Gray S4 */
    color: #817393;
  }
  }
  tr td {
    width:auto;
    padding:6px 10px;
  }
  thead tr td div{
    font-family: Avenir Next;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
/* identical to box height, or 117% */
text-align: left;
letter-spacing: -0.015em;
/* Gray S5 */
color: #503D66;
  }
  thead tr td, tr td {
    border-left:none;
    border-right: 1px solid #EDEDED;
    border-bottom:none;
    border-top:none;
    padding:6px 10px;
    background-color: white;
    font-family: Avenir Next;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 18px;
/* identical to box height, or 138% */
text-align: center;
letter-spacing: -0.015em;
/* Gray S4 */
color: #817393;
    
  }
  tr td div{
    font-family: Avenir Next;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
/* identical to box height, or 117% */
text-align: left;
letter-spacing: -0.015em;
/* Gray S5 */
color: #503D66;
`;
export const ColScrollable = styled.div`
  overflow: hidden;
  flex: auto;
  flex: 1;
  overflow: scroll;
`;

export const InputtForm = styled.input`
  background: #fafafa;
  border-radius: 2px;
  height: 21px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
`;

export const NumberTitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  /* identical to box height, or 100% */
  text-align: center;
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;
export const ContainerNumber = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: -0.015em;
  /* Gray S5 */
  color: #503d66;
`;

export const Subtitle = styled.div`
  font-family: Avenir Next;
  padding-bottom: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */
  text-align: center;
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;
export const PriceTotal = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  /* identical to box height, or 25% */
  height: 100%;
  /* Gray S5 */
  color: #503d66;
`;

export const TitleFooter = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 11px;
  line-height: 109.8%;
  /* or 26px */
  letter-spacing: -0.015em;
  /* Magenta Original */
  color: #b12d84;
`;
export const Container = styled.div`
  max-height: 100%;
  overflow-y: auto;
  font-family: Raleway;
  :nth-child(4) {
    justify-content: flex-end;
  }
`;
export const Containers = styled.div`
  display: flex;
`;
export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 17px 16px 25px 20px;
  border-bottom: 1px solid #c4c4c4;
  .ContainSpan {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    span {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.015em;
      /* Gray S5 */
      color: #503d66;
    }
  }
`;
export const ContainerFooterLeft = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;
export const ContainerFLText = styled.div`
  display: flex;
  flex-direction: column;
`;
