import { FilterContainer } from "./styles";
import { InputSection } from "./inputSection";
import { OptionsButtons } from "./buttonsSection";
import { useEffect, useState } from "react";

export const FilterInput = (props) => {
  // variables
  const [keyword, setKeyword] = useState("");
  const filters = props.filtersArray;
  const [globalFilters, setGlobalFilters] = useState({});

  useEffect(() => {
    props.filterInputFunct(globalFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters]);

  return (
    <FilterContainer taskFilter={props.taskFilter} className={props.className}>
      <InputSection
        filters={filters}
        total={props.total}
        charged={props.charged}
        setKeyword={setKeyword}
        keyword={keyword}
        filterInputFunct={props.filterInputFunct}
        globalFilters={globalFilters}
        setGlobalFilters={setGlobalFilters}
        taskFilter={props.taskFilter}
        filterArray={props.filterArray}
        placeHolderInput={props.placeHolderInput}
        className={props.classNameFilterContainer}
        mainFilter={props.mainFilter}
        hiddenLabelProductsLoaded={props.hiddenLabelProductsLoaded}
        productList={props.productList}
        filterLists={props.filterLists}
        section={props.section}
        setProviderSelected={props.setProviderSelected}
        setGroupsSelected={props.setGroupsSelected}
        setPhasesSelected={props.setPhasesSelected}
        providerSelected={props.providerSelected}
        phasesSelected={props.phasesSelected}
        groupsSelected={props.groupsSelected}
      />

      {!props.hideButtons && (
        <OptionsButtons
          editProducts={props.editProducts}
          exportProducts={props.exportProducts}
          download={props.download}
          loadingExportables={props.loadingExportables}
          loadingLoadMore={props.loadingLoadMore}
          setLoadingExportables={props.setLoadingExportables}
          loadMoreProducts={props.loadMoreProducts}
          setKeyword={setKeyword}
          taskFilter={props.taskFilter}
          deleteArticles={props.deleteArticles}
          addToCart={props.addToCart}
          hideExportButton={props.hideExportButton}
          buttonAdd={props.buttonAdd}
          gridView={props.gridView}
          setGridView={props.setGridView}
          section={props.section}
        />
      )}
    </FilterContainer>
  );
};
