import { Container } from "./styles";
import bad from "../images/surveyPopUp/bad.svg";
import regular from "../images/surveyPopUp/regular.svg";
import good from "../images/surveyPopUp/good.svg";
import excellent from "../images/surveyPopUp/excellent.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../../general/loading/index.js";
import Check from "../../../assets/IconComponents/genericModalCheck.svg";
import { Redirect } from "react-router-dom";

export default function SurveyPopUp(props) {
  const [emojiSelect, setEmojiSelect] = useState(false);
  const [rating, setRating] = useState();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [backToTasks, setBackToTasks] = useState(false);
  const select = (idEmoji, unSelect1, unSelect2, unSelect3) => {
    setRating(idEmoji);
    setEmojiSelect(true);
    document.getElementById(idEmoji).classList.add("emoji-select");
    document.getElementById(unSelect1).classList.remove("emoji-select");
    document.getElementById(unSelect2).classList.remove("emoji-select");
    document.getElementById(unSelect3).classList.remove("emoji-select");
  };
  useEffect(() => {
    const inputValue = document.querySelector("#textarea");
    inputValue.addEventListener(
      "input",
      (e) => {
        setComment(inputValue.value);
      },
      false
    );
  }, []);

  let data = {
    rating: rating,
    orderId: props.order,
    articleId: props.article,
    comment: comment,
  };
  const sendEvaluation = async () => {
    setLoading(true);
    const ratingRegistered = await axios.post(
      `${process.env.REACT_APP_RATINGS}`,
      data,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    ratingRegistered.data.statusCode === 200 ? setCheck(true) : setCheck(false);
    setBackToTasks(true);
    return ratingRegistered.data.statusCode;
  };

  return (
    <Container>
      <div className={"global-styles " + "upload-image"}>
        <button className="close-button" onClick={props.close} />
        <p className="title">Ayúdanos a mejorar nuestro trabajo</p>
        {loading ? (
          check ? (
            <>
              <img src={Check} alt="Checked icon"></img>
            </>
          ) : (
            <>
              <Loading />
            </>
          )
        ) : (
          <>
            <p>¿Cómo calificas nuestros servicios?</p>
            <div className="emojis-experiences">
              <button
                id={1}
                onClick={() => {
                  select(1, 2, 3, 4);
                }}
              >
                <img className="bad" src={bad} alt="Bad icon"></img>
              </button>
              <button
                id={2}
                onClick={() => {
                  select(2, 1, 3, 4);
                }}
              >
                <img src={regular} alt="Regular icon"></img>
              </button>
              <button
                id={3}
                onClick={() => {
                  select(3, 1, 2, 4);
                }}
              >
                <img src={good} alt="Good icon"></img>
              </button>
              <button
                id={4}
                onClick={() => {
                  select(4, 1, 2, 3);
                }}
              >
                <img src={excellent} alt="Excellent icon"></img>
              </button>
            </div>
            <textarea
              id="textarea"
              type="text"
              placeholder="Comentarios adicionales"
            ></textarea>
            {emojiSelect ? (
              <button
                id="send-button"
                className="send-button-enable"
                onClick={() => {
                  sendEvaluation();
                }}
              >
                Enviar encuesta
              </button>
            ) : (
              <button id="send-button">Enviar encuesta</button>
            )}
          </>
        )}
        {backToTasks && (
          <Redirect
            to={{
              pathname: `/tasks`,
            }}
          />
        )}
      </div>
    </Container>
  );
}
