import { useState, useEffect, useCallback } from "react";
import {
  ContainerPrincipal,
  Container,
  HeaderEstatus,
  ContainerHeader,
} from "../products/productsGeneral/styles";
import { InputSearchRight } from "./styles";
import { useParams } from "react-router-dom";
import Table from "../general/tableGeneral/Table";
import IconDownloadPDF from "../../assets/IconComponents/IconDownloadPDF.js";

import moment from "moment";
import axios from "axios";
import Loading from "../general/loading/index";
import HeaderOrdersR from "./HeaderOrdersR";
import HeaderOrdersL from "./HeaderOrdersL";
import InvoicingDetail from "./InvoicingDetail";
import HeaderInvoicingR from "./HeaderInvoicingR";
import HeaderInvoicingL from "./HeaderInvoicingL";
import StatusOrders from "./StatusOrders";

import { getStatusColor, getShortStatus } from "../_utils/helper";

export default function Orders(props) {
  const [sideOrders, setsideOrders] = useState(false);
  const [list, setList] = useState([]);
  const [listFacturacion, setListFacturacion] = useState([]);
  const [listAux, setListAux] = useState([]);
  const [listAuxData, setListAuxData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState([]);
  const [FacturacionMensual, setFacturacionMensual] = useState(0);
  const [modalClass, setModalClass] = useState("");
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Deciembre",
  ];
  let url = window.location.pathname;
  const { orderId } = useParams();
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getOrders = async () => {
    try {
      const response = orderId
        ? await axios({
            method: "get",
            url: `${process.env.REACT_APP_ORDER_ENDPOINT}?orderId=${orderId}`,
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          })
        : await axios({
            method: "get",
            url: `${process.env.REACT_APP_ORDER_ENDPOINT}${
              url === "/orders/billing" ? "?billing=1" : ""
            }`,
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          });
      setLoading(false);
      url === "/orders/billing"
        ? setListFacturacion(
            Object.values(JSON.parse(response.data.body).data).reverse()
          )
        : setList(Object.values(JSON.parse(response.data.body).data).reverse());
      setListAux(Object.values(JSON.parse(response.data.body).data).reverse());
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    setFirstLoad(true);
  };

  useEffect(() => {
    setLoading(true);
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const [firstLoad, setFirstLoad] = useState(true);
  let mesActual = new Date();
  const anioActual = mesActual.getFullYear();
  mesActual = mesActual.getMonth();
  const d = new Date(anioActual, mesActual + 1, 0);
  const lastMonthDay = d.getDate();

  if ((list.length > 0 || listFacturacion.length > 0) && firstLoad) {
    const listData = [];
    props.headerl === "Global"
      ? list.forEach((item, i) => {
          listData.push([
            <input
              id={"chk" + item.order.id_order}
              onClick={(e) => {
                articleClicked(e.target.checked, item, e);
              }}
              onChange={(chk) => {
                let chkGlobal = document.getElementById("chk-global");
                chkGlobal.checked = false;
                let inc = props.checkboxCounter;
                chk.target.checked ? inc++ : inc--;
                setCheckboxCounter(inc);
                if (inc === listData.length) {
                  chkGlobal.checked = true;
                }
              }}
              type="checkbox"
            />,
            item.order?.id_order,
            item.order?.qtyProducts,
            moment(item?.order?.timestamp).format("DD/MM/YYYY"),
            <StatusOrders
              status={getShortStatus(item.order.status)}
              color={getStatusColor(item.order.status)}
            />,
            item.order.qtyDatasheet ? item.order.qtyDatasheet : "---",
            item.order.qtyDescription ? item.order.qtyDescription : "---",
            item.order.qtyImages ? item.order.qtyImages : "---",
            item.order.deliver_date
              ? moment(item.order.deliver_date).format("DD/MM/YYYY")
              : "---",
            formatter.format(item.order.total),
          ]);
        })
      : listFacturacion.forEach((item, i) => {
          item !== null &&
            listData.push([
              <input
                onClick={(e) => articleClicked(e.target.checked, item.month, e)}
                onChange={(chk) => {
                  let chkGlobal = document.getElementById("chk-global");
                  chkGlobal.checked = false;
                  let inc = props.checkboxCounter;
                  chk.target.checked ? inc++ : inc--;
                  setCheckboxCounter(inc);
                  if (inc === props.data.length) {
                    chkGlobal.checked = true;
                  }
                }}
                type="checkbox"
              />,
              item?.month,
              item?.qtyOrders,
              item?.qtyProducts,
              moment(item?.timestamp).format("DD/MM/YYYY"),
              <StatusOrders
                status={getShortStatus(item?.status)}
                color={getStatusColor(item?.status)}
              />,
              item?.datasheetPrice,
              item?.descriptionPrice,
              item?.imagesPrice ? item?.order?.imagesPrice : 0,
              item?.extraServices ? item?.order?.extraServices : 0,
              formatter.format(item?.total),
              <p>
                PDF <IconDownloadPDF />
              </p>,
            ]);
        });

    let total = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i] !== null && props.headerl !== "Global") {
        let auxDate = new Date(list[i].order.timestamp);
        if (mesActual === auxDate.getMonth()) {
          total = total + list[i].order.total;
        }
      } else if (list[i] !== null && props.headerl === "Global") {
        let auxDate = new Date(list[i].order.timestamp);
        if (mesActual === auxDate.getMonth()) {
          total = total + list[i].order.total;
        }
      }
    }
    setListAuxData(listData);
    setFacturacionMensual(total);
    setFirstLoad(false);
  }

  useEffect(() => {
    setLoading(true);
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedArticles, setSelectedArticles] = useState([]);

  const articleClicked = (checked, articleId, e) => {
    e.stopPropagation();
    if (checked) {
      setSelectedArticles([...selectedArticles, parseInt(articleId)]);
    } else {
      let articleIndex = selectedArticles.indexOf(parseInt(articleId));
      let updatedArticles = selectedArticles.slice();
      updatedArticles.splice(articleIndex, 1);
      setSelectedArticles(updatedArticles);
    }
  };

  const columnOrders =
    props.headerl === "Global"
      ? [
          { name: <input type="checkbox" />, width: "40", align: "center" },
          { name: "Órdenes", width: "70", align: "center" },
          { name: "Num.", width: "70", align: "center" },
          { name: "Fecha compra", width: "100", align: "center" },
          { name: "Estatus", width: "70", align: "center" },
          { name: "Ficha técnica", width: "90", align: "center" },
          { name: "Descripciones", width: "90", align: "center" },
          { name: "Imagénes", width: "70", align: "center" },
          { name: "Entrega estimada", width: "100", align: "center" },
          { name: "Costo", width: "70", align: "center" },
        ]
      : [
          { name: <input type="checkbox" />, width: "40", align: "center" },
          { name: "Mes", width: "80", align: "center" },
          { name: "Órdenes", width: "80", align: "center" },
          { name: "Productos", width: "80", align: "center" },
          { name: "Fecha compra", width: "100", align: "center" },
          { name: "Estatus", width: "80", align: "center" },
          { name: "Ficha técnica", width: "100", align: "center" },
          { name: "Descripciones", width: "80", align: "center" },
          { name: "Imagénes", width: "80", align: "center" },
          { name: "Servicios Extra", width: "100", align: "center" },
          { name: "Costo Total", width: "80", align: "center" },
          { name: "Descarga", width: "80", align: "center" },
        ];

  const CONTAINER_HEADERL = {
    Global: <HeaderOrdersL path={url} tableAux={listAux} event={setList} />,
    Contentoh: <HeaderOrdersR />,
  };

  const HEADERL_DEFAULT = <HeaderOrdersL />;

  const headerLeft = CONTAINER_HEADERL[props.headerl] || HEADERL_DEFAULT;

  const CONTAINER_HEADERR = {
    Global: <HeaderInvoicingR />,
    Contentoh: <HeaderInvoicingL />,
  };

  const HEADERR_DEFAULT = <HeaderInvoicingL />;

  const headerRight = CONTAINER_HEADERR[props.headerr] || HEADERR_DEFAULT;

  const handleFilter = (e) => {
    const value = e.target.value;
    let updatedData = [];

    if (value.length) {
      updatedData = listAux.filter((item) => {
        if (item !== null) {
          item.qtyOrders = item.qtyOrders.toString();
          const startsWith =
            moment(item.timestamp)
              .format("DD/MM/YYYY")
              .toLowerCase()
              .startsWith(value.toLowerCase()) ||
            item.month.toLowerCase().startsWith(value.toLowerCase()) ||
            item.status.toLowerCase().startsWith(value.toLowerCase());
          const includes =
            moment(item.timestamp)
              .format("DD/MM/YYYY")
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            item.month.toLowerCase().startsWith(value.toLowerCase()) ||
            item.status.toLowerCase().startsWith(value.toLowerCase());
          if (startsWith) {
            return startsWith;
          } else if (!startsWith && includes) {
            return includes;
          } else return null;
        } else return null;
      });
      url === "/orders/billing" && setListFacturacion(updatedData);
    } else {
      url === "/orders/billing" && setListFacturacion(listAux);
    }
  };

  const [initialRetailersList, setInitialRetailersList] = useState([]);
  const [checkboxCounter, setCheckboxCounter] = useState(0);

  useEffect(() => {
    if (!window.location.href.includes("/orders/billing")) {
      let tmpList = [];
      list.map(
        (product) => (tmpList[`chk${product.order.id_order}`] = product)
      );
      setInitialRetailersList(tmpList);
    }
  }, [list]);

  useEffect(() => {
    if (!window.location.href.includes("/orders/billing")) {
      let tempRetailersList = [];
      const chks = document.querySelectorAll(".checkbox-item input");
      chks.forEach((chk, index) => {
        if (chk.checked) {
          tempRetailersList[chk.id] = initialRetailersList[chk.id];
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxCounter]);

  const openModal = useCallback(
    (e) => {
      if (
        (!e.target.closest(".modal-container") && sideOrders) ||
        e.target.closest(".btn-close-modal")
      ) {
        document.removeEventListener("click", openModal, false);
        setModalClass("");
        setTimeout(() => setsideOrders(false), 500);
      }
    },
    [sideOrders]
  );

  useEffect(() => {
    if (sideOrders) {
      document.addEventListener("click", openModal, false);
      setModalClass("modal-container");
    }
  }, [openModal, sideOrders]);

  return (
    <ContainerPrincipal>
      <Container
        className="work-orders-container"
        justifycontent="space-between"
      >
        {headerLeft}
        <Container className="order-date-range">
          <Container className="order-dates">
            <ContainerHeader className="date-range-container">
              <div>Periodo</div>
              <p>
                1 {months[mesActual]} - {lastMonthDay} {months[mesActual]}
              </p>
            </ContainerHeader>
            {headerRight}
          </Container>
          <Container className="monthly-billing">
            <ContainerHeader className="monthly-billing-container">
              <div>Facturación mensual</div>
              <p>{formatter.format(FacturacionMensual)}</p>
              <div>{months[mesActual]}</div>
            </ContainerHeader>
            {props.headerl === "Global" ? (
              <HeaderEstatus
                className="last-header-status"
                bg="#FC3030"
                width="100%"
              >
                <div> </div>Pago invalidado
              </HeaderEstatus>
            ) : (
              <InputSearchRight type="search" onChange={handleFilter} />
            )}
          </Container>
        </Container>
      </Container>
      {!loading && (
        <Table
          dataHead={columnOrders}
          data={listAuxData}
          rowClick={(e, i) => {
            props.headerl === "Global" && setsideOrders(true);
            props.headerl === "Global" && setOrder([list[i].order.id_order]);
          }}
        />
      )}
      {loading && <Loading />}
      {sideOrders && (
        <InvoicingDetail
          setsideOrders={setsideOrders}
          orders={order}
          modalClass={modalClass}
        />
      )}
    </ContainerPrincipal>
  );
}
