import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 76%;

  .input-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 20px;
    align-items: center;
    padding: 4px;
    padding-left: 12px;
    height: 43px;

    .fake-input-text,
    .chosen-filters {
      height: 100%;
      display: flex;
      align-items: center;

      .extra-filters {
        padding: 4px 5px 4px 14px;
        border: 1px solid #f0eef2;
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        align-items: center;

        p {
          font-family: Avenir Next;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 25px;
          color: #d4d1d7;
          white-space: nowrap;
        }
      }
    }

    .fake-input-text {
      flex: 1 1 70%;
      cursor: text;
      max-width: 55%;

      p {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        height: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 90%;
      }
    }
  }
  .products-loaded {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #817393;
    white-space: nowrap;
  }

  & + * {
    margin-left: 10px;
  }
`;
