import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  RequestModal,
  ModalContainer,
  CloseButton,
  ModalImage,
  Title,
  Row,
  ButtonContainer,
  FormActiveContainer,
  Status,
} from "./styles";
import Loading from "../../general/loading";

export default function ProductosElegidosModal(props) {
  sessionStorage.setItem(
    "retailersSelected",
    JSON.stringify(props.retailersSelected)
  );
  const [list, setList] = useState([]);
  const productsOriginal = JSON.parse(
    sessionStorage.getItem("productsToExport")
  );
  const [loading, setLoading] = useState(false);
  const [invalidPercentage, setInvalidPercentage] = useState(0);

  const getRetailersCategory = useCallback(async () => {
    setLoading(true);
    const categories = [
      ...new Set(productsOriginal.map((e) => e.categoryName)),
    ];
    const categoriresRes = await Promise.all(
      categories.map((cat) =>
        axios.get(
          `${
            process.env.REACT_APP_RETAILER_ENDPOINT
          }?category=${encodeURIComponent(cat)}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        )
      )
    );
    const relCatRet = {};
    categoriresRes.forEach((r, i) => {
      relCatRet[categories[i]] = Object.values(JSON.parse(r.data.body).data);
    });
    const retailersSelected = JSON.parse(
      sessionStorage.getItem("retailersSelected")
    );
    const temp = [];
    const dataToSend = [];
    let arrayRetailers = [];

    productsOriginal.forEach((prod, i) => {
      const retailersValid = relCatRet[prod.categoryName];
      prod.retailers = prod.retailersAvailable.filter((f) =>
        retailersSelected.includes(f.id)
      );
      const filtered = retailersValid.filter((f) =>
        retailersSelected.includes(f.id)
      );
      const intersection = filtered.filter((f) =>
        prod.retailers.includes(f.id)
      );
      const tempE = prod;
      tempE.retailers = [...prod.retailers, ...intersection];
      temp.push(tempE);
      dataToSend.push({
        id_article: prod.id_article,
        id_category: prod.id_category,
        version: prod.version,
      });
      [...prod.retailers, ...intersection].forEach((retailer) => {
        arrayRetailers.push(retailer.id);
      });
      dataToSend[i].id_retailer_array = arrayRetailers;
      arrayRetailers = [];
    });
    axios
      .post(
        process.env.REACT_APP_VALID_EXPORT_ENDPOINT,
        {
          data: dataToSend,
          attrRequired: props.activesSelected[0],
          descRequired: props.activesSelected[1],
          imgRequired: props.activesSelected[2],
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      )
      .then((response) => {
        const total = Object.keys(response.data).length;
        let allInvalids = 0;
        Object.values(response.data).forEach((element) => {
          allInvalids = Object.values(element).filter(
            (r) => r.percentageRequired < 100
          ).length;
        });
        setInvalidPercentage((allInvalids * 100) / total);

        let objRetailers = {};
        productsOriginal.forEach((product, index) => {
          const showValues = {};
          product.retailers.forEach((retailer) => {
            Object.values(response.data).forEach((element) => {
              if (element[retailer.id] === undefined) {
                return;
              }
              showValues[retailer.id] = {
                id_article: product.id_article,
                upc: product.upc,
                name: product.name,
                retailerName: retailer.name,
                percentageRequired: element.percentageRequired,
                version: product.version,
              };
              objRetailers[index] = showValues;
            });
          });
        });
        setList(objRetailers);
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listRetailers, props.retailersSelected]);

  useEffect(() => {
    getRetailersCategory();
    return () => sessionStorage.removeItem("retailersSelected");
  }, [getRetailersCategory]);

  const sendToExport = async (obj) => {
    const response = await axios.post(
      process.env.REACT_APP_EXPORT_ENDPOINT,
      obj,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return response;
  };

  const exportProducts = async () => {
    setLoading(true);
    if (Object.values(list).length > 0) {
      const obj = {};
      const user = JSON.parse(sessionStorage.getItem("user"));
      let retailers = [];
      Object.values(list).forEach((e) => {
        Object.keys(e).forEach((r) => {
          if (!isNaN(r)) {
            retailers.push(r);
          }
        });
      });
      const relRetailerUUID = {};
      const count = {};
      retailers.forEach((e) => {
        relRetailerUUID[e] = uuidv4();
        count[e] = 0;
      });
      const productArrayToTHD = {};

      productsOriginal.forEach((product) => {
        props.retailersSelected?.forEach((retailerId) => {
          if (retailerId === 58) {
            productArrayToTHD[product.article.id_article] = {
              idArticle: product.article.id_article,
              version: product.version,
              idCategory: product.article.id_category,
            };
          }
        });
      });

      let indexTHD = 0;

      productsOriginal.forEach((product) => {
        props.retailersSelected?.forEach((retailerId) => {
          const key = `${product.id_category}-${retailerId}`;
          // If the key does not exist in the object, create and initialize it
          if (!obj[key]) {
            obj[key] = {
              idProductArray: [],
              idRetailer: retailerId,
              idCategory: product.id_category,
              generateDatasheet: props.activesSelected[0],
              generateDescriptions: props.activesSelected[1],
              generateImages: props.activesSelected[2],
              email: user.email,
            };
            if (retailerId === 58) {
              obj[key].extraProductDataByRetailer =
                Object.values(productArrayToTHD);
              obj[key].index = indexTHD;
              indexTHD++;
            }
            count[retailerId]++;
          }
          obj[key].idProductArray = [
            ...obj[key].idProductArray,
            {
              idArticle: product.id_article,
              version: product.version,
              orderId: product.orderId,
              status: product.status,
            },
          ];
        });
      });
      const data = Object.values(obj);
      props.setStep(3);
      await sendToExport(data);
    } else {
      console.log("Pero que hace mi loco, que noo entiende");
    }
  };

  return (
    <RequestModal>
      <div className="transparent-background">
        <ModalContainer>
          <CloseButton>
            <button
              className="close"
              onClick={() => props.close(false)}
            ></button>
          </CloseButton>
          <ModalImage percent={invalidPercentage} className="percent-circle">
            <div className="image-container2">
              <p id="counter-retailers">{props.totalProducts}</p>
            </div>
          </ModalImage>
          <Title>
            <h2>Productos a Exportar</h2>
          </Title>
          <FormActiveContainer>
            <form>
              <p>Los siguientes productos se encuentrán incompletos:</p>
              <Row>
                <h3>UPC</h3>
                <h3>Nombre</h3>
                <h3>Cadena</h3>
                <h3>Estatus</h3>
              </Row>
              <div className="products-container">
                {Object.values(list).map((retailer, index) =>
                  Object.values(retailer).map((product, i) => (
                    <Row key={index}>
                      <label>{product.upc ? product.upc : "---"}</label>
                      <label>{product.name ? product.name : "---"}</label>
                      <label>
                        {product.retailerName ? product.retailerName : "---"}
                      </label>
                      <Status
                        backColor={
                          product.percentageRequired === "100.00"
                            ? "#18A0FB"
                            : "#EDD34D"
                        }
                      >
                        {product.percentageRequired || 0 || 0}%
                      </Status>
                    </Row>
                  ))
                )}
              </div>

              <ButtonContainer className="btn-container">
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <button className="back-button">Completar Productos</button>
                    <button
                      className="add-button"
                      type="button"
                      onClick={exportProducts}
                    >
                      Exportar Productos
                    </button>
                  </>
                )}
              </ButtonContainer>
            </form>
          </FormActiveContainer>
        </ModalContainer>
      </div>
    </RequestModal>
  );
}
