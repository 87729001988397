import { useEffect, useState } from "react";
import { CheckBox } from "contentoh-components-library";
import { ContainerCustomModal } from "./styles";

const RetailerComponentModal = (props) => {
  const [optionsSelected, setOptionsSelected] = useState({
    datasheet: { isSelected: false, name: "Ficha Técnica" },
    description: { isSelected: false, name: "Descripción" },
    image: { isSelected: false, name: "Imágenes" },
  });

  useEffect(() => {
    sessionStorage.setItem("serviceSelected", JSON.stringify([]));
  }, []);

  const setSelected = (evt, service) => {
    const isSelected = evt.target.checked;
    setOptionsSelected((prev) => ({
      ...prev,
      [service]: { ...prev[service], isSelected },
    }));
  };

  useEffect(() => {
    const dataSelected = [];
    Object.entries(optionsSelected).forEach(([service, { isSelected }]) => {
      if (isSelected) dataSelected.push(service);
    });
    sessionStorage.setItem("serviceSelected", JSON.stringify(dataSelected));
  }, [optionsSelected]);

  return (
    <ContainerCustomModal>
      <p>Se agregarán a tus productos seleccionados.</p>
      <ul>
        {Object.entries(optionsSelected).map(([service, data]) => (
          <li key={service}>
            {data.name}
            <CheckBox
              name={`select-${service}`}
              id={`select-${service}`}
              onChange={(e) => setSelected(e, service)}
              checked={data.isSelected}
            />
          </li>
        ))}
      </ul>
    </ContainerCustomModal>
  );
};

export default RetailerComponentModal;
