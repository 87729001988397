import getData from "./productsGeneral/getData";
import { getImagesPrices } from "../_utils/helper";

export const getProductDataTypes = async (products) => {
  let retailerDataSource = [];

  //Products for data count
  for (const product in products) {
    // Fetch article's description inputs and values
    const descriptions = await getData(
      products[product].id_category,
      products[product].id_article,
      "Descriptions",
      products[product].version
    );
    // Fetch article's data required
    const retailersData = await getData(
      products[product].id_category,
      products[product].id_article,
      "RetailerData",
      products[product].version
    );
    // Fetch article's data required
    const imagesData = await getData(
      products[product].id_category,
      products[product].id_article,
      "ImagesData",
      products[product].version
    );
    // if checkout version (add retailer to article) exists remove
    sessionStorage.getItem("lastVersion") &&
      sessionStorage.removeItem("lastVersion");
    let inputsArticulo = retailersData.pop();
    //from that category retailers, select the ones the article needs to get the data
    let productRetailers = [];
    products[product].retailersAvailable?.forEach((retailerChosen) => {
      let retFound = retailersData.find(
        (ret) => ret.retailer.id === retailerChosen.id
      );
      retFound !== undefined && productRetailers.push(retFound);
    });

    retailerDataSource.push({
      product: products[product],
      retailers: productRetailers,
      descriptions: descriptions,
      inputsGlobal: inputsArticulo,
      images: imagesData,
    });
  }

  return retailerDataSource;
};

const isUserRetailer = () => {
  const userCompany = JSON.parse(sessionStorage.getItem("company"));
  const isUserRetailer = userCompany.is_retailer;

  if (isUserRetailer) return [{ name: userCompany.name, id: userCompany.id }];
};

const filterDescriptionsForRetailer = (descriptionsArray, retailerObj) => {
  return descriptionsArray.filter((desc) => desc.id === retailerObj[0].id);
};

const setRetailerAttributes = (globalInputs, retAttributes) => {
  return globalInputs.filter((global) => retAttributes.includes(global.id));
};

// SET PRODUCT PRICES
export const setProductPrices = (productData, products, selectedRetailers) => {
  let productDataTypes = productData;
  productDataTypes.forEach((retailerData) => {
    let sharedInputs = null;
    let images;

    const retailerObj = isUserRetailer();
    if (retailerObj) {
      images = getImagesPrices(retailerData.images, retailerObj);
      retailerData.descriptions = filterDescriptionsForRetailer(
        retailerData.descriptions,
        retailerObj
      );
    } else {
      images = getImagesPrices(retailerData.images);
    }

    //IMAGES
    retailerData.images.price = images.price;
    retailerData.images.missingData = images.quantity;

    // DATASHEETS
    if (retailerData.inputsGlobal) {
      sharedInputs = Object.values(retailerData?.inputsGlobal);
    }
    let filledData = 0;
    let indice = productDataTypes.indexOf(retailerData);
    productDataTypes[indice].dataSheet = {};
    productDataTypes[indice].dataSheet.attributes = [];

    retailerData.retailers.forEach((ret) => {
      let inputsRetailer = [];
      // Iterar cada datagroup del retailer y obtener cada input/atributo requerido
      if (sharedInputs && ret.data) {
        Object.values(ret.data).forEach((datagroup) => {
          datagroup.inputs.forEach((input) => {
            // check if this input has a value
            let inputFound = sharedInputs.find((IR) => IR.id === input);
            if (inputFound !== undefined) {
              // if value exists -> don't add to retailer's missing  attributes
              if (!inputFound.value) {
                inputsRetailer.push(inputFound.id);
              }
            }
          });
        });
      }
      // Insertar los inputs especificos de cada retailer en nuestro obj de data
      if (retailerObj)
        sharedInputs = setRetailerAttributes(
          Object.values(retailerData.inputsGlobal),
          inputsRetailer
        );
      productDataTypes[indice].dataSheet.attributes.push({
        retailer: ret.retailer.name,
        retailerAttributes: inputsRetailer,
        charged: true,
        ignore: false,
        complete: false,
      });
    });
    if (sharedInputs) {
      let originalData = sharedInputs.length;
      // Buscar values de los campos unicos
      sharedInputs = sharedInputs.filter((input) => !input.value);
      filledData += originalData - sharedInputs.length;

      let price = 0;
      if (sharedInputs.length > 0 && sharedInputs.length <= 15) {
        price = 282;
      } else if (sharedInputs.length > 15 && sharedInputs.length <= 45) {
        price = 459;
      } else if (sharedInputs.length > 45) {
        price = 616;
      }

      // Agregar la nueva data a cada articulo de la lista
      productDataTypes[indice].dataSheet.price = price;
      productDataTypes[indice].dataSheet.filledData = filledData;
      productDataTypes[indice].dataSheet.sharedInputs = sharedInputs;
      productDataTypes[indice].dataSheet.commonAttributes = originalData;
    }
  });
  if (Array.isArray(products)) {
    products.forEach((selectedArticle) => {
      let article = productDataTypes.find(
        (prod) => prod.product.id_article === selectedArticle.id_article
      );

      if (article !== undefined) {
        let index = productDataTypes.indexOf(article);

        // Check if theres any description completed already
        article.descriptions.forEach((desc, i) => {
          productDataTypes[index].descriptions[i].charged = true;
          productDataTypes[index].descriptions[i].ignore = false;
          productDataTypes[index].descriptions[i].complete = false;
        });
      }
    });
  }
  return productDataTypes;
}; // end of function
