import { memo } from "react";
import { PanelLayout, CheckBox } from "contentoh-components-library";
import Collapsed from "../../Collapsed/index";
import RetailerItem from "../../RetailerItem/index";
import ServicePanel from "../../ServicePanel/index";
import { currencyFormatter } from "../../../_utils/helper";
import { TwoColumns, TotalArticleDetail } from "./styles.js";

const First = (props) => {
  const onChangeService = (articleId, retailerId, service, isAdd) => {
    const payload = { articleId, retailerId, service, isAdd };
    props.setArticles("updateService", payload);
  };

  const onChangeServiceAllProducts = (service, isAdd) => {
    const payload = { service, isAdd };
    props.setArticles("updateServiceAllProducts", payload);
  };

  const selectAll = (evt) => {
    const isChecked = evt.target.checked;
    if (isChecked) {
      const ids = Object.keys(props.articles);
      props.setArticlesSelected(ids);
    } else props.setArticlesSelected([]);
  };

  const selectArticle = (evt, articleId) => {
    const totalArticles = Object.keys(props.articles).length;
    const isChecked = evt.target.checked;
    if (isChecked) {
      if (props.articlesSelected.length + 1 === totalArticles) {
        document.getElementById("select-all").checked = true;
      }
      props.setArticlesSelected((prev) => [...prev, articleId]);
    } else {
      document.getElementById("select-all").checked = false;
      props.setArticlesSelected((prev) => prev.filter((f) => f !== articleId));
    }
  };

  return (
    <TwoColumns left="minmax(715px, auto)" right="minmax(400px, auto)">
      <div className="products">
        <PanelLayout
          header={
            <TwoColumns left="80%" right="20%">
              <div className="header first margin">
                <CheckBox
                  name="select-all"
                  id="select-all"
                  onChange={selectAll}
                  label="Productos"
                />
              </div>
              <div className="header second">Precio</div>
            </TwoColumns>
          }
        >
          {Object.entries(props.articles).map(([articleId, article]) => (
            <Collapsed
              key={articleId}
              product={{
                name: article.name,
                upc: article.upc,
                price: article.price.general,
                full: article.price.full,
                discount: article.price.discount,
                totalRetailers: Object.keys(article.retailers).length,
              }}
              articleId={articleId}
              isSelected={props.articlesSelected.includes(articleId)}
              selectArticle={(evt) => selectArticle(evt, articleId)}
            >
              {Object.keys(article.retailers)
                .sort(props.sortRetailersFunction)
                .reverse()
                .map((id) => {
                  const services = article.retailers[id];
                  const retailerId = id.replace(/.*-/, "");
                  return (
                    <RetailerItem
                      key={`${articleId}-${retailerId}`}
                      retailer={{
                        id: retailerId,
                        name: props.retailers[retailerId],
                      }}
                      services={services}
                      price={article?.price?.retailers[retailerId] ?? {}}
                      onChangeService={(service, isAdd) =>
                        onChangeService(
                          Number(articleId),
                          Number(retailerId),
                          service,
                          isAdd
                        )
                      }
                    />
                  );
                })}
              <TotalArticleDetail>
                <p>{Object.keys(article.retailers).length} cadenas</p>
                <div>
                  <span>
                    {currencyFormatter().format(
                      article?.price?.services?.datasheet?.price
                    )}
                  </span>
                  <span>
                    {currencyFormatter().format(
                      article?.price?.services?.description?.price
                    )}
                  </span>
                  <span>
                    {currencyFormatter().format(
                      article?.price?.services?.image?.price
                    )}
                  </span>
                </div>
              </TotalArticleDetail>
            </Collapsed>
          ))}
        </PanelLayout>
      </div>
      <div className="cards">
        <ServicePanel
          service="article"
          onChangeServiceAllProducts={onChangeServiceAllProducts}
        />
        <ServicePanel
          service="dataset"
          onChangeServiceAllProducts={onChangeServiceAllProducts}
        />
        <ServicePanel
          service="image"
          onChangeServiceAllProducts={onChangeServiceAllProducts}
        />
      </div>
    </TwoColumns>
  );
};

export default memo(First);
