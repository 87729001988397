import moment from "moment";
import Status from "../../../_utils/TableComponents/Status/index";
import Priority from "../../../_utils/TableComponents/Priority/index";
import Percentage from "../../../_utils/TableComponents/Percentage/index";
import ValidadedProduct from "../../../general/ValidProduct";

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  let a = s.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const renderer = (col, item, eventsFunctions, index) => {
  const value = Object.byString(item, col.path || "") ?? "---";
  switch (col.data) {
    case "checkbox":
      return (
        <input
          key={`checkbox-$${index}-${item.checked}`}
          type="checkbox"
          defaultChecked={item.checked}
          onClick={(evt) => {
            evt.stopPropagation();
            eventsFunctions.checkboxClicked(evt.target.checked, item);
          }}
        />
      );
    case "order":
    case "category":
    case "company":
    case "upc":
    case "version":
      return <p>{value}</p>;
    case "priority":
      return (
        <Priority
          prio={{ prio: value, articleId: col.articleIdPath }}
          {...col.attributes}
        />
      );
    case "status":
      return <Status status={value} />;
    case "requestDate":
      return moment(value).format("DD/MM/YYYY") ?? <p>---</p>;
    case "totalPrice":
      return value !== null ? `$${value}`: `$${value.toFixed(2)}`;
    case "valid":
      return <ValidadedProduct status={value} />;
    case "missingData":
      return (value <= 0 ? 0 : <p>{value}</p>) ?? <p>---</p>;
    case "percentage":
      return <Percentage percentage={value} />;
    default:
      return <p>----</p>;
  }
};

export const headerHandler = (colType, attributes, eventsFunctions) => {
  const colContent = {
    checkbox: (
      <input
        id={"globalHeaderCheckbox"}
        type="checkbox"
        onClick={(evt) => {
          evt.stopPropagation();
          eventsFunctions.checkboxClicked(evt.target.checked);
        }}
      />
    ),
  };
  return colContent[colType] ?? colType;
};
