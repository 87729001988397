import styled from "styled-components";

export const Container = styled.div`
  padding: 25px 20px 0 0;
  overflow-y: scroll;

  .table-products {
    .table-column {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      box-sizing: border-box;

      &:first-child {
        div {
          width: 30px;
          height: 30px;
          padding: 0;

          input {
            margin: auto;
          }
        }
      }

      & + .table-column {
        border-left: 1px solid #f0eef2;
      }
    }
    .table-row {
      cursor: pointer;
      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #d4d1d7;
      }
    }
    .ReactVirtualized__Grid {
      width: 100%!important;
      will-change: auto !important;
      overflow: scroll !important;
      .ReactVirtualized__Grid__innerScrollContainer {
        overflow: unset !important;
        /* width: 1500px !important; */
      }
    }
  }

  .table-container {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .table-products {
    width: 100%;
  }
  
  .table-row {
    display: flex;
    width: 100%;
  }
  
  .table-column {
    display: flex;
    align-items: center;
  }
  
`;

export const TableWarning = styled.div`
  font-family: Avenir Next;
  box-sizing: border-box;
  font-size: 20px;
  color: #603888;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
`;

export const RowColumn = styled.div`
  font-family: "Avenir Next";
  font-size: 13px;
  box-sizing: border-box;
  display: flex;
  color: #817393;
  padding: 0 10px;
  width: 100%;

  p {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
  }
`;

export const PrincipalGrid = styled.div`
  display: grid;
  height: 70vh;
  grid-template-columns: 180px repeat(auto-fill, 180px);
  gap: 10px 10px;
  grid-auto-flow: row;
  overflow-y: scroll;
`;
export const GridRow = styled.div`
  width: 100%;
  max-width: 190px;
  min-width: 190px;
  height: 280px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 220px;
  padding: 3px;
`;

export const FilterDropDowns = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  margin-bottom: 20px;
  padding: 2px;
`;
