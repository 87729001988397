export const isAccountFinanced = () => {
  try {
    const userCompany = JSON.parse(sessionStorage.getItem("company"));
    const defaultRetailers = userCompany.financedRetailers?.length > 0;
    return defaultRetailers;
  } catch (err) {
    console.log(err, "unable to check if account is financed by retailer acc");
    return false;
  }
};
