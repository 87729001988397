import styled from "styled-components";
export const MainContainer = styled.div`
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;

  & > .filters-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;

    .filters {
      display: flex;
      align-items: center;

      .search-user-input {
        display: flex;
        align-items: center;
        border: 1px solid #f0f0f0;
        border-radius: 50px;
        padding: 9px;

        img {
          width: 14px;
          height: 14px;
          & + * {
            margin-left: 15px;
          }
        }
        input {
          font-family: Roboto;
          font-size: 12px;
          outline: none;
          border: none;
        }
      }

      & > * + * {
        margin-left: 20px;
      }
    }

    .buttons-container {
      display: flex;
      gap: 10px;

      button {
        background-color: transparent;
        img {
          width: 18px;
        }
        &.circular-button-v2 {
          border-radius: 50%;
          overflow: hidden;
          width: 34px;
          height: 34px;
          border: 1px solid #f0f0f0;
          padding: 7px;

          & + * {
            margin-left: 5px;
          }
        }

        &.add-users-button {
          border: 1px solid #e33aa9;
        }
        &.save-button {
          background-color: #e33aa9;
          border-radius: 50px;
        }
        &.disabled-button {
          background-color: #f0f0f0;
          border: none;
        }
        &.edit-users-button {
          border: 1px solid #e33aa9;
        }
        &.close-users-button{
          border: 1px solid #e33aa9;
        }
        &.trash-button {
          border-radius: 50%;
          overflow: hidden;
          width: 34px;
          height: 34px;
          border: 1px solid #B64545;
          padding: 7px;
          :hover{
            background: #FFE9E9!important;
          }
        }
        &.button-users-delete {
          cursor: pointer;
          font-family: RobotoMedium, sans-serif;
          font-style: normal;
          font-weight: 500;
          border: none;
          padding: 9px 18px;
          background: #e33aa9;
          border-radius: 30px;
          color: #ffffff;
        }
        &.save-changes{
          background:#e33aa9!important;
        }
      }
    }
    .grupo-select {
      width: auto;
      padding: 5px 10px;
      height: 34px;
      border: 1px solid #f0f0f0;
      border-radius: 50px;
    }
    .contentModal .container-customComponent .parrafo {
      font-family: RobotoRegular, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: center;
      color: #707070;
    }
  }
  .modal-cambio-grupo .contentModal{
    max-width:320px;
    header .label-title, .label-message{
      text-align: left;
      white-space: normal;
    }
    #group-select-modal{
      width: 100%;
    }
    .container-buttons{
      justify-content: end;
    }
  } 
  .modal-aceptar-cambio-grupo .contentModal{
    max-width:230px;
  }
  .modal-save .contentModal {
    max-width:200px;
  }
  
`;

export const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 24px 0px;
  p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }
`;

export const BasicData = styled(Section)`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #817393;
  width: 100%;
  border:1px solid #F0F0F0;
  border-radius:5px;
  padding:0;
  .no-users {
    padding: 10px 5px;
    p {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #707070;
    }
  }

  .text_center {
    text-align: center;
  }
  .space {
    justify-content: space-between;
  }
  .align_end {
    justify-content: flex-end;
  }
  .th-custom p{
    font-family: "RobotoMedium", sans-serif;
  }
  .margin_row {
    .col.chk-cont {
      display: flex;
      justify-content: flex-start;
      & > div {
        & + * {
          margin-left: 10px;
        }
      }
    }
    .col + * {
      margin-left: 10px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;

    p {
      color: #817393;

      & + * {
        margin-top: 5px;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border:0.5px solid #F0F0F0;
    &:first-child {
      border:none;
    }

    .col {
      flex: 1;
      overflow: hidden;
      align-items: center;

      figure {
        & + * {
          margin-left: 10px;
        }
      }

      &:first-child {
        padding: 10px;
        align-items: center;
        overflow: inherit;
      }
    }

    &.header {
      background-color: #f7f7fc;
      height: 34px;
      border-radius: 5px;

      .col {
        color: #262626;
        text-align: left;
        font-weight: 500;
      }
    }
  }
  .center {
    justify-content: center;
    align-items: center;
  }
  input[type="email"] {
    width: 360px;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 7px;
    margin-right: 20px;
    ::placeholder {
      color: #d4d1d7;
    }
  }
  input[type="checkbox"]:checked {
    width: 75px;
    background-color: #e33aa9;
    color: green;
  }
  select {
    width: 116px;
    height: 30px;
    padding: 0 8px;
    border: 1px solid #e33aa9;
    border-radius: 7px;
    box-sizing: border-box;
    background: #fff;
  }
  .flex{
    display:flex;
  }
  .gap-10{
    gap:10px;
  }
`;

export const Parrafo = styled.div`
  font-family: RobotoRegular, sans-serif;
  font-size: 13px;
  color: #707070;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
`;