import React, { useState, useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import { SideModal } from "contentoh-components-library";
import { Redirect } from "react-router-dom";
import closepinkbutton from "../../../assets/IconComponents/closePinkButton.svg";
import defaultRetailer from "../../../assets/defaultImages/notifyDefaultIcon.png";
import {
  RSHeader,
  RSTextContainer,
  RSCloseButton,
  RSShoppingTable,
  RSCheckout,
  RSSummary,
  RSSumaryItem,
  RSTotalPrice,
  RSCheckoutButton,
} from "./styles";
import { getCart } from "../../_utils/data";
import { getRetailerImage, currencyFormatter } from "../../_utils/helper";
import Loading from "../../general/loading/index";
import { icons } from "./utils";

const RequestedServices = (props) => {
  const date = new Date();
  const [cartList, setCartList] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState();
  const [dataGeneral, setDataGeneral] = useState({
    totalPrice: 0,
    totalDatasheets: 0,
    totalDescriptions: 0,
    totalImages: 0,
    totalRetailers: {},
  });

  const getTotalAttributes = (rules, retailers, category, service) => {
    if (!rules[category]) return 0;
    const uniqueAttributes = [];
    retailers.forEach((id) => {
      if (!rules[category][id]) return;
      if (!rules[category][id][service]) return;
      if (service === "description") {
        uniqueAttributes.push(id);
      } else {
        rules[category][id][service].inputs.forEach((attrId) => {
          if (!uniqueAttributes.includes(attrId)) uniqueAttributes.push(attrId);
        });
      }
    });
    return uniqueAttributes.length;
  };

  useEffect(() => {
    const getDataCart = async () => {
      let cart;
      let prices;
      try {
        [cart, prices] = await Promise.all([
          getCart({ action: "general" }),
          getCart({ action: "price" }),
        ]);
      } catch (err) {
        console.log("Algo salió mal...");
        setIsLoading(false);
        return;
      }
      const { rules } = prices;
      const { articles, retailers } = cart;
      setRetailers(retailers);
      const tempDataGeneral = {
        totalPrice: 0,
        totalDatasheets: 0,
        totalDescriptions: 0,
        totalImages: 0,
        totalRetailers: {},
      };
      const tempListCart = [];
      Object.values(articles).forEach((article) => {
        tempDataGeneral.totalPrice += article.subtotal ?? 0;
        const retailersIds = [];
        const includesService = {
          datasheet: { isActive: false, retailers: [], key: "totalDatasheets" },
          description: {
            isActive: false,
            retailers: [],
            key: "totalDescriptions",
          },
          image: { isActive: false, retailers: [], key: "totalImages" },
        };
        Object.entries(article.retailers).forEach(([keyId, services]) => {
          const retailerId = keyId.replace(/.*-/, "");
          retailersIds.push(retailerId);
          tempDataGeneral.totalRetailers[retailerId] = retailers[retailerId];
          Object.entries(includesService).forEach(([service, { isActive }]) => {
            const includeCurrentService = services.includes(service);
            if (includeCurrentService) {
              if (!isActive) includesService[service].isActive = true;
              includesService[service].retailers.push(retailerId);
            }
          });
        });
        Object.entries(includesService).forEach(([service, data]) => {
          const { isActive, retailers, key } = data;
          if (!isActive) return;
          tempDataGeneral[key] += getTotalAttributes(
            rules,
            retailers,
            article.category,
            service
          );
        });
        tempListCart.push({
          name: article.name,
          retailers: retailersIds,
          datasheet: includesService.datasheet.isActive,
          description: includesService.description.isActive,
          image: includesService.image.isActive,
          price: article.subtotal ?? 0,
        });
      });
      tempDataGeneral.totalPrice = tempDataGeneral.totalPrice * 1.16;
      setCartList(tempListCart);
      setDataGeneral(tempDataGeneral);
      setIsLoading(false);
    };
    getDataCart();
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <SideModal
      id="cart-side-modal"
      show={props.show}
      setShow={props.setShow}
      header={[
        <RSHeader>
          <RSTextContainer>
            <h2>Servicios solicitados</h2>
            <p className="date">{`${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`}</p>
          </RSTextContainer>
          <RSCheckoutButton>
            <button
              id="rs-checkout-button"
              onClick={() => {
                setRedirect("checkout");
              }}
            >
              Ir al checkout
            </button>
          </RSCheckoutButton>
          <RSCloseButton
            id="rs-close-button"
            onClick={() => props.setShow(false)}
          >
            <img src={closepinkbutton} alt="" />
          </RSCloseButton>
        </RSHeader>,
      ]}
      body={[
        <RSShoppingTable>
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cadenas</th>
                <th>Servicios</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <Loading />
              ) : (
                cartList.length > 0 &&
                cartList.map((product, i) => (
                  <tr key={i}>
                    <td>{product.name}</td>
                    <td className="retailers-images">
                      {product?.retailers?.length > 4 &&
                        `+${product?.retailers?.length - 4}`}
                      {product?.retailers?.map(
                        (id, i) =>
                          i < 4 && (
                            <ReactImageFallback
                              key={id}
                              alt={retailers[id]}
                              src={getRetailerImage(id)}
                              fallbackImage={defaultRetailer}
                              title={retailers[id]}
                            />
                          )
                      )}
                    </td>
                    <td className="services-icons">
                      {product.datasheet && icons("datasheet")}
                      {product.description && icons("description")}
                      {product.image && icons("images")}
                    </td>
                    <td>{currencyFormatter().format(product.price)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </RSShoppingTable>,
      ]}
      footer={[
        <RSCheckout>
          <RSTotalPrice>
            <p>Total</p>
            <h2>{currencyFormatter().format(dataGeneral.totalPrice)}</h2>
          </RSTotalPrice>
          <RSSummary>
            <RSSumaryItem>
              <p>PRODUCTOS</p>
              <h3>{cartList.length}</h3>
            </RSSumaryItem>
            <RSSumaryItem>
              <p>CADENAS</p>
              <h3>{Object.values(dataGeneral.totalRetailers).length}</h3>
            </RSSumaryItem>
            <RSSumaryItem>
              <p>FICHAS TÉCNICAS</p>
              <h3>{dataGeneral.totalDatasheets}</h3>
            </RSSumaryItem>
            <RSSumaryItem>
              <p>DESCRIPCIONES</p>
              <h3>{dataGeneral.totalDescriptions}</h3>
            </RSSumaryItem>
            <RSSumaryItem>
              <p>IMÁGENES</p>
              <h3>{dataGeneral.totalImages}</h3>
            </RSSumaryItem>
          </RSSummary>
        </RSCheckout>,
      ]}
    />
  );
};

export default RequestedServices;
