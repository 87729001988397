import styled from "styled-components";

export const Tomas = styled.div`
  font-family: Raleway;
  height: 100%;
  width: 100%;

  .flexDiv {
    color: #817393;
    font-size: 12px;

    table {
      width: 100%;

      thead {
        display: block;
        border-bottom: 1px solid #d4d1d7;
        padding-bottom: 10px;

        & > tr {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .asig-container {
          display: flex;
          align-items: center;

          .first-letter {
            width: 20px;
            height: 20px;
            background-color: #71de56;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            border-radius: 50%;
          }

          .short-text {
            font-family: Avenir Next;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #d4d1d7;
          }

          .icon-section {
            position: relative;
          }

          * + * {
            margin-left: 5px;
          }
        }

        & + tbody {
          margin-top: 10px;
        }
      }

      tbody {
        display: block;

        tr {
          display: flex;

          td:first-child {
            flex: 0%;
          }
        }
      }
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
  height: 100%;
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;

  .topBar {
    display: flex;
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    background-color: #3b1366;
    justify-content: flex-end;

    align-items: center;
    padding: 5px 10px;

    .asig-container {
      display: flex;
      align-items: center;

      .first-letter {
        width: 20px;
        height: 20px;
        background-color: #71de56;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        border-radius: 50%;
      }

      .short-text {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #d4d1d7;
      }

      .checked-icon {
        position: relative;
      }

      * + * {
        margin-left: 5px;
      }

      & + * {
        margin-left: 10px;
      }
    }

    > figure:hover {
      cursor: pointer;
    }
  }
`;
export const ScrollerImg = styled.div`
  width: 100%;
  height: 55px;
`;

export const ImageItem = styled.figure`
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  height: 45px;
  width: 50px;
  :hover {
    cursor: pointer;
  }
  > img {
    object-fit: contain;
    height: 40px;
    width: 100%;
  }
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
    > p:last-child {
      font-size: 10px;
    }
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.015em;
    color: #969696;
  }
`;

export const ValidationContainer = styled.div`
  display: none;
  position: absolute;
  right: 0;
  margin-top: 10px;

  .validation-selected,
  .validation-menu {
    background-color: #f0eef2;
    display: flex;
    padding: 10px;
    border-radius: 10px;
  }

  .validation-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .validation-selected + .validation-menu {
    margin-top: 5px;
    margin-left: 0 !important;
  }

  .validation-menu {
    .validation-options {
      .validation-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }
    }
  }
`;
