import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../general/loading";
import { FilterInput } from "../filterInput/index";
import { Container, ContainerGrid, GridWarning } from "./styles";
import Modal from "./Modal/Modal";
import GridLists from "./GridLists";
import { faCheck as okIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import ErrorModalIcon from "../../assets/IconComponents/errorModal.svg";
import OkModalIcon from "../../assets/IconComponents/genericModalCheck.svg";
import {
  fetchDELETE,
  fetchGET,
  fetchPOST,
  fetchPUT,
} from "../_utils/handle_http";
import { getImage } from "./utils/getImage";

export default function ListProducts() {
  const mainFilter = "Nombre";
  const [valueInputEditName, setValueInputEditName] = useState("");
  const [selectedList, setSelectedList] = useState({ id: "", name: "" });
  const [valueInputNewNameList, setValueInputNewNameList] = useState("");
  const [originalLists, setOriginalLists] = useState(null); // listas merchants originales
  const [lists, setLists] = useState([]); // listas merchants originales
  const [selectedLists] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [modalAlert, setModalAlert] = useState({
    // config del modal mensaje/preguntas
    show: false,
    title: "",
    message: "",
    onClickBtnClose: () => {},
    button1: { type: "btnTransparent", name: "Entendido", action: () => {} },
    button2: undefined,
    icon: undefined,
  });
  const [isVisibleModalInputEdit, setVisibleModalInputEdit] = useState(false); // config del modal editar nombre lista
  const [showDropdownAddList, setShowDropdownAddList] = useState(false);
  const [redirect, setRedirect] = useState();
  const [isLoading, setIsLoading] = useState(true); // ver comp cargando?
  const [filters, setFilters] = useState({
    name: "",
  });

  const getLists = async () => {
    setIsLoading(true); // dejar el componente cargando

    const paramsQuery = {
      nameList: "",
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const respHTTP = await fetchGET(
      process.env.REACT_APP_LIST_MERCHANTS_GRID_ENDPOINT,
      paramsQuery,
      paramsHeaders
    );

    // cuando existe un error
    if (!respHTTP.body) {
      setOriginalLists(undefined);
      setIsLoading(false);
      showModalAlert(
        respHTTP.message,
        respHTTP.errorDetail,
        hideModalAlert,
        { type: "btnTransparent", name: "Entendido", action: hideModalAlert },
        undefined,
        ErrorModalIcon
      );
      return;
    }

    // success
    let lists = [];
    lists = respHTTP.body.lists ?? [];

    // completar la URL de las imagenes
    lists.forEach((list) => {
      let images = [];
      for (const src of list.images) {
        images.push(getImage(src, 500, 500).imgURL);
      }
      list.images = images;
    });

    // ordenar las listas alfabeticamente
    lists = lists.sort((listA, listB) => {
      if (listA.name.toLowerCase() < listB.name.toLowerCase()) return -1;
      else return 1;
    });
    setIsLoading(false);
    setOriginalLists(lists);
  };

  const isSelectedList = (listId) => {
    if (selectedLists.findIndex((id) => id === listId) === -1) return false;
    else return true;
  };

  const hideModalAlert = (event) => {
    setModalAlert({
      show: false,
      title: "",
      message: "",
      onClickBtnClose: () => {},
      button1: undefined,
      button2: undefined,
      icon: undefined,
    });
  };

  const showModalAlert = (
    title,
    message,
    onClickBtnClose,
    button1 = {
      type: "btnTransparent",
      name: "Entendido",
      action: hideModalAlert,
    },
    button2 = undefined,
    icon = undefined
  ) => {
    setModalAlert({
      show: true,
      title: title,
      message: message,
      onClickBtnClose: onClickBtnClose,
      button1: button1,
      button2: button2,
      icon: icon,
    });
  };

  const handlerFilterInput = async (filtersFilterInput) => {
    const activeFilters = Object.values(filtersFilterInput); //[ { filter , values} ]
    let nameList = "";
    if (activeFilters.length !== 0) nameList = activeFilters[0].values;
    if (nameList !== filters.name) {
      setFilters((prev) => ({ ...prev, name: nameList }));
    }
  };

  const onClickListCard = (listId, nameList) => {
    setRedirect({
      pathname: "/merchants",
      state: { listId: `${listId}`, nameList },
    });
  };

  const onClickAddList = async () => {
    const newNameList = valueInputNewNameList.trim();
    setValueInputNewNameList(newNameList);
    // cuando el new name list esta vacio
    if (newNameList === "") {
      showModalAlert(
        "El nombre de la nueva lista no esta establecido",
        "Por favor ingrese un nombre valido",
        hideModalAlert,
        { type: "btnTransparent", name: "Entendido", action: hideModalAlert },
        undefined,
        ErrorModalIcon
      );
      return;
    }

    // mandamos a crear la lista
    setIsLoading(true);
    const paramsBody = {
      nameList: newNameList,
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const respCreate = await fetchPOST(
      process.env.REACT_APP_LIST_MERCHANTS_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    setShowDropdownAddList(false);

    //esto sucede cuando se crea en la BD
    if (respCreate.body) {
      const refreshLists = async () => {
        setValueInputNewNameList(""); // limpiar el input
        hideModalAlert(); // ocultar modal
        setSelectedList({ id: "", name: "" }); // limpiar lista seleccionada

        // agregar la nueva lista al array original
        let currentLists = originalLists.map((list) => ({ ...list }));
        currentLists.push({
          id: respCreate.body.listId,
          name: newNameList,
          articles_count: 0,
          is_editable: 1,
          images: [],
        });
        // ordenar las listas alfabeticamente
        currentLists = currentLists.sort((listA, listB) => {
          if (listA.name.toLowerCase() < listB.name.toLowerCase()) return -1;
          else return 1;
        });
        setOriginalLists(currentLists);
      };

      // indicar que se creo
      showModalAlert(
        "Tu lista ha sido creada",
        respCreate.message ? respCreate.message : "",
        refreshLists,
        { type: "btnTransparent", name: "Entendido", action: refreshLists },
        undefined,
        OkModalIcon
      );
    } else {
      // cuando ocurrio un error y no se creo
      setIsLoading(false);
      showModalAlert(
        respCreate.message,
        respCreate.errorDetail,
        hideModalAlert,
        { type: "btnTransparent", name: "Entendido", action: hideModalAlert },
        undefined,
        ErrorModalIcon
      );
    }
  };

  const onClickEditName = (listId, listName) => {
    setSelectedList({ id: listId, name: listName });
    setValueInputEditName("");
    setVisibleModalInputEdit(true);
  };

  const modalInputEditBtnGuardar = async (/*valueInputEditName*/) => {
    // volver a pedir name edit
    const showModalInputEditName = () => {
      hideModalAlert();
      setVisibleModalInputEdit(true);
    };

    setVisibleModalInputEdit(false);
    const newNameList = valueInputEditName.trim();
    setValueInputEditName(newNameList);
    if (newNameList === "") {
      showModalAlert(
        "Nuevo nombre de la lista esta vacio",
        "Por favor ingrese un nombre valido o haga click en Cancelar",
        showModalInputEditName,
        {
          type: "btnTransparent",
          name: "Entendido",
          action: showModalInputEditName,
        },
        undefined,
        ErrorModalIcon
      );
      return;
    }

    setIsLoading(true);
    // realizar el fetch UPDATE
    const paramsBody = {
      listId: selectedList.id,
      newNameList: newNameList,
    };
    const paramsHeaders = {};
    const respUpdate = await fetchPUT(
      process.env.REACT_APP_LIST_MERCHANTS_ENDPOINT,
      paramsBody,
      paramsHeaders
    );

    //esto sucede cuando se actualizo en la BD
    if (respUpdate.body) {
      const refreshLists = async () => {
        hideModalAlert();
        // cambiar el name en original lists
        let currentLists = originalLists.map((list) => ({ ...list }));
        currentLists.forEach((list) => {
          if (list.id === selectedList.id) {
            list.name = newNameList;
            return;
          }
        });
        setSelectedList({ id: "", name: "" });
        setOriginalLists(currentLists);
      };
      // indicar que se borraron
      showModalAlert(
        "Nombre de lista actualizado",
        respUpdate.message ? respUpdate.message : "",
        refreshLists,
        { type: "btnTransparent", name: "Entendido", action: refreshLists },
        undefined,
        OkModalIcon
      );
    } else {
      // cuando ocurrio un error
      setIsLoading(false);
      showModalAlert(
        respUpdate.message,
        respUpdate.errorDetail,
        hideModalAlert,
        { type: "btnTransparent", name: "Entendido", action: hideModalAlert },
        undefined,
        ErrorModalIcon
      );
    }
  };

  const onClickDeleteList = async (listId) => {
    // funcion a ejecutar cuando desee eliminar
    const proceso = async () => {
      hideModalAlert();
      setIsLoading(true);

      // realizar el fetch DELETE
      const paramsBody = {
        listsId: [listId],
      };
      const paramsHeaders = {};
      const respDelete = await fetchDELETE(
        process.env.REACT_APP_LIST_MERCHANTS_ENDPOINT,
        paramsBody,
        paramsHeaders
      );

      //esto sucede cuando se eliminan de la BD
      if (respDelete.body) {
        const refreshLists = async () => {
          hideModalAlert();
          // cambiar el name en original lists
          let currentLists = originalLists.map((list) => ({ ...list }));
          currentLists = currentLists.filter((list) => list.id !== listId);
          setSelectedList({ id: "", name: "" });
          setOriginalLists(currentLists);
        };

        // indicar que se borraron
        showModalAlert(
          "Lista eliminada",
          respDelete.message ? respDelete.message : "",
          refreshLists,
          { type: "btnTransparent", name: "Entendido", action: refreshLists },
          undefined,
          OkModalIcon
        );
      } else {
        setIsLoading(false);
        // cuando ocurrio un error y no se liminaron
        showModalAlert(
          respDelete.message,
          respDelete.errorDetail,
          hideModalAlert,
          { type: "btnTransparent", name: "Entendido", action: hideModalAlert },
          undefined,
          ErrorModalIcon
        );
      }
    };

    // preguntar si desea eliminar
    showModalAlert(
      "Eliminar lista",
      "La lista se eliminará de forma permanente. Los productos " +
        "se conservarán y pasaran a productos no listados",
      hideModalAlert,
      { type: "btnTransparent", name: "Cancelar", action: hideModalAlert },
      { type: "btnPink", name: "Eliminar", action: proceso }
    );
  };

  const renderGrid = () => {
    if (lists.length === 0)
      return <GridWarning> {warningMessage} </GridWarning>;
    const gridProps = {
      lists,
      isVisibleModalAlert: modalAlert.show,
      isVisibleModalInputEdit: isVisibleModalInputEdit,
      handlers: { onClickListCard, onClickDeleteList, onClickEditName },
      functions: {
        isSelectedList,
      },
    };
    return (
      <ContainerGrid>
        <GridLists {...gridProps} />
      </ContainerGrid>
    );
  };

  useEffect(() => {
    getLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (originalLists === undefined) {
      setWarningMessage(
        "No fue posible obtener las listas, por favor vuelva a intentarlo"
      );
      setLists([]);
      return;
    }
    if (originalLists === null) {
      return;
    }

    setIsLoading(true);
    let tempLists = originalLists.map((list) => ({ ...list }));
    tempLists = tempLists.filter((list) => {
      // aplicar filtro por nombre de lista
      if (filters.name !== "") {
        if (!list.name.toLowerCase().includes(filters.name)) return false;
      }

      // filtrar al pasar todos los filtros
      return true;
    });
    let message = "";
    if (tempLists.length === 0) {
      if (filters.name === "") message = "No tienes ninguna lista creada";
      else message = "No tienes listas que coincidan con la busqueda";
    }
    setIsLoading(false);
    setWarningMessage(message);
    setLists(tempLists);
  }, [originalLists, filters]);

  if (redirect) return <Redirect to={redirect} />;

  // botones del modalAlert
  let buttonsModalAlert = [];
  modalAlert.button1 &&
    buttonsModalAlert.push(
      <button
        key={"modalAlert-btn1"}
        className={modalAlert.button1.type}
        onClick={(event) => {
          event.stopPropagation();
          modalAlert.button1.action();
        }}
      >
        {modalAlert.button1.name}
      </button>
    );
  modalAlert.button2 &&
    buttonsModalAlert.push(
      <button
        key={"modalAlert-btn2"}
        className={modalAlert.button2.type}
        onClick={(event) => {
          event.stopPropagation();
          modalAlert.button2.action();
        }}
      >
        {modalAlert.button2.name}
      </button>
    );

  return (
    <Container id="divSeccion-listsMerchants">
      {/* inputFilter + container de botones */}
      <FilterInput
        className="filter-input"
        classNameFilterContainer="filter-container"
        placeHolderInput="Buscar lista"
        filterInputFunct={handlerFilterInput}
        charged={lists?.length}
        total={originalLists?.length ?? 0}
        filterArray={[{}]}
        filtersArray={[{}]}
        mainFilter={mainFilter}
        hideButtons={false}
        buttonAdd={{
          showHiddenComponent: showDropdownAddList,
          onClick: (event) => {
            setShowDropdownAddList((prev) => !prev);
          },
          onClickAway: (event) => {
            if (!modalAlert.show && !isVisibleModalInputEdit)
              setShowDropdownAddList(false);
          },
          hiddenComponent: (
            <div className="dropdownAddList">
              <input
                autoFocus
                className="dropdownAddList-input"
                type={"text"}
                placeholder={"Nombre de lista..."}
                value={valueInputNewNameList}
                onChange={(event) =>
                  setValueInputNewNameList(event.target.value)
                }
                onKeyDown={(event) => {
                  if (event.key === "Enter") onClickAddList();
                }}
              />
              <button onClick={() => onClickAddList()}>
                <i>
                  <Icon icon={okIcon} />
                </i>
              </button>
            </div>
          ),
        }}
        hideExportButton={true}
      />

      {/* componete de cargando ... despues mostrar las listas grid */}
      {isLoading ? <Loading /> : renderGrid()}

      {/* modal para mensajes o preguntas */}
      {modalAlert.show && (
        <Modal
          icon={modalAlert.icon}
          onClickBtnClose={(event) => {
            event.stopPropagation();
            modalAlert.onClickBtnClose();
          }}
          title={modalAlert.title}
          message={modalAlert.message}
          buttons={buttonsModalAlert}
        />
      )}

      {/* modal para editar nombre de lista */}
      {isVisibleModalInputEdit && (
        <Modal
          onClickBtnClose={(event) => {
            event.stopPropagation();
            setVisibleModalInputEdit(false);
          }}
          title={"Actualizar nombre de lista"}
          customComponent={
            <input
              autoFocus
              className="inputEditName"
              type={"text"}
              placeholder={selectedList.name}
              value={valueInputEditName}
              onChange={(event) => {
                setValueInputEditName(event.target.value);
              }}
              onKeyDown={async (event) => {
                if (event.key === "Enter") {
                  modalInputEditBtnGuardar();
                }
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          }
          buttons={[
            <button
              key={"modalInputEdit-btnCancelar"}
              className="btnTransparent"
              onClick={(event) => {
                event.stopPropagation();
                setVisibleModalInputEdit(false);
              }}
            >
              Cancelar
            </button>,
            <button
              key={"modalInputEdit-btnGuardar"}
              className="btnPink"
              onClick={async (event) => {
                event.stopPropagation();
                modalInputEditBtnGuardar();
              }}
            >
              Guardar
            </button>,
          ]}
        />
      )}

      <button id="btn-click" style={{ display: "none" }}></button>
    </Container>
  );
}
