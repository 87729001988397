import React, { useState } from "react";
import { Container } from "./styles";
import { Slider, SliderThumb } from "@mui/material";

export const CustomSlider = ({
  // definicion de las props
  valueMin,
  valueMax,
  initialMin,
  initialMax,
  distanceMinBetweenValues = 0,
  step = 1,
  labelIconLeft,
  labelIconRight,
  onChange, // (min , max) => {}
  ...props
}) => {
  //variables
  const [valueInputMin, setValueInputMin] = useState(
    isNaN(Number(initialMin)) ? "" : initialMin
  );
  const [lastValueMin, setLastValueMin] = useState(
    isNaN(Number(initialMin)) ? "" : initialMin
  );
  const [placeholderInputMin, setPlaceholderInputMin] = useState("min");

  const [valueInputMax, setValueInputMax] = useState(
    isNaN(Number(initialMax)) ? "" : initialMax
  );
  const [lastValueMax, setLastValueMax] = useState(
    isNaN(Number(initialMax)) ? "" : initialMax
  );
  const [placeholderInputMax, setPlaceholderInputMax] = useState("max");

  // saber si un valor es numero
  const isNumber = (number) => {
    return !isNaN(Number(number));
  };

  // cuando cambia el valor minimo
  const onChangeValueMin = () => {
    // ver si cambio el valor de min/max
    if (lastValueMin === valueInputMin) return;

    let MIN = undefined;
    // cuando input MIN esta vacio
    if (valueInputMin === "") {
      if (isNumber(valueMin)) {
        setValueInputMin(valueMin);
        setLastValueMin(valueMin);
        MIN = valueMin;
      } else {
        setLastValueMin("");
      }
    }
    // cuando el input contiene algo
    else {
      if (isNumber(valueMin) && valueInputMin < valueMin) {
        setValueInputMin(valueMin);
        setLastValueMin(valueMin);
        MIN = valueMin;
      } else {
        // checar si es mayor al valor MAX
        if (lastValueMax === "") {
          if (isNumber(valueMax)) {
            if (Number(valueInputMin) > valueMax - distanceMinBetweenValues) {
              setValueInputMin(lastValueMin);
              return;
            }
          }
        } else {
          if (
            Number(valueInputMin) >
            Number(lastValueMax) - distanceMinBetweenValues
          ) {
            setValueInputMin(lastValueMin);
            return;
          }
        }
        setLastValueMin(valueInputMin);
        MIN = Number(valueInputMin);
      }
    }

    // obtener el valor MIN
    let MAX = undefined;
    if (lastValueMax !== "") MAX = Number(lastValueMax);
    else if (lastValueMax === "" && isNumber(valueMax)) MAX = valueMax;
    onChange(MIN, MAX);
  };

  // cuando cambia el valor maximo
  const onChangeValueMax = () => {
    // ver si cambio el valor de min/max
    if (lastValueMax === valueInputMax) return;

    let MAX = undefined;
    // cuando input Max esta vacio
    if (valueInputMax === "") {
      if (isNumber(valueMax)) {
        setValueInputMax(valueMax);
        setLastValueMax(valueMax);
        MAX = valueMax;
      } else {
        setLastValueMax("");
      }
    }
    // cuando el input contiene algo
    else {
      if (isNumber(valueMax) && valueInputMax > valueMax) {
        setValueInputMax(valueMax);
        setLastValueMax(valueMax);
        MAX = valueMax;
      } else {
        // checar si es menor al valor MIN
        if (lastValueMin === "") {
          if (isNumber(valueMin)) {
            if (Number(valueInputMax) < valueMin + distanceMinBetweenValues) {
              setValueInputMax(lastValueMax);
              return;
            }
          }
        } else {
          if (
            Number(valueInputMax) <
            Number(lastValueMin) + distanceMinBetweenValues
          ) {
            setValueInputMax(lastValueMax);
            return;
          }
        }
        setLastValueMax(valueInputMax);
        MAX = Number(valueInputMax);
      }
    }

    // obtener el valor MIN
    let MIN = undefined;
    if (lastValueMin !== "") MIN = Number(lastValueMin);
    else if (lastValueMin === "" && isNumber(valueMin)) MIN = valueMin;
    onChange(MIN, MAX);
  };

  // render del input
  const inputRender = (
    labelDefault,
    valueDefault,
    placeholder,
    setPlaceholder,
    value,
    setValue,
    onChangeValue
  ) => {
    return (
      <input
        type={"number"}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          isNumber(event.target.value) && setValue(event.target.value);
        }}
        onKeyDown={(event) => {
          const caracteresValidos = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            ".",
            "-",
            "Backspace",
            "ArrowRight",
            "ArrowLeft",
          ];
          if (event.key === "Enter") {
            onChangeValue();
          } else if (!caracteresValidos.includes(event.key)) {
            event.preventDefault();
          }
        }}
        onBlur={(event) => {
          // al perder focus <-equivale a -> enter
          onChangeValue();
          setPlaceholder(labelDefault);
        }}
        onFocus={(event) => {
          if (isNumber(valueDefault)) setPlaceholder(valueDefault);
        }}
      />
    );
  };

  // cuando cambia el valor del slider
  const onChangeSlider = (event, newValues, activeThumb) => {
    if (!Array.isArray(newValues)) return;

    // cuando se mueve el boton circular de la izq
    if (activeThumb === 0) {
      const vMax = lastValueMax === "" ? valueMax ?? 100 : Number(lastValueMax);
      const newValueMin = Math.min(
        newValues[0],
        vMax - distanceMinBetweenValues
      );
      setLastValueMin(newValueMin);
      setValueInputMin(newValueMin);
    }
    // cuando se mueve el boton circular de la der
    else {
      const vMin = lastValueMin === "" ? valueMin ?? 0 : Number(lastValueMin);
      const newValueMax = Math.max(
        newValues[1],
        vMin + distanceMinBetweenValues
      );
      setLastValueMax(newValueMax);
      setValueInputMax(newValueMax);
    }
  };

  const onChangeSliderFinal = (event, newValues) => {
    if (!Array.isArray(newValues)) return;

    // obtener el valor MIN
    let MIN = undefined;
    if (lastValueMin !== "") MIN = Number(lastValueMin);
    else if (lastValueMin === "" && isNumber(valueMin)) MIN = valueMin;

    // obtener el valor MIN
    let MAX = undefined;
    if (lastValueMax !== "") MAX = Number(lastValueMax);
    else if (lastValueMax === "" && isNumber(valueMax)) MAX = valueMax;

    onChange(MIN, MAX);
  };

  // definicion HTML del componente
  return (
    <Container className={props.className}>
      <div className="inputs-container">
        {/* input mix */}
        <div className="div-input">
          {labelIconLeft && <label> {labelIconLeft} </label>}
          {inputRender(
            "min",
            valueMin,
            placeholderInputMin,
            setPlaceholderInputMin,
            valueInputMin,
            setValueInputMin,
            onChangeValueMin
          )}
          {labelIconRight && <label> {labelIconRight} </label>}
        </div>

        {/* separador */}
        <label className="label-separator"> - </label>

        {/* input max */}
        <div className="div-input">
          {labelIconLeft && <label> {labelIconLeft} </label>}
          {inputRender(
            "max",
            valueMax,
            placeholderInputMax,
            setPlaceholderInputMax,
            valueInputMax,
            setValueInputMax,
            onChangeValueMax
          )}
          {labelIconRight && <label> {labelIconRight} </label>}
        </div>
      </div>

      {/* componente slider */}
      {isNumber(valueMin) && isNumber(valueMax) && (
        <div className="div-slider">
          <Slider
            components={{
              Thumb: (props) => {
                const { children, ...other } = props;
                return (
                  <SliderThumb {...other}>
                    {children}
                    <span className="thumb-line" />
                    <span className="thumb-line" />
                    <span className="thumb-line" />
                  </SliderThumb>
                );
              },
            }}
            value={[
              lastValueMin === ""
                ? valueMin ?? 0
                : isNumber(lastValueMin)
                ? Number(lastValueMin)
                : valueMin ?? 0,
              lastValueMax === ""
                ? valueMax ?? 100
                : isNumber(lastValueMax)
                ? Number(lastValueMax)
                : valueMax ?? 100,
            ]}
            onChange={onChangeSlider}
            onChangeCommitted={onChangeSliderFinal}
            valueLabelDisplay="auto"
            disableSwap
            min={valueMin ?? 0}
            max={valueMax ?? 100}
            step={step}
          />
        </div>
      )}
    </Container>
  );
};
