import React, { useState, useEffect } from "react";
import { RequestModal } from "./styles";
import axios from "axios";

// Components
import ProductosElegidosModal from "./ProductosElegidosModal.js";
import ProductosExportarModal from "./ProductosExportarModal.js";
import ProductosEntregadosModal from "./ProductosEntregadosModal.js";

export default function ContentohExportModal(props) {
  sessionStorage.setItem("productsToExport", JSON.stringify(props.products));
  const [retailersGeneral, setRetailersGeneral] = useState([]);
  const [listRetailers, setListRetailers] = useState([]);
  const [step, setStep] = useState(1);
  const [component, setComponet] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [activesSelected, setActivesSelected] = useState([true, true, true]);
  const [retailersSelected, setRetailersSelected] = useState([]);
  const totalProducts = props.products.length;

  useEffect(() => {
    const financedRetailers = JSON.parse(
      sessionStorage.getItem("company")
    )?.financedRetailers;
    if (financedRetailers?.length === 0) {
      axios
        .get(`${process.env.REACT_APP_RETAILER_ENDPOINT}`, {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        })
        .then((result) =>
          setRetailersGeneral(Object.values(JSON.parse(result.data.body).data))
        )
        .catch((err) => console.log(err));
    } else {
      setRetailersGeneral(
        financedRetailers?.map((ret) => ({
          name: ret.name,
          id: ret.retailer_id,
        }))
      );
    }
    return () => sessionStorage.removeItem("productsToExport");
  }, []);

  useEffect(() => {
    const articlesSelected = props.products;
    const temp = [];
    const arrayProducts = [];
    articlesSelected.forEach((e) => {
      arrayProducts.push(e);
      e.retailersAvailable.forEach((ret) => {
        if (temp.indexOf(ret.id) === -1) temp.push(ret);
      });
    });

    setListRetailers(temp);
    setProductsList(arrayProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const company = JSON.parse(sessionStorage.getItem("company"));
    const temp = listRetailers.map((e) => e.id);
    // if (company.is_retailer === 1 && company.retailers.length > 0) {
    //   const validRetailers = company.retailers.map(({ id }) => id);
    //   temp = validRetailers;
    // } else {
    //   temp = listRetailers.map((e) => e.id);
    // }
    setRetailersSelected(temp);
  }, [listRetailers]);

  useEffect(() => {
    switch (step) {
      case 1:
        setComponet(
          <ProductosElegidosModal
            listRetailers={listRetailers}
            retailersGeneral={retailersGeneral}
            close={props.close}
            setStep={setStep}
            activesSelected={activesSelected}
            setActivesSelected={setActivesSelected}
            setRetailersSelected={setRetailersSelected}
            totalProducts={totalProducts}
            isUserRetailer={props.isUserRetailer}
          />
        );
        break;
      case 2:
        setComponet(
          <ProductosExportarModal
            listRetailers={retailersGeneral}
            close={props.close}
            setStep={setStep}
            productsList={productsList}
            retailersSelected={retailersSelected}
            activesSelected={activesSelected}
            totalProducts={totalProducts}
          />
        );
        break;
      case 3:
        setComponet(
          <ProductosEntregadosModal
            listRetailers={listRetailers}
            close={props.close}
            retailers={retailersSelected}
            setStep={setStep}
          />
        );
        break;
      default:
        setComponet(
          <ProductosElegidosModal
            listRetailers={listRetailers}
            retailersGeneral={retailersGeneral}
            close={props.close}
            setStep={setStep}
            activesSelected={activesSelected}
            setActivesSelected={setActivesSelected}
            setRetailersSelected={setRetailersSelected}
            totalProducts={totalProducts}
          />
        );
        break;
    }
  }, [
    step,
    listRetailers,
    props.close,
    retailersGeneral,
    props.products,
    activesSelected,
    productsList,
    retailersSelected,
    totalProducts,
    props.isUserRetailer,
  ]);

  return (
    <RequestModal>
      <div className="transparent-background">{component}</div>
    </RequestModal>
  );
}
