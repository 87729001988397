import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  padding: 35px 65px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 90px 1fr 100px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const TopSide = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #503d66;
  }
`;

export const MidSide = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 10px;
  /* overflow-y: auto; */

  .header {
    height: 35px;
    display: flex;
    align-items: center;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #503d66;
    border-bottom: 1px solid #f0eef2;
  }

  .mid {
    flex: 1;
    max-height: 290px;
    overflow-y: auto;
    padding-top: 5px;
  }


  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 65px;
    border-top: 1px solid #f0eef2;
  }
`;

export const BotSide = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
  }
`;

export const BoxBtn = styled.div`
  padding: 15px 45px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #d4d1d7;
  border-radius: 15px;

  & > div {
    margin-left: 20px;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #817393;
    -webkit-touch-callout: none; /* iPhone OS, Safari */
    -webkit-user-select: none; /* Chrome, Safari 3 */
    -khtml-user-select: none; /* Safari 2 */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
    & > p:first-child {
      font-family: Raleway;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #503d66;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: #e33aa9;
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #817393;
  & > figure {
    margin-right: 15px;
    & > img {
      object-fit: contain;
      cursor: pointer;
    }
  }
  & > p {
    -webkit-touch-callout: none; /* iPhone OS, Safari */
    -webkit-user-select: none; /* Chrome, Safari 3 */
    -khtml-user-select: none; /* Safari 2 */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
    & > span {
      font-weight: 600;
    }
  }
`;

export const Column = styled.div`
  text-align: center;
  width: ${({ width }) => width || "15%"};
  /* min-width: ${({ minWidth }) => minWidth || "100px"}; */
`;

export const Row = styled.div`
  display: flex;
  > div {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #817393;
  }
`;

export const Close = styled.figure`
  position: fixed;
  right: 10px;
  > img {
    width: 50px;
    height: 50px;
  }
`;

export const UploadFiles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  padding: 0 40px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #f0eef2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  .label {
    border-radius: 30px;
    border: 1px solid #f0eef2;
    padding: 10px 25px;
    box-sizing: border-box;
    width: 130px;
    & label {
      display: flex;
      & > img {
        margin-right: 10px;
      }
    }
    & > input[type="file"] {
      display: none;
    }
  }
  .btn {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 0 40px;
      box-sizing: border-box;
      border-radius: 30px;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #f0eef2;
      border: 0;
      background: #e33aa9;
      cursor: pointer;
    }
  }
`;

export const Button = styled.button`
  display: inline-block;
  width: 45px;
  border: 1px solid #e33aa9;
  background-color: #fff;
  border-radius: 30px;
  margin-right: 5px;
  box-sizing: bor;
`;

export const RetailersDiv = styled.div`
  position: relative;
  width: 85px;
`;

export const RetailersBtn = styled.div`
  display: flex;
  align-items: center;
  width: 85px;
  background: #fafafa;
  border-radius: 2px;
  > p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: ${({ color }) => (color ? color : "#d4d1d7")};
    padding: 0 5px;
    text-align: center;
    width: 80%;
    box-sizing: border-box;
  }
  &::after {
    border-left: 1px solid #e33aa9;
    border-top: 1px solid #e33aa9;
    width: 4px;
    content: "";
    height: 4px;
    position: absolute;
    right: 7px;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    top: 35%;
  }
  &::before {
    border-left: 1px solid #e33aa9;
    width: 1px;
    content: "";
    height: 15px;
    right: 15px;
    position: absolute;
    z-index: 2;
  }
`;

export const ListContainer = styled.div`
  color: #f0eef2;
  font-family: Avenir Next;
  padding: 0 50px;
  & > ul {
    max-height: 75px;
    margin-bottom: 10px;
    overflow-y: auto;
  }
  & > p {
    margin-bottom: 10px;
  }
`;

export const AlertError = styled.div`
  color: red;
  font-family: Avenir Next;
  font-size: 11px;
  margin-top: -8px;
  margin-left: 0.8%;
  margin-bottom: 5px;
`;
