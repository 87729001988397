import moment from "moment";
export const setCanonicFilter = (filterName, filterValues) => {
  const filtersObject = {
    categories: "filterByCategory",
    retailers: "filterByRetailer",
    upc: "filterByUpc",
  };
  const filterQueryName = filtersObject[filterName];
  const filterParam = {};
  filterParam[filterQueryName] = filterValues;
  return filterParam;
};
export const updateReportFilters = (filters, setReportFilters) => {
  try {
    const filtersActive = filters;
    let newFilters = {};
    filtersActive.forEach((filterItem) => {
      switch (filterItem.filter) {
        case "country":
          newFilters.filterByCountry = filterItem.values;
          break;
        case "company_name":
          newFilters.filterByCompany = filterItem.values;
          break;
        case "orderId":
          newFilters.filterByOrder = filterItem.values;
          break;
        case "status":
          newFilters.filterByEstatus = filterItem.values;
          break;
        case "prio":
          newFilters.filterByPrio = filterItem.values;
          break;
        case "retailers":
          newFilters.filterByRetailer = filterItem.values;
          break;
        case "date":
          newFilters.filterByDate = {
            startDate: moment(filterItem.values.startDate)
              .utc()
              .format("YYYY-MM-DD"),
            endDate: moment(filterItem.values.endDate)
              .utc()
              .format("YYYY-MM-DD"),
          };
          break;
        default:
          break;
      }
    });
    setReportFilters(newFilters);
  } catch (err) {
    console.log(err, "Unable to set filters for report");
  }
};
