import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 75px 100% max(100px);
  gap: 5px 0px;
  grid-template-areas:
    "header"
    "body"
    "footer";
  font-family: Roboto, sans-serif;
  height: calc(100vh - 280px);
  width: 100%;
`;

export const Header = styled.div`
  grid-area: header;
  font-family: Roboto;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  img {
    padding: 5px;
  }
  .border_red {
    border-color: #b64545 !important;
  }
  .date {
    color: #707070;
    font-size: 12px;
  }
  .button__icon {
    button {
      padding: 5px;
      border-color: #e9e9e9;
      & > p > span {
        margin: 0;
        font-size: 24px;
        color: #afafaf;
      }
    }
  }
  .button__general {
    display: flex;
    align-items: center;
    & > * {
      margin-left: 15px;
    }
  }
  .button__retailer_control {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
  .button__steps {
    position: relative;
  }
  .links {
    display: flex;
    z-index: 2;
    & > * {
      margin-right: 10px;
    }
    & > div > button {
      padding: 10px 13px;
    }
  }
  .button__steps:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid #e33aa9;
    background: black;
    width: calc(100% - 10px);
    transform: translateY(-50%);
  }
`;

export const Body = styled.div`
  grid-area: body;
  max-width: 100%;
  padding: 0 30px;
`;

export const Footer = styled.div`
  grid-area: footer;
  grid-row-start: 3;
  margin-right: 30px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px #e6e6e6;
  background-color: #ffffff;
  z-index: 2;
  & > div {
    display: flex;
    align-items: center;
  }
  .total {
    font-family: Roboto;
    font-size: 20px;
    margin-right: 10px;
  }
`;

export const InfoCard = styled.div`
  background-color: #f7f7fc;
  border-radius: 10px;
  font-family: Roboto;
  width: 100px;
  height: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  .title {
    color: #808080;
    font-size: 10px;
  }
  .value {
    color: #262626;
    font-size: 15px;
  }
`;

export const TotalDetail = styled.div`
  color: #707070;
  font-family: Roboto;
  font-size: 10px;
  display: flex;
  .container {
    text-align: right;
    margin-right: 10px;
    & > p {
      margin-bottom: 4px;
    }
  }
`;

export const Center = styled.p`
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  & > span {
    margin-left: 10px;
  }
`;

export const PaymentButtonContainer = styled.div`
  & > button  {
    width: fit-content;
  }
  ${({ orderBeingCreated }) =>
    orderBeingCreated &&
    `
  display: flex;
  flex-direction: column;
  align-items: center;
  & > button {
    max-width: 90px;
    background-color: #fff !important;
    border: 1px solid #70707055;
    & > p > {
      img {
        width: 20px;
      }
    }
  }
  & > p {
    color: #707070;
    font-family: Roboto;
    font-size: 10px;
    margin-top: 5px;
  }
  `}
`;
