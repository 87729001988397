import axios from "axios";
import { saveAs } from "file-saver";

export const getUsers = async () => {
  let users = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
    headers: {
      Authorization: sessionStorage.getItem("jwt"),
    },
  });
  sessionStorage.setItem(
    "categoriesByUser",
    JSON.stringify(JSON.parse(users.data.body).data[2])
  );
  return JSON.parse(users.data.body).data[2];
};

export const getProducts = async () => {
  let products = await axios.get(process.env.REACT_APP_LIST_MERCHANTS, {
    headers: {
      Authorization: sessionStorage.getItem("jwt"),
    },
  });
  return JSON.parse(products.data.body);
};

export const fetchAddArticlesList = (listId, articlesId) => {
  return new Promise(async (response, error) => {
    try {
      // realizar el requestHTTP get
      let URL = `${process.env.REACT_APP_LIST_MERCHANTS_ARTICLES_ENDPOINT}`; // endpoint
      const data = {
        listId: listId,
        articlesId: articlesId,
      };
      const respHTTPjson = await axios.post(URL, data);

      // verificar si existe un error en la peticion realizada
      if (respHTTPjson.status !== 200) {
        response({
          message: "No fue posible llevar a cabo la operacion",
          errorDetails: "Por favor, comprueba tu conexion a internet",
        });
        return;
      }

      // obtener body backend
      const body = JSON.parse(respHTTPjson.data.body);

      // verificar si existe error desde el backend
      if (respHTTPjson.data.statusCode !== 200) {
        response({
          message: body.message,
          errorDetails: body.errorDetail,
        });
        return;
      }

      // cuando se ejecuto bien el create ... tomar un posible message
      response({
        data: true,
        message: body.message,
        errorDetails: body.errorDetail,
      });
    } catch (err) {
      response({
        message: "Algo salio mal al agregar los productos a la lista",
        errorDetails: err.message,
      });
    }
  });
};

export const fetchDeleteArticlesList = (listId, articlesId) => {
  return new Promise(async (response, error) => {
    try {
      // realizar el requestHTTP get
      let URL = `${process.env.REACT_APP_LIST_MERCHANTS_ARTICLES_ENDPOINT}`; // endpoint
      const data = {
        listId: listId,
        articlesId: articlesId,
      };
      const respHTTPjson = await axios.delete(URL, {
        data: data,
      });

      // verificar si existe un error en la peticion realizada
      if (respHTTPjson.status !== 200) {
        response({
          message: "No fue posible llevar a cabo la operacion",
          errorDetails: "Por favor, comprueba tu conexion a internet",
        });
        return;
      }

      // obtener body backend
      const body = JSON.parse(respHTTPjson.data.body);

      // verificar si existe error desde el backend
      if (respHTTPjson.data.statusCode !== 200) {
        response({
          message: body.message,
          errorDetails: body.errorDetail,
        });
        return;
      }

      // cuando se ejecuto bien el create ... tomar un posible message
      response({
        data: true,
        message: body.message,
        errorDetails: body.errorDetail,
      });
    } catch (err) {
      console.log("error catch delete articles list", err);
      response({
        message: "Algo salio mal al eliminar los productos de la lista",
        errorDetails: err.message,
      });
    }
  });
};

export const loadMoreProducts = async (
  startIndex,
  canonicFilter,
  stateFlags,
  setStateFlags,
  setArticlesList,
  setOriginalList,
  setStartIndex,
  originalList,
  listType,
  setProductCount
) => {
  try {
    setStateFlags((prev) => {
      prev.isLoading = true;
      return prev;
    });
    const newStartIndex =
      startIndex +
      parseInt(process.env.REACT_APP_RETAILERS_INCREMENT_LIMIT, 10);

    const params = { startIndex: newStartIndex, productsType: listType };
    if (canonicFilter) {
      params.canonicFilter = canonicFilter;
    }
    setStartIndex(newStartIndex);

    const newProducts = await getProducts(
      params,
      stateFlags,
      setStateFlags,
      setProductCount
    );

    setArticlesList((prev) => [...prev, ...newProducts]);

    setOriginalList((prev) => [...prev, ...newProducts]);
  } catch (err) {
    console.log(err, "unable to load more products,please report this to IT");
    setArticlesList(originalList);
    setOriginalList(originalList);
  }
};

export const getExportables = async (
  flagUpdateFunction,
  reportFilters,
  aditionalFilter
) => {
  flagUpdateFunction((prev) => ({ ...prev, loadingExportables: true }));
  try {
    const defaultRetailers = JSON.parse(
      sessionStorage.getItem("company")
    ).retailers.map((retailer) => retailer.id);

    const email = encodeURIComponent(
      JSON.parse(sessionStorage.getItem("user")).email
    );
    let reportFiltersCopy = reportFilters ?? {
      filterByRetailer: defaultRetailers,
    };
    //if no retailer was selected from filter, get only retailers linked to acc products
    if (!reportFiltersCopy.filterByRetailer) {
      reportFiltersCopy.filterByRetailer = defaultRetailers;
    }
    const filtersForQuery = encodeURIComponent(JSON.stringify(reportFilters));
    const jsFilter = aditionalFilter
      ? `&jsFilter=${encodeURIComponent(JSON.stringify(aditionalFilter))}`
      : "";
    const response = await axios.get(
      `${process.env.REACT_APP_REPORTS}?email=${email}&filters=${filtersForQuery}&reportType=2${jsFilter}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    saveAs(JSON.parse(response.data.body).url);
  } catch (error) {
    console.log(error);
  }
  flagUpdateFunction((prev) => ({ ...prev, loadingExportables: false }));
};
export const updateSessionStorageProduct = (product) => {
  let prod = product;
  prod.retailersAvailable = product.retailers;
  prod.upc = product.article.upc;
  prod.name = product.article.name;
  prod.categoryName = product.article.category;
  prod.id_category = product.article.id_category;
  prod.id_article = product.article.id_article;
  prod.asignations = product.asignations ?? [];
  if (!prod.orderId) {
    prod.datasheet_status = product.article_status;
    prod.description_status = product.article_status;
    prod.images_status = product.article_status;
  }
  const user = JSON.parse(sessionStorage.getItem("user"));
  const company = JSON.parse(sessionStorage.getItem("company"));
  prod.services = [
    {
      id_order: prod.id_order,
      id_retailer: company.id,
      id_article: prod.id_article,
      service: "datasheet",
      quantity: 1,
      price: 0,
      id_user: user.id_user,
      datasheet_common: null,
      discount: null,
      status: product.article_status,
    },
  ];
  sessionStorage.setItem("version", prod.version);
  sessionStorage.setItem("productSelected", JSON.stringify(prod));
  sessionStorage.setItem(
    "productEdit",
    JSON.stringify({
      ArticleId: prod?.article.id_article,
      idCategory: prod?.article.id_category,
      product: prod,
    })
  );
  return prod;
};
