import React, { useState, useEffect, useReducer } from "react";
import { Redirect } from "react-router-dom";
import { MainContainer, Close } from "./styles";
import close from "../../../../assets/IconComponents/Cerrar.svg";
import Top from "./Top";
import Mid from "./Mid";
import Bottom from "./Bottom";
import { getUser } from "../../../_utils/data";
import axios from "axios";

const reducerForArticles = (state, action) => {
  const temp = state.slice();
  switch (action.action) {
    case "depot":
      temp[action.index].depot = action.depot;
      temp[action.index].category = action.category;
      temp[action.index].type = action.type;
      temp[action.index].lists.category = action.lists.category;
      temp[action.index].lists.type = action.lists.type;
      return temp;
    case "category":
      temp[action.index].category = action.category;
      temp[action.index].type = action.type;
      temp[action.index].lists.type = action.lists.type;
      return temp;
    case "type":
      temp[action.index].type = action.type;
      return temp;
    case "delete":
      const [removed] = temp.splice(action.index, 1);
      const indexes = [];
      temp.forEach((e, i) => {
        if (e.upc === removed.upc) indexes.push(i);
      });
      if (indexes.length > 0) {
        if (indexes.length === 1) delete temp[indexes[0]].error;
        else {
          indexes.forEach((e, i) => {
            if (indexes.length - 1 !== i) delete temp[e].error;
          });
        }
      }
      return temp;
    case "newArticle":
      return [...state, action.value];
    case "addByExcel":
      const newArticles = [];
      action.values.forEach((article, i) => {
        if (
          [...temp, ...newArticles].find((f) => f.upc === article.upc) ||
          action.isDuplicate[i]
        )
          article.error = true;
        newArticles.push(article);
      });
      return [...state, ...newArticles];
    case "sku":
    case "upc":
      let indx;
      if (action.isRetailer) {
        indx = temp.findIndex(
          (f) => f.upc === action.value && f.provider === action.provider
        );
      } else {
        indx = temp.findIndex((f) => f.upc === action.value);
      }
      if (![-1, action.index].includes(indx)) temp[action.index].error = true;
      else if (temp[action.index].error) delete temp[action.index].error;
      temp[action.index][action.action] = action.value;
      return temp;
    case "name":
    case "provider":
      temp[action.index][action.action] = action.value;
      return temp;
    case "updateCategories":
      temp[action.index].provider = action.value;
      return temp;
    case "DbDuplicateUPC":
      if (action.error) temp[action.index].error = true;
      else if (temp[action.index].error) delete temp[action.index].error;
      temp[action.index].upc = action.value;
      return temp;
    default:
      return temp;
  }
};

export default function Add() {
  const isRetailer =
    +JSON.parse(sessionStorage.getItem("user")).is_retailer === 1;
  const [firstLoad, setFirstLoad] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [articles, setArticles] = useReducer(reducerForArticles, []);
  const [lists, setLists] = useState({
    categories: [],
    depot: [],
    category: [],
    type: [],
    providers: [],
  });
  const [newArticle, setNewArticle] = useState({
    sku: "",
    upc: "",
    name: "",
    depot: "",
    category: "",
    type: "",
    provider: isRetailer ? "" : [],
  });

  useEffect(() => {
    const { depot, category, type } = newArticle;
    if (depot && category && !type) changeSelects("category", category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newArticle]);
  const changeSelects = (select, value, article = undefined) => {
    const valueToFilter =
      select === "depot" ? value : article ? article.depot : newArticle.depot;
    const depot = lists.categories[valueToFilter];
    switch (select) {
      case "depot":
        const tempCategory = Object.keys(depot.sub_category);
        if (article !== undefined) {
          setArticles({
            action: "depot",
            index: article.index,
            depot: value,
            category: tempCategory.sort()[0],
            type: "",
            lists: {
              category: tempCategory.sort(),
              type: [],
            },
          });
        } else {
          setLists((prev) => ({
            ...prev,
            category: tempCategory.sort(),
            type: [],
          }));
          setNewArticle((prev) => ({
            ...prev,
            depot: value,
            category: tempCategory.sort()[0],
            type: "",
          }));
        }
        break;
      case "category":
        const tempTypes = Object.keys(depot.sub_category[value].sub_category);
        if (article !== undefined) {
          setArticles({
            action: "category",
            index: article.index,
            category: value,
            type: tempTypes.sort()[0],
            lists: {
              type: tempTypes.sort(),
            },
          });
        } else {
          setNewArticle((prev) => ({
            ...prev,
            category: value,
            type: tempTypes.sort()[0],
          }));
          setLists((prev) => ({
            ...prev,
            type: tempTypes.sort(),
          }));
        }
        break;
      case "type":
        if (article !== undefined) {
          setArticles({
            action: "type",
            index: article.index,
            type: value,
          });
        } else {
          setNewArticle((prev) => ({
            ...prev,
            type: value,
          }));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (firstLoad) {
      getUser({ query: "isProviders=1" }).then((r) => {
        setLists((prev) => ({
          ...prev,
          providers: JSON.parse(r.data.body)
            .data.filter((f) => f.trade_name)
            .map((e) => ({
              value: e.id_company,
              name: e.trade_name,
            })),
        }));
      });
      const company = JSON.parse(sessionStorage.getItem("company"));
      const key = company.financedRetailers ? "financedRetailers" : "retailers";
      const query =
        isRetailer || key === "financedRetailers"
          ? `?id=${encodeURIComponent(
              company[key].map((ret) => ret.id).join(",")
            )}`
          : "";
      const config = {
        headers: { Authorization: sessionStorage.getItem("jwt") },
      };
      axios
        .get(`${process.env.REACT_APP_CATEGORY_ENDPOINT}${query}`, config)
        .then((r) => {
          const data = JSON.parse(r.data.body).data;
          setLists((prev) => ({
            ...prev,
            categories: data,
            depot: Object.keys(data).sort(),
          }));
        });
      setFirstLoad(false);
    }
  }, [firstLoad, newArticle, isRetailer]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <MainContainer>
      <Close onClick={() => setRedirect("Dashboard")}>
        <img src={close} alt="Regresar" />
      </Close>
      <Top isRetailer={isRetailer} />
      <Mid
        articles={articles}
        setArticles={setArticles}
        changeSelects={changeSelects}
        lists={lists}
        newArticle={newArticle}
        setNewArticle={setNewArticle}
        setLists={setLists}
        isRetailer={isRetailer}
      />
      <Bottom
        articles={articles}
        lists={lists}
        setArticles={setArticles}
        isRetailer={isRetailer}
      />
    </MainContainer>
  );
}
