import React from "react";
import PropTypes from "prop-types"; //especificar de que tipo son las props
import { ContainerGridLists } from "./styles";
import ListCard from "./ListCard/ListCard";

export default function GridLists({
  lists,
  handlers,
  functions,
  isVisibleModalAlert,
  isVisibleModalInputEdit,
}) {
  return (
    <ContainerGridLists>
      {lists.map((l, i) => (
        <ListCard
          list={l}
          handlers={handlers}
          functions={functions}
          key={"MerchantsLists" + i}
          isVisibleModalAlert={isVisibleModalAlert}
          isVisibleModalInputEdit={isVisibleModalInputEdit}
        />
      ))}
    </ContainerGridLists>
  );
}

// definir el tipo de cada prop
GridLists.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired, // id de la lista
      name: PropTypes.string.isRequired, // nombre de la lista
      articlesCount: PropTypes.number, // total de productos en la lista
    })
  ),
};

// definir los valores por default de cada prop
GridLists.defaultProps = {
  id: -1,
  name: "",
  articlesCount: 0,
};
