import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  overflow: auto;
`;

export const LeftContainer = styled.div`
  width: 390px;
  position: relative;
  border-radius: 7px;
  height: 613px;
`;

export const RoleBadge = styled.p`
  padding: 3px 10px;
  background: #603888;
  border-radius: 4px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 19px;
  letter-spacing: -0.015em;
  color: #ededed;
  width: fit-content;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f4f7;
  border-radius: 10px;
  padding: 15px 12px;

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    margin-right: 15px;
  }
  #title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.015em;
    color: #262626;

    & + * {
      font-family: Roboto;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #707070;

      & + * {
        margin-top: 5px;
      }
    }
  }

  & + * {
    margin-top: 20px;
  }
`;

export const MainData = styled.div`
  box-sizing: border-box;
`;

export const DataItem = styled.div`
  display: flex;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.015em;
  padding: 0 30px;

  figure {
    & + * {
      margin-left: 11px;
    }
  }

  .info-container {
    display: flex;
    width: 100%;

    p:first-child {
      width: 117px;
      color: #503d66;

      & + p {
        margin-left: 20px;
        color: #817393;
      }
    }
  }

  & + * {
    margin-top: 12px;
  }

  & + ul {
    margin-top: 24px;
  }
`;

export const Navbar = styled.ul`
  padding: 0;
  width: 100%;
  background-color: #f4f4f7;
  border-radius: 10px;
  overflow: hidden;
  a {
    text-decoration: none;
    justify-content: space-between;
    width: 100%;
    padding: 13px 24px;

    .sub-menu {
      background-color: #f4f4f7;
      padding: 13px;
      padding-left: 80px;
      p {
        font-family: "Avenir Next";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.015em;
        color: #503d66;
      }
      display: none;

      &:hover {
        background-color: #b3b3b3;
      }
    }

    &.active {
      background-color: #e6e6e6;
      padding: 0;
      #leftContainer {
        padding: 13px;
        padding-left: 24px;
      }

      .sub-menu {
        display: block;
      }
    }
    &:hover {
      background-color: #b3b3b3;
    }
  }

  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    #leftContainer {
      display: flex;
      align-items: center;
      figure {
        width: 14px;
        height: 14px;
        img {
          width: 100%;
        }

        & + * {
          margin-left: 18px;
        }
      }
      p {
        font-family: "Avenir Next";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.015em;
        color: #503d66;
      }
    }
    figure {
      width: 14px;
      height: 14px;
      img {
        width: 100%;
      }

      &:last-child {
        line-height: 55px;
      }
    }
    #arrow {
      width: 6px;
      height: 10px;
      img {
        width: 100%;
      }
    }
  }
`;

export const RightContainer = styled.div`
  margin-right: 30px;
  padding-left: 20px;
  box-sizing: border-box;
  flex: 0%;
`;

export const Alert = styled.div`
  width: 100%;
  height: 50px;
  background: #d43594;
  border-radius: 7px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #f7e3ef;
  }
  figure {
    width: 15px;
    height: 20px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
  #close {
    position: absolute;
    top: 16px;
    right: 5px;
  }

  & + * {
    margin-top: 30px;
  }
`;

export const CurrentPlan = styled.div`
  padding: 20px;
  background: #fafafa;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;

  .plan-info-container {
    h2 {
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #ba0070;

      .used-products {
        color: #d4d1d7;
        border-right: 2px solid #e33aa9;
        padding-right: 10px;

        & + * {
          margin-left: 10px;
        }
      }

      .products-limit {
        color: #503d66;

        & + * {
          margin-left: 20px;
        }
      }

      .products-title {
        color: #817393;
      }
    }

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #817393;
      & + * {
        margin-top: 5px;
      }
    }
  }

  .plan-button-container {
    display: flex;
    align-items: center;
    button {
      padding: 9px 18px;
      background-color: #e33aa9;
      border-radius: 30px;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      border: none;
      color: #ededed;
    }
  }
`;

export const InviteUsersContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  .modal-enviado{
    max-width: 250px;
    background: none;
  }

  .form-container {
    height: fit-content;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 15px 20px;
    margin-top: 150px;
    min-width:328px;

    p {
      color: #262626;
      font-family: Roboto;
      font-size: 17px;
    }

    input {
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      height: 28px;
      padding-left: 10px;
    }

    .invitation-container {
      margin-top:10px;
      .invite-provider {
        input {
          width: 200px;

          & + * {
            margin-left: 10px;
          }
        }

        & + * {
          margin-top: 10px;
        }
      }
    }
    .add-invitation {
      background-color: transparent;
      color: #e33aa9;
      font-family: Roboto;
      font-size: 10px;
      border: none;
      cursor: pointer;
    }

    .email-container {
      input {
        width: 300px;
        border:none;
        font-size: 12px;
        font-family:Roboto, sans-serif;
      }
      input:before{
        border-bottom: 0px solid rgba(0, 0, 0, 0.42);
      }

      & + * {
        margin-top: 10px;
      }
    }

    .inputs-container {
      .input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 12px;
        }

        input {
          height: 24px;
          font-size: 12px;
        }

        #category-select {
          .button-list {
            padding: 5px 10px;
            border: none;
            border-radius: 3px;

            p {
              color: #808080;
            }

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }

        & + * {
          margin-top: 10px;
        }
      }

      & + * {
        margin-top: 10px;
      }
    }
    .buttons-container {
      display: flex;
      justify-content: flex-end;

      button {
        border-radius: 17px;
        font-family: Roboto;
        font-size: 12px;
        background-color: transparent;
        border: 1px solid #e33aa9;
        color: #e33aa9;
        padding: 5px 10px;

        & + * {
          margin-left: 10px;
        }

        &.invite-button {
          background-color: #e33aa9;
          color: #fff;
        }
      }
    }
  }
`;
