import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  ContainerPrincipal,
  Container,
  Progressdiv,
  TablesHead,
  TablesRowH,
  TablesHeadRow,
  Tables,
  NumberProduct,
  Title,
  TitleFooter,
  TitleComents,
  Subtitle,
  TitleImages,
  Button,
  TitleVersion,
  TablesRowD,
  TitleContainCat,
  SubtitleContainCat,
  ContainerLeft,
  TitleCoantin,
  TitleCoantinVersion,
  HeaderTitle,
  AvatarContainer,
  NewComment,
} from "./styles";
import PopUpEdit from "./popUpEdit";
import AssignTo from "./assignTo";
import moment from "moment";
import axios from "axios";
import { useCloseModal } from "../../_utils/customHooks";
import {
  getStatusColor,
  getShortStatus,
  getImage,
  getProfilePicture,
} from "../../_utils/helper";

import { RowStatus2 } from "../../../components/contentoh/styles";
import AddIconEdit from "../../../assets/IconComponents/IconEdit.svg";
import AddIconCancel from "../../../assets/IconComponents/IconCancel.svg";
import Cart from "../../../assets/IconComponents/carrito.svg";
import Loading from "../../../components/general/loading/index";
import ProgressBar from "../../general/progressBar/index";
import GenericModal from "../../general/GenericModal";
import RequestedProduct from "../../main/requestedProduct/RequestedProduct";
import CarrouselImg from "../FinalRevisionComponentes/CarrouselImg.js";
import ReactImageFallback from "react-image-fallback";
import defaultUpdate from "../../../assets/defaultImages/defaultUpdate.png";
import spinner from "../../../assets/defaultImages/Spinner.gif";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";

import { getProductDataTypes, setProductPrices } from "../get_product_prices";
import { setFinancedRetailers, setRegularRetailers } from "./utils";
import {
  getComments,
  postComments,
  getPercentage,
  getCart,
  addToCart,
} from "../../_utils/data";
import SliderToolTip from "../../main/SliderToolTip.js";

export default function ProductDetail(props) {
  const [isUserRetailer, setIsUserRetailer] = useState(false);
  const [productContent, setProductContent] = useState(null);
  const [datasheetPrice, setDatasheetPrice] = useState(0);
  const [showAssign, setShowAssign] = useState(false);
  const productDetail = props.productSelected;
  const [FT, setFT] = useState(0);
  const [qtyImages, setQtyImages] = useState(0);
  const [descriptions, setDescriptions] = useState(0);
  const version = productDetail?.version;
  sessionStorage.setItem("version", productDetail?.version);
  const [inCart, setInCart] = useState(false);
  const [imagesInputs, setImagesInputs] = useState([]);
  const [images, setImages] = useState([]);
  const [inputsGeneral, setInputsGeneral] = useState([]);
  const [activeImg, setActiveImg] = useState(null);
  const [dataImages, setDataImages] = useState([]);
  const [lastComment, setLastComment] = useState(null);
  const [hasOrder, setHasOrder] = useState(null);
  const [comment, setComment] = useState("");
  const [redirect, setRedirect] = useState(undefined);
  const [leftPopUp, setLeftPopUp] = useCloseModal("leftPopUp");
  const [rightPopUp, setRightPopUp] = useCloseModal("rightPopUp");
  const [percentages, setPercentages] = useState(
    new Array(productDetail?.retailersAvailable?.length || 0).fill({
      percentage: 0,
    })
  );
  const [retailersList, setRetailersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const reviewStateProductSelected = JSON.parse(
    sessionStorage.getItem("productSelected")
  );

  const redirectEdit = () => {
    const membership = JSON.parse(sessionStorage.getItem("user")).membership;
    const canEdit = membership.planID !== 1;
    try {
      if (!canEdit) {
        setShowMessage(true);
        return true;
      }

      sessionStorage.setItem(
        "productEdit",
        JSON.stringify({
          idCategory: productDetail.id_category,
          ArticleId: productDetail.id_article,
          //pass as array as products/get_product_prices function works with multiple products
          product: props.origin !== undefined ? productDetail : [productDetail],
        })
      );
      sessionStorage.setItem(
        "asignations",
        JSON.stringify(productDetail.asignations ?? [])
      );
      setRedirect(`/products/${productDetail.id_article}/datasheet`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (productContent) {
      setFT(productContent[0].dataSheet?.sharedInputs?.length);
      setQtyImages(productContent[0].images?.missingData);
      setDescriptions(
        productContent[0].descriptions?.filter((e) => e.charged).length
      );
    }
  }, [productContent]);

  useEffect(() => {
    if (FT > 0 && FT <= 15) {
      setDatasheetPrice(96);
    } else if (FT > 15 && FT <= 45) {
      setDatasheetPrice(213);
    } else if (FT > 45) {
      setDatasheetPrice(320);
    }
    const data = [
      {
        ...productDetail,
        retailersAvailable: productDetail.retailersAvailable.map(
          ({ id }) => id
        ),
      },
    ];
    getPercentage({ data }).then(([response]) => {
      const [retailers] = Object.values(response);
      delete retailers.percentageGeneral;
      delete retailers.percentageRequired;
      setPercentages({ retailers });
      // productDetail.percentageRequired = percentages.percentageRequired;
    });
  }, [FT, productDetail]);

  const getGeneralInputs = (response) => {
    const dataDatasheet = Object.values(JSON.parse(response.data.body).data);
    const inputsGeneral = dataDatasheet?.pop();
    dataDatasheet?.forEach((e) => {
      let itemInputs = [];
      if (JSON.parse(response.data.body).data[e.retailer.id].data) {
        Object.values(
          JSON.parse(response.data.body).data[e.retailer.id].data
        ).forEach((item) => (itemInputs = itemInputs.concat(item.inputs)));
      }
    });
    setInputsGeneral(inputsGeneral);
  };

  const getGeneralImages = (response) => {
    const parseData = JSON.parse(response.data.body).data;
    const imagesInputs = parseData.inputs;
    setImagesInputs(imagesInputs);
    const values = parseData.values;
    const images = values?.map((e) => {
      e.name = imagesInputs.find((item) => item.id === e.image_id);
      return getImage(e, 236, 236);
    });
    setActiveImg(images?.length > 0 ? images[0] : []);
    setImages(images);
  };

  const getData = async () => {
    let responseArray = [];
    if (productDetail) {
      const category =
        productDetail.id_category || productDetail?.article?.id_category;
      const retailers = isUserRetailer && [
        productDetail.retailersAvailable[0].id,
      ];
      responseArray.push(
        axios.post(
          `${process.env.REACT_APP_ARTICLE_DATA_DATASHEET_ENDPOINT}?idCategory=${category}&articleId=${productDetail.id_article}&version=${version}`,
          {
            retailers,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        ),
        axios.post(
          `${process.env.REACT_APP_ARTICLE_DATA_IMAGES_ENDPOINT}?articleId=${productDetail.id_article}&version=${version}`,
          {
            category,
            retailers,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        )
      );

      !props.featuresOfOrders &&
        responseArray.push(
          getProductDataTypes([
            JSON.parse(sessionStorage.getItem("productSelected")),
          ])
        );

      const inputsResponse = await Promise.all(responseArray);

      getGeneralInputs(inputsResponse[0]);
      getGeneralImages(inputsResponse[1]);

      // get comments
      updateComments();

      // get product content

      !props.featuresOfOrders &&
        setProductContent(
          setProductPrices(inputsResponse[2], [
            JSON.parse(sessionStorage.getItem("productSelected")),
          ])
        );
    }
    setLoading(false);
  };

  const getRetailers = async () => {
    try {
      const infoAccount = JSON.parse(sessionStorage.getItem("company"));
      const category =
        productDetail?.categoryName ?? productDetail?.article.category;

      let defaultRetailersForAccount;
      if (infoAccount.financedRetailers?.length > 0 && isUserRetailer) {
        defaultRetailersForAccount =
          infoAccount.financedRetailers ?? infoAccount.retailers;
      }

      const currentRetailers =
        productDetail?.retailersAvailable ?? productDetail?.retailers;
      if (defaultRetailersForAccount) {
        setFinancedRetailers(
          defaultRetailersForAccount,
          setRetailersList,
          currentRetailers
        );
      } else {
        setRegularRetailers(currentRetailers, category, setRetailersList);
      }
    } catch (err) {
      console.log(err, "unable to set current retailers for product");
      setRetailersList([]);
    }
  };

  const articleInCart = () => {
    const articlesInCart = JSON.parse(sessionStorage.getItem("inCart"));
    if (!articlesInCart) {
      setInCart(false);
      return;
    }
    setInCart(articlesInCart.includes(`${productDetail?.id_article}`));
  };

  useEffect(() => {
    if (!sessionStorage.getItem("inCart")) {
      getCart({ action: "general" }).then((r) => {
        articleInCart();
      });
    } else articleInCart();
    const company = JSON.parse(sessionStorage.getItem("company"));
    setIsUserRetailer(company?.financedRetailers?.length > 0);
    getData();
    getRetailers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateComments = () => {
    getComments({
      articleId: productDetail.id_article,
    }).then((commentResponse) => {
      commentResponse.order !== null && setHasOrder(commentResponse.order);
      const commentData = commentResponse.data;
      if (commentData?.length === 1) {
        if (
          JSON.parse(sessionStorage.getItem("user"))?.id_user !==
          commentData[0].user_id
        ) {
          commentData[0].src = getProfilePicture(
            commentData[0].user_id,
            40,
            40
          );
        } else {
          commentData[0].src = JSON.parse(sessionStorage.getItem("user"))?.src;
        }
        setLastComment(commentData[0]);
      }
    });
  };

  const handleUpdateCadena = async (retailers) => {
    const { add: toAdd, delete: toDelete } = retailers;
    const config = {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    };
    const promises = [];
    if (toAdd.length > 0) {
      const data = {
        idArticle: productDetail.id_article,
        isEditView: true,
        newRetailers: toAdd,
        version,
      };
      promises.push(
        axios.post(
          process.env.REACT_APP_ARTICLE_RETAILER_ENDPOINT,
          data,
          config
        )
      );
    }
    if (toDelete.length > 0) {
      toDelete.forEach((retailerId) => {
        const dataDelete = {
          idArticle: productDetail.id_article,
          idRetailer: retailerId,
          versionId: version,
        };
        promises.push(
          axios({
            method: "delete",
            url: process.env.REACT_APP_ARTICLE_RETAILER_ENDPOINT,
            data: dataDelete,
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          })
        );
      });
    }
    await Promise.all(promises);
    if (props.getProductsList) props.getProductsList();
    document.getElementById("close-button").click();
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { origin: props.origin },
        }}
      />
    );
  }
  const getNewPercentage = (id_article) => {
    const retailers =
      productDetail?.retailersAvailable || productDetail?.retailers;
    if (Object.keys(percentages ?? {})?.length > 0) {

      retailers?.forEach((retailer, index) => {
        retailer["percentage"] = Number(
          percentages?.retailers[retailer.id]?.percentageRequired
        );
      });
    }
  };

  const openPopUpEdit = (side) => {
    if (side === "right") {
      setRightPopUp(true);
      setLeftPopUp(false);
    } else {
      setLeftPopUp(true);
      setRightPopUp(false);
    }
  };

  const closeModal = () => {
    setImagesInputs([]);
    setImages([]);
    setActiveImg(null);
    setInputsGeneral([]);
  };

  return (
    <ContainerPrincipal
      ref={props.modalRef}
      className={`${props.className}${
        props.featuresOfOrders ? " removeForFixed" : ""
      }`}
      id="product-detail"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {showMessage && (
            <GenericModal
              close={() => setShowMessage(false)}
              message="Tu plan actual no te permite utilizar esta función"
              detail="¿Quieres actualizarlo?"
              button1={{
                name: "Cancelar",
                action: () => setShowMessage(false),
              }}
              button2={{
                name: "Actualizar",
                action: () => {
                  setShowMessage(false);
                  setRedirect("upgradeplan");
                },
              }}
            />
          )}

          {!props.featuresOfOrders && (
            <>
              <Container
                className="product-detail-container"
                width="100%"
                justifycontent="space-between"
              >
                <HeaderTitle>{productDetail?.name}</HeaderTitle>
                <Container width="90px" justifycontent="space-between">
                  {props.section !== "onboarding" &&
                    (reviewStateProductSelected.reviewState === undefined ||
                      reviewStateProductSelected.reviewState ===
                        "rejected_to_provider") && (
                      <img
                        className="edit-button"
                        onClick={() => {
                          redirectEdit();
                        }}
                        src={AddIconEdit}
                        alt="Editar"
                      />
                    )}
                  <button
                    id="close-button"
                    className="close-button"
                    onClick={() => closeModal()}
                  >
                    <img src={AddIconCancel} alt="Cerrar" />
                  </button>
                </Container>
              </Container>
              <Title>{productDetail?.description}</Title>
              <Title className="date-container">
                {moment(productDetail?.timestamp).format("DD/MM/YYYY")}
              </Title>
              <Container className="title-container">
                <Subtitle className="upc-container">UPC</Subtitle>
                <Title>{productDetail?.upc}</Title>
              </Container>
              <Container className="progressbar-container">
                {props.option === "Productos" ? (
                  <div className="containerEstatus">
                    <Subtitle className="title-progress">Progreso</Subtitle>
                    <ProgressBar
                      percentage={Number(
                        percentages?.percentageRequired
                      ).toFixed(2)}
                    />
                  </div>
                ) : (
                  <div className="containerEstatus">
                    <Subtitle className="title-progress">Estatus</Subtitle>
                    <RowStatus2 bg={getStatusColor("CA")}>
                      {getShortStatus("CA")}
                    </RowStatus2>
                  </div>
                )}
                <TitleCoantinVersion
                  onClick={() => {
                    sessionStorage.setItem("versionModal", JSON.parse(true));
                    redirectEdit();
                  }}
                >
                  <TitleVersion>Versión</TitleVersion>

                  <TitleVersion>{productDetail?.version}</TitleVersion>
                </TitleCoantinVersion>
              </Container>
              <Container
                className="category-container"
                justifycontent="flex-start"
                width="100%"
              >
                <ContainerLeft>
                  <TitleCoantin
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      openPopUpEdit("left");
                    }}
                  >
                    <TitleContainCat>Categoría</TitleContainCat>
                    <SubtitleContainCat>
                      {productDetail?.categoryName.replaceAll("|", " / ")}
                    </SubtitleContainCat>
                  </TitleCoantin>
                </ContainerLeft>
                <TitleCoantin
                  onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    openPopUpEdit("right");
                  }}
                  className="container-right"
                >
                  {productDetail?.retailersAvailable && (
                    <>
                      <TitleContainCat>Cadenas</TitleContainCat>
                      <SubtitleContainCat>
                        {productDetail?.retailersAvailable.map((e, i) =>
                          i < productDetail?.retailersAvailable.length - 1
                            ? `${e.name}, `
                            : `${e.name}`
                        )}
                      </SubtitleContainCat>
                    </>
                  )}
                </TitleCoantin>
                {leftPopUp && (
                  <PopUpEdit
                    id="leftPopUp"
                    className="category-container"
                    categories={productDetail?.categoryName.replaceAll(
                      "|",
                      " / "
                    )}
                  />
                )}
                {rightPopUp && (
                  <PopUpEdit
                    id="rightPopUp"
                    className="chk-inputs"
                    retailers={retailersList}
                    retailersAvailable={productDetail?.retailersAvailable}
                    handleUpdateCadena={handleUpdateCadena}
                  />
                )}
              </Container>
            </>
          )}

          <Container className="image-container">
            <Container className="image-container-2" flexdirection="column">
              <TitleImages>Imágenes</TitleImages>
              <ReactImageFallback
                src={activeImg?.src}
                fallbackImage={defaultUpdate}
                initialImage={spinner}
                className="containerImg"
              />
              <div className="carrousel">
                <CarrouselImg
                  images={images}
                  seleccionado={setActiveImg}
                  setDataImages={setDataImages}
                  namesList={imagesInputs}
                  hideTopBar
                ></CarrouselImg>
              </div>
            </Container>
            <Container className="progress-percent">
              <TitleImages className="retailers-head">
                <span>Porcentaje de avance</span>
                {props.option === "Productos" ? (
                  <Container className="avatar-container pa">
                    <TitleImages className="assignation-title">
                      Asig.
                    </TitleImages>
                    <AvatarContainer>
                      <div
                        className="avatars"
                        onClick={() => setShowAssign(!showAssign)}
                      ></div>
                      <AssignTo
                        showAssign={showAssign}
                        setShowAssign={setShowAssign}
                      />
                    </AvatarContainer>
                  </Container>
                ) : (
                  <Container className="avatar-container">
                    <TitleImages className="assignation-title">
                      Validado
                    </TitleImages>
                    <AvatarContainer>
                      {/* <Avatar src={ValIcon} /> */}
                    </AvatarContainer>
                  </Container>
                )}
              </TitleImages>
              {productDetail?.retailersAvailable.map((retailer, i) => (
                <Progressdiv color="#ED9A4D" key={i}>
                  <span>{retailer.name}</span>
                  <span className="percent">
                    {getNewPercentage(productDetail.id_article)}
                    {retailer?.percentage
                      ? retailer.percentage.toFixed(1)
                      : "0"}
                    %
                  </span>
                </Progressdiv>
              ))}
            </Container>
          </Container>
          <Container padding="10px 0" justifycontent="space-between">
            <Tables>
              <TablesHead>
                <TablesHeadRow>
                  <TablesRowH align="left" scope="col">
                    Nombre
                  </TablesRowH>
                  <TablesRowH align="right" scope="col">
                    Tamaño
                  </TablesRowH>
                </TablesHeadRow>
              </TablesHead>
              <tbody>
                <div>
                  {dataImages.length > 0 &&
                    dataImages.map((e, i) => (
                      <TablesHeadRow key={i}>
                        <TablesRowD align="left">{e.name}</TablesRowD>
                        <TablesRowD align="right">{e.dimension}</TablesRowD>
                      </TablesHeadRow>
                    ))}
                </div>
              </tbody>
            </Tables>
            <Tables className="table-group">
              <TablesHead>
                <TablesHeadRow>
                  <TablesRowH align="left" scope="col">
                    Información básica del producto
                  </TablesRowH>
                  <TablesRowH scope="col"></TablesRowH>
                </TablesHeadRow>
              </TablesHead>
              <tbody>
                <div>
                  {inputsGeneral &&
                    Object.values(inputsGeneral).length > 0 &&
                    Object.values(inputsGeneral).map((e, i) => (
                      <TablesHeadRow key={i}>
                        <TablesRowD align="left">{e.name}</TablesRowD>
                        <TablesRowD align="right">
                          {e.value ? e.value : "---"}
                        </TablesRowD>
                      </TablesHeadRow>
                    ))}
                </div>
              </tbody>
            </Tables>
          </Container>
          {lastComment !== null && (
            <Container className="comments-container">
              <TitleContainCat>Comentarios</TitleContainCat>
              <Container className="comments-avatar">
                <ContainerLeft>
                  <ReactImageFallback
                    src={lastComment.src}
                    fallbackImage={defaultProfile}
                    initialImage={spinner}
                    className="avatar-comment"
                  />
                </ContainerLeft>

                <TitleComents>{lastComment.message}</TitleComents>
              </Container>
            </Container>
          )}

          {!props.featuresOfOrders && (
            <>
              <Container className="footer">
                {props.option === "Productos" ? (
                  <Container className="title-footer">
                    <TitleFooter>Contenido restante</TitleFooter>
                    <Title className="footer-title">
                      Para este producto requieres completar los siguientes
                      activos, puedes agregarlos a tu carrito y nos haremos
                      cargo de entregar tus productos de forma correcta.
                    </Title>
                  </Container>
                ) : (
                  <Container className="title-footer">
                    <TitleFooter>
                      Contenido <br /> Total
                    </TitleFooter>
                    <NumberProduct>{`$${
                      datasheetPrice + descriptions * 278 + qtyImages * 99
                    }`}</NumberProduct>
                  </Container>
                )}
                <Container className="summary-container">
                  <Container className="resume-product">
                    <Container className="total-summary">
                      <Title className="title-summary">Productos</Title>
                      <NumberProduct>1</NumberProduct>
                    </Container>

                    <Container className="total-summary">
                      <Title className="title-summary">Ficha técnica</Title>
                      <NumberProduct>{FT}</NumberProduct>
                    </Container>

                    <Container className="total-summary">
                      <Title className="title-summary">Descripciones</Title>
                      <NumberProduct>{descriptions}</NumberProduct>
                    </Container>
                    <Container className="total-summary">
                      <Title className="title-summary">Imágenes</Title>
                      <NumberProduct>{qtyImages || "-"}</NumberProduct>
                    </Container>
                  </Container>
                  {props.option === "Productos" && (
                    <Container className="button-container">
                      <NumberProduct className="total-price">
                        $
                        {(
                          datasheetPrice +
                          descriptions * 278 +
                          qtyImages * 99
                        ).toFixed(0)}
                      </NumberProduct>
                      <SliderToolTip />
                      <Button
                        onClick={async () => {
                          if (!inCart) {
                            const user = JSON.parse(
                              sessionStorage.getItem("user")
                            );
                            const retailersIds =
                              productDetail.retailersAvailable.map((e) => e.id);
                            const data = [
                              {
                                articleId: productDetail.id_article,
                                version: productDetail.version,
                                userId: user.id_user,
                                discount: 0,
                                subtotal: 0,
                                companyId: user.id_company,
                                datasheet: JSON.stringify(retailersIds),
                                description: JSON.stringify(retailersIds),
                                image: JSON.stringify(retailersIds),
                                attributeTranslations: false,
                                descriptionTranslations: false,
                                build: "[]",
                                manual: "[]",
                                userCreated: user.id_user,
                              },
                            ];
                            const res = await addToCart({ data });
                            if (res.data.statusCode === 200) {
                              setInCart(true);
                              props.setAddedToCart(true);
                              props.setAddedPopUp(
                                <RequestedProduct
                                  productDetail={productDetail}
                                  productRetailerData={productContent}
                                />
                              );
                            } else {
                              addToCart(productContent, [
                                productDetail.id_article,
                              ]);
                              props.setAddedToCart(true);
                              props.setAddedPopUp(
                                <RequestedProduct
                                  productDetail={productDetail}
                                  productRetailerData={productContent.filter(
                                    (item) =>
                                      item.product.id_article ===
                                      productDetail.id_article
                                  )}
                                />
                              );
                              setTimeout(function () {
                                props.setAddedToCart(false);
                              }, 8000);
                            }
                          }
                        }}
                      >
                        {inCart ? (
                          <p>¡Árticulo ya en el carrito!</p>
                        ) : (
                          <>
                            <p>Solicitar a Content-oh!</p>
                            <img src={Cart} alt="shopping-cart" />
                          </>
                        )}
                      </Button>
                    </Container>
                  )}
                </Container>
              </Container>
              <NewComment>
                <form className="text-comment">
                  <ContainerLeft>
                    <ReactImageFallback
                      src={JSON.parse(sessionStorage.getItem("user")).src}
                      fallbackImage={defaultProfile}
                      initialImage={spinner}
                      className="avatar-comment"
                    />
                  </ContainerLeft>
                  <div className="text-button-container">
                    <div className="textarea-container">
                      <textarea
                        name="comment"
                        id="comment"
                        placeholder="Agregar comentario"
                        disabled={hasOrder === null}
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                      />
                    </div>
                    <div className="button-container">
                      <button
                        type="button"
                        disabled={hasOrder === null}
                        onClick={() => {
                          postComments({
                            message: comment,
                            articleId: productDetail.id_article,
                            orderId: hasOrder,
                          }).then((res) => {
                            setComment("");
                            updateComments();
                          });
                        }}
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
                <div className="button-validate">
                  <button
                    type="button"
                    onClick={() => {
                      redirectEdit();
                    }}
                  >
                    Validar
                  </button>
                </div>
              </NewComment>
            </>
          )}
        </>
      )}
    </ContainerPrincipal>
  );
}
