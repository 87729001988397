import styled from "styled-components";

export const BlurContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 10;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #281f33;
    opacity: 0.6;
    backdrop-filter: blur(4px);
  }
`;

export const ModalContainer = styled.div`
  width: 430px;
  height: 450px;
  background-color: #281f33;
  border-radius: 15px;
  z-index: 1;

  &.exit-modal {
    height: auto;
    padding: 30px;
    position: relative;

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: transparent;
      border: none;

      img {
        width: 14px;
        height: 14px;
      }
    }
    .buttons-container {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;

        &.btn-exit {
          background-color: transparent;
          border: 1px solid #f0eef2;
          border-radius: 30px;
          color: #f0eef2;
          padding: 7px 60px;
        }

        &.btn-return {
          background-color: #e33aa9;
          border: 1px solid #fff;
          border-radius: 30px;
          color: #fff;
          padding: 7px 45px;
        }

        & + button {
          margin-left: 45px;
        }
      }
    }
  }

  .close-button-container {
    display: flex;
    justify-content: right;
    width: 100%;

    button {
      border: none;
      background-color: transparent;
      padding-top: 22px;
      padding-right: 22px;
      cursor: pointer;
    }

    & + * {
      margin-top: 11px;
    }
  }

  .succes-icon {
    display: flex;
    justify-content: center;

    & + * {
      margin-top: 32px;
    }
  }

  .title-container {
    font-family: Raleway;
    font-style: normal;
    line-height: 28px;
    text-align: center;
    color: #f6f6f6;
    h2 {
      font-size: 24px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      font-weight: 400;
    }

    & + * {
      margin-top: 22px;
    }
  }

  .select-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .select {
      display: flex;
      justify-content: space-between;
      border: 1px solid #d4d1d7;
      border-radius: 7px;
      padding: 2px 5px;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #d4d1d7;
      width: 315px;

      & + * {
        margin-top: 8px;
      }
    }

    & + * {
      margin-top: 41px;
    }
  }

  .save-button-container {
    display: flex;
    justify-content: center;

    button {
      background: #e33aa9;
      border: none;
      border-radius: 40px;
      color: #ffffff;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      padding: 8px 45px;
      cursor: pointer;
    }
  }
`;
