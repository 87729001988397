import { memo } from "react";
import { PanelLayout, ButtonV2 } from "contentoh-components-library";
import { Header, ButtonsContainer, Content, Container } from "./styles.js";
import FichaTecnica from "../../../assets/IconCheckout/Ficha técnica.svg";
import Descripcion from "../../../assets/IconCheckout/Descripción.svg";
import Imagenes from "../../../assets/IconCheckout/Imágenes.svg";

const dataService = {
  dataset: {
    title: "Ficha técnica",
    name: "datasheet",
    detail:
      "La ficha técnica es la información más importante del producto, relevante para cumplir muchas normativas y obligatoria en la mayoría de las cadenas.",
    image: FichaTecnica,
  },
  article: {
    title: "Descripción",
    name: "description",
    detail:
      "Enamoramos a tu cliente con la descripción del producto, explicamos como es, como funciona y con las fotos, ya no necesitas imaginar el producto.",
    image: Descripcion,
  },
  image: {
    title: "Imágenes",
    name: "image",
    detail:
      "Si hay algo más importante que ficha técnica, son las imágenes. No puede existir el e-commerce sin las imágenes de tus productos.",
    image: Imagenes,
  },
};

const ServicePanel = (props) => {
  const Footer = () => (
    <ButtonsContainer>
      <ButtonV2
        id="continue"
        label="Agregar"
        type="pink"
        onClick={(evt) => {
          evt.preventDefault();
          props.onChangeServiceAllProducts(
            dataService[props.service].name,
            true
          );
        }}
      />
      <ButtonV2
        id="continue"
        label="Quitar"
        onClick={(evt) => {
          evt.preventDefault();
          props.onChangeServiceAllProducts(
            dataService[props.service].name,
            false
          );
        }}
      />
      <span>Esta acción se aplicará para todos los productos</span>
    </ButtonsContainer>
  );

  return (
    <Container>
      <PanelLayout
        header={
          <Header left="75%" right="25%">
            <span className={`material-icons-outlined`}>{props.service}</span>
            <p>{dataService[props.service].title}</p>
          </Header>
        }
        className="card__grid"
        footer={<Footer />}
      >
        <Content>
          <p>{dataService[props.service].detail}</p>
          <img
            src={dataService[props.service].image}
            alt={`Imagen de muestra de ${dataService[props.service].title}`}
          />
        </Content>
      </PanelLayout>
    </Container>
  );
};

export default memo(ServicePanel);
