import { MetricsBar } from "./MetricsBar/index.js";
import { useState, useEffect, useCallback } from "react";
import ProductDetail from "../../productDetail/index.js";
import { FilterInput } from "../../../filterInput/index.js";
import ContentohExportModal from "../../contentohExportModal/index";
import { isAccountFinanced } from "../../../_utils/account_validations.js";
import {
  getProduct,
  getRetailers,
  addToCart,
  getPercentage,
} from "../../../_utils/data.js";
import { Redirect } from "react-router-dom";
import { saveAs } from "file-saver";
import axios from "axios";
import { setCanonicFilter } from "./utils/filterFunctions.js";
import { GeneralProductsContainer } from "./styles";
import GenericModal from "../../../general/GenericModal/index.js";
import { GridProducts } from "contentoh-components-library";
import {
  getProductsToTable,
  chkOnChange,
  chkChecked,
  checkAll,
  chkCheckedAll,
  renderColumns,
} from "./utils/ProductsGridUtils";

export const GeneralProducts = (props) => {
  const [sidebool, setSidebool] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [productSelected, setProductSelected] = useState(null);
  const [addedToCart, setAddedToCart] = useState(false);
  const [addedPopUp, setAddedPopUp] = useState(null);
  const [modalClass, setModalClass] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [filtersArray, setFiltersArray] = useState({});
  const [filterArray, setFilterArray] = useState([]);
  const [redirectMultipleEdition, setRedirectMultipleEdition] = useState(false);
  const [loadingExportables, setLoadingExportables] = useState(false);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [option] = useState(props.option);
  const [indexProducts, setIndexProducts] = useState(
    -parseInt(process.env.REACT_APP_INCREMENT_LIMIT, 10)
  );
  const [showContentohExportModal, setShowContentohExportModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [filtersObject, setFiltersObject] = useState({});
  const [firstExecution, setFirstExecution] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [gridProducts, setGridProducts] = useState([]);
  const [images, setImages] = useState({});

  const closeModal = (e) => {
    if (
      (!e.target.closest("#product-detail") && sidebool) ||
      e.target.closest(".close-button")
    ) {
      setModalClass("");
      document.removeEventListener("click", closeModal, false);
      setTimeout(() => setSidebool(false), 500);
    }
  };

  const filterInputFunct = (filters, filtersObject, arrayFiltered) => {
    let array = [];
    filters?.forEach((filter) => {
      if (filtersObject[filter].filter !== "retailers") {
        arrayFiltered.forEach((article) => {
          let filtro = "";
          if (filtersObject[filter].filter === "categories") {
            filtro = "id_category";
            if (
              filtersObject[filter].values?.findIndex((v) => {
                return v === article[filtro];
              }) > -1
            ) {
              array.push(article);
            }
          } else {
            filtro = filtersObject[filter].filter;
            if (
              filtersObject[filter].values
                ?.map((e) => e)
                .findIndex((v) => v === article[filtro]) > -1
            ) {
              array.push(article);
            }
          }
        });
      } else {
        arrayFiltered.forEach((article) => {
          let isValid = false;
          filtersObject[filter].values.forEach((r) => {
            if (article.retailersAvailable.findIndex((f) => f.id === r) > -1) {
              isValid = true;
            }
          });
          if (isValid) {
            array.push(article);
          }
        });
      }
      arrayFiltered = array.slice();
      array = [];
    });
    return arrayFiltered;
  };

  const editProducts = () => {
    if (selectedArticles.length > 0) {
      setRedirectMultipleEdition(true);
      let orderedArray = selectedArticles.sort(
        (a, b) => a.id_article - b.id_article
      );
      sessionStorage.setItem(
        "multipleEditionList",
        JSON.stringify(orderedArray)
      );
    }
  };

  const getExportables = async () => {
    setLoadingExportables(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_EXPORTABLES}`, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      saveAs(JSON.parse(response.data.body).url);
      setLoadingExportables(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (sidebool) {
      setTimeout(() => {
        setModalClass("modal-active");
        document.addEventListener("click", closeModal, false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebool]);

  const getProductsList = useCallback(
    async (loadMore = false) => {
      setLoadingLoadMore(true);
      let indexFront = loadMore
        ? indexProducts + parseInt(process.env.REACT_APP_INCREMENT_LIMIT, 10)
        : 0;

      const filters = Object.keys(filtersObject);
      let params = {};
      if (filters.length > 0) {
        const { filter, values } = filtersObject[filters[0]];
        params = setCanonicFilter(filter, values);
        filters.shift();
      } else if (!loadMore && indexFront > 0) {
        indexFront = 0;
      }
      const productsResponse = await getProduct(params, indexFront);
      const parseData = JSON.parse(productsResponse.data.body || "{}");
      const imagesObject = parseData.images;
      setImages(imagesObject);
      setTotalProducts(parseData.count);
      if (firstExecution) {
        const financedRetailers = JSON.parse(
          sessionStorage.getItem("company")
        )?.financedRetailers;
        let retailersList;
        let retailerArray = [];
        if (financedRetailers?.length > 0) {
          retailerArray = financedRetailers?.map((retailer) => ({
            name: retailer.name,
            value: retailer.id,
          }));
        } else {
          const retailers = await getRetailers();
          retailersList = JSON.parse(retailers.data.body).data;
          Object.keys(retailersList)?.forEach((key) => {
            retailerArray.push({
              name: key,
              value: retailersList[key]?.id,
            });
          });
        }
        const categoryArray = [];
        const arrayAsignations = [];
        retailerArray.sort((a, b) => (a.name > b.name ? 1 : -1));
        parseData.data?.forEach((item) => {
          if (
            categoryArray.findIndex((e) => e.value === item.id_category) === -1
          ) {
            categoryArray.push({
              name: item.categoryName?.split("|").pop() || item.id_article,
              value: item.id_category,
            });
          }
        });
        const fltsOptions = {
          Categoría: categoryArray,
          Cadenas: retailerArray,
          Asignado: arrayAsignations,
          Marca: parseData.trademarks,
        };
        const tempArray = [
          { name: "Categoría", active: false },
          { name: "Cadenas", active: false },
          { name: "Asignado", active: false },
          { name: "Marca", active: false },
        ];
        setFiltersArray(fltsOptions);
        setFilterArray(tempArray);
        setFirstExecution(false);
      }
      const arrayFiltered = filterInputFunct(
        filters,
        filtersObject,
        parseData.data
      );
      const newProducstList = loadMore
        ? [...productsList, ...arrayFiltered]
        : arrayFiltered;
      loadMore && setIndexProducts(indexFront);
      const data =
        newProducstList
          ?.filter((f) => f.percentageRequired === undefined)
          .map((item) => ({
            ...item,
            retailersAvailable: item.retailersAvailable.map(({ id }) => id),
          })) || [];
      if (data.length > 0) {
        const temp = newProducstList.slice();
        getPercentage({ data }).then((res) => {
          if (res?.length > 0) {
            const percentages = res[0];
            temp.forEach((product) => {
              const articleId = product.id_article;
              product.percentage = percentages[articleId]?.percentageRequired;
            });
            setProductsList(temp);
          }
        });
      } else setProductsList(newProducstList);
      setLoadingLoadMore(false);
    },
    [indexProducts, productsList, filtersObject, firstExecution]
  );

  useEffect(() => {
    getProductsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersObject]);

  const exportProducts = () => {
    if (selectedArticles.length > 0) {
      setShowContentohExportModal(true);
    }
  };

  const deleteArticles = async () => {
    const data = selectedArticles.map((e) => e.id_article);
    const listUpdate = productsList.filter((e) =>
      selectedArticles.find((f) => f.id_article !== e.id_article)
    );
    await axios.delete(process.env.REACT_APP_ARTICLE_ENDPOINT, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
      data,
    });
    setProductsList(listUpdate);
    setTotalProducts(totalProducts - selectedArticles.length);
    setSelectedArticles([]);
    setIndexProducts(-parseInt(process.env.REACT_APP_INCREMENT_LIMIT, 10));
    setShowDeleteModal(false);
  };

  const addCart = async () => {
    const articleToOrder = [];
    const user = JSON.parse(sessionStorage.getItem("user"));
    selectedArticles.forEach((e) => {
      const retailers = JSON.stringify(
        e.retailersAvailable.map((ret) => ret.id)
      );
      articleToOrder.push({
        articleId: e.id_article,
        version: e.version,
        userId: user.id_user,
        discount: 0,
        subtotal: 0,
        companyId: user.id_company,
        datasheet: retailers,
        description: retailers,
        image: retailers,
        attributeTranslations: false,
        descriptionTranslations: false,
        build: "[]",
        manual: "[]",
        userCreated: user.id_user,
      });
    });

    const resCart = await addToCart({ data: articleToOrder });
    if (resCart.data.statusCode === 200) {
      setRedirect("checkout");
    }
  };

  useEffect(() => {
    if (images && productsList.length) {
      const arrayTemp = getProductsToTable(productsList, images);
      setGridProducts(arrayTemp);
    }
  }, [productsList, images]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <GeneralProductsContainer>
      {addedToCart && addedPopUp}
      <MetricsBar />
      <FilterInput
        hideExportButton={isAccountFinanced()}
        total={totalProducts}
        charged={productsList?.length}
        filterArray={filterArray}
        className={"filter-input"}
        editProducts={editProducts}
        filtersArray={filtersArray}
        download={() => getExportables()}
        exportProducts={exportProducts}
        loadMoreProducts={() => {
          if (productsList.length !== totalProducts) getProductsList(true);
        }}
        filterInputFunct={setFiltersObject}
        loadingExportables={loadingExportables}
        loadingLoadMore={loadingLoadMore}
        deleteArticles={() => {
          if (selectedArticles.length > 0) {
            setShowDeleteModal(true);
          }
        }}
        addToCart={addCart}
        gridView={gridView}
        setGridView={setGridView}
      />
      {/* <TableSection
        loading={loading}
        sidebool={sidebool}
        setSidebool={setSidebool}
        productsList={productsList}
        setFilterArray={setFilterArray}
        getProductsList={getProductsList}
        setProductsList={setProductsList}
        selectedArticles={selectedArticles}
        setIndexProducts={setIndexProducts}
        setProductSelected={setProductSelected}
        setSelectedArticles={setSelectedArticles}
      /> */}
      <GridProducts
        products={gridProducts}
        gridView={gridView}
        selected={selectedArticles}
        columnsArray={renderColumns()}
        chkOnChange={(e, product) =>
          chkOnChange(e, product, selectedArticles, setSelectedArticles)
        }
        chkChecked={(product) => {
          return chkChecked(product, selectedArticles);
        }}
        checkAll={(e) => checkAll(e, setSelectedArticles, productsList)}
        chkCheckedAll={(e) => {
          return chkCheckedAll(selectedArticles, productsList);
        }}
        onGridClick={(e, product) => {
          sessionStorage.setItem("productSelected", JSON.stringify(product));
          setProductSelected(product);
          setSidebool(true);
        }}
      />
      {sidebool && productSelected && (
        <ProductDetail
          selectedArticles={selectedArticles}
          option={option}
          productSelected={productSelected}
          setAddedToCart={setAddedToCart}
          setAddedPopUp={setAddedPopUp}
          className={modalClass}
          setModalClass={setModalClass}
          setSidebool={setSidebool}
          getProductsList={getProductsList}
          // setLoading={setLoading}
          // getProducts={getProducts}
        />
      )}
      {showContentohExportModal && (
        <ContentohExportModal
          selectedArticlesIds={selectedArticles}
          productsList={productsList}
          products={selectedArticles}
          close={setShowContentohExportModal}
          checkboxCounter={selectedArticles.length}
        />
      )}
      {redirectMultipleEdition && (
        <Redirect
          to={{
            pathname: `/products/multipleEdition`,
          }}
        />
      )}
      {showDeleteModal && (
        <GenericModal
          message="¿Estás seguro que deseas eliminar estos artículos?"
          close={() => setShowDeleteModal(false)}
          button1={{
            name: "Cancelar",
            action: () => setShowDeleteModal(false),
          }}
          button2={{ name: "Eliminar", action: () => deleteArticles() }}
        />
      )}
    </GeneralProductsContainer>
  );
};
