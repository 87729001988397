import axios from "axios";

const objToQueryString = (obj) => {
  if (!Object.values(obj).length) return null;
  return Object.entries(obj)
    .map(
      ([key, value]) => `${key}=${encodeURIComponent(JSON.stringify(value))}`
    )
    .join("&");
};

export const getComments = async (params = {}) => {
  const func = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_COMMENTS_ENDPOINT}?articleId=${params.articleId}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(response.data.body);
  };

  return await func();
};

export const getRetailerProducts = async (params, setProductCount) => {
  try {
    const {
      canonicFilter,
      productsType,
      providerFilter,
      phaseFilter,
      groupFilter,
    } = params;
    let productsResponse;

    let providerFilterEncoded = encodeURIComponent(
      JSON.stringify(providerFilter)
    );
    let phaseFilterEncoded = encodeURIComponent(JSON.stringify(phaseFilter));
    let groupFilterEncoded = encodeURIComponent(JSON.stringify(groupFilter));
    if (canonicFilter) {
      switch (canonicFilter.filterName) {
        case "filterByPhase":
          phaseFilterEncoded = canonicFilter.filterValues;
          break;
        case "filterByProvider":
          providerFilterEncoded = canonicFilter.filterValues;
          break;
        case "filterByGroup":
          groupFilterEncoded = canonicFilter.filterValues;
          break;
        default:
          break;
      }
    }
    if (productsType === "onboarding" || productsType === "acquired" ) {
      productsResponse = await axios.get(
        `${
          process.env.REACT_APP_ARTICLE_RETAILER_ENDPOINT
        }?productsType=${productsType}&startIndex=${
          params.startIndex
        }&providerFilter=${providerFilterEncoded}&phaseFilter=${phaseFilterEncoded}&groupPhase=${groupFilterEncoded}   ${
          canonicFilter
            ? `&filterName=${canonicFilter.filterName}&filterValues=${canonicFilter.filterValues}`
            : ""
        }`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      if (!params.filterLists) {
        params.setFilterLists(JSON.parse(productsResponse.data.body));
      }
    } else {
      productsResponse = await axios.get(
        `${
          process.env.REACT_APP_ARTICLE_RETAILER_ENDPOINT
        }?productsType=${productsType}&startIndex=${params.startIndex}

      ${
        canonicFilter
          ? `&filterName=${canonicFilter.filterName}&filterValues=${canonicFilter.filterValues}`
          : ""
      }`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
    }

    const parsedResponse = JSON.parse(productsResponse.data.body);
    setProductCount(parsedResponse.count);
    const products = parsedResponse.data ?? [];
    const filterOptionsRadioshack = parsedResponse;

    return {
      err: false,
      productList: products,
      dataRadio: filterOptionsRadioshack,
    };
  } catch (err) {
    console.log(err, "Unable to retrieve products from database");
    return { err: true, productList: [] };
  }
};

export const postComments = async (params = {}) => {
  const func = async () => {
    if (params.message === "") {
      console.error("Mensaje vacío");
      return false;
    }
    await axios
      .post(
        `${process.env.REACT_APP_COMMENTS_ENDPOINT}?message=${params.message}&articleId=${params.articleId}&orderId=${params.orderId}`,
        {
          message: params.message,
          articleId: params.articleId,
          orderId: params.orderId,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      )
      .then((response) => {
        return true;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  return await func();
};

export const getPercentage = async (params = {}) => {
  const func = () => {
    const dataToSend = [];
    const query = params?.query ? objToQueryString(params?.query ?? {}) : "";
    try {
      params.data.forEach((product) => {
        dataToSend.push({
          id_article: product.id_article,
          id_category: product.id_category,
          version: product.version,
          id_retailer_array: product.retailersAvailable,
        });
      });
    } catch (error) {
      console.log(error);
    }
    return axios
      .post(
        `${process.env.REACT_APP_VALID_EXPORT_ENDPOINT}?${query}`,
        { data: dataToSend },
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      )
      .then((response) => [response.data])
      .catch((err) => console.log(err, "Errror"));
  };

  return await func();
};

export const getProduct = async (params = {}, indexFront) => {
  const func = async () => {
    const queryParams = encodeURIComponent(JSON.stringify(params));
    const articlesResponse = await axios.get(
      `${process.env.REACT_APP_NEW_ARTICLES_ENDPOINT}?listType=general&params=${queryParams}&indexFront=${indexFront}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return articlesResponse;
  };

  return await func();
};

export const getContentohTasks = async (params = {}) => {
  let query = "";
  if (params.filter) {
    query = `?filterIdOrder=${params.filter}`;
  }
  const func = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_TASKS_ENDPOINT}${query}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      const products = JSON.parse(response.data.body)?.data ?? [];
      const images = JSON.parse(response.data.body)?.images ?? {};
      return { products, images };
    } catch (err) {
      console.log(err, "unable to retrieve tasks from database");
      return { err: true, taskList: [] };
    }
  };
  return await func();
};
export const getOptionsIDs = async (params = {}) => {
  const func = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TASKS_ENDPOINT}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      return JSON.parse(response.data.body).filtersData;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  return await func();
};
export const addUserArticles = async (params = {}) => {
  const func = async () => {
    const data = {
      articleList: params.articleList,
      collaboratorId: params.id_user,
      isProveedor: "1",
    };
    return await axios({
      method: "post",
      url: process.env.REACT_APP_ASSIGNATIONS_ENDPOINT,
      data,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
  };

  return await func();
};

export const deleteUserArticles = async (params = {}) => {
  const func = async () => {
    const data = {
      articlelist: params.articleList,
      collaboratorId: params.collaboratorId,
    };
    try {
      await axios({
        method: "delete",
        url: process.env.REACT_APP_ASSIGNATIONS_ENDPOINT,
        data,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  return await func();
};

export const getCollaborators = async (params = {}) => {
  const func = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_USER_ENDPOINT}?isColaborators=1`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const collaborators = JSON.parse(response.data.body).data[2];
    sessionStorage.setItem("collaborators", JSON.stringify(collaborators));
    return collaborators;
  };

  return await func();
};

export const createArticles = async (params = {}) => {
  const func = async () => {
    return axios.post(process.env.REACT_APP_ARTICLE_ENDPOINT, params.data, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
  };

  return await func();
};

export const getRetailers = async (params = {}) => {
  const func = async () => {
    return axios.get(process.env.REACT_APP_RETAILER_ENDPOINT, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
  };

  return await func();
};

export const getCompanyCollaborators = async (params = {}) => {
  try {
    const func = async () => {
      const userColabs = await axios.get(
        `${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=true`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      return userColabs.data?.data ?? [];
    };

    return await func();
  } catch (err) {
    console.log(err, "unable to retrieve company colabs");
    return [];
  }
};

export const getUser = async (params = {}) => {
  const func = async () => {
    const query = params.query ? `?${params.query}` : "";
    return axios.get(`${process.env.REACT_APP_USER_ENDPOINT}${query}`, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
  };

  return await func();
};

export const getOrderedContentoh = async () => {
  const func = async () => {
    const response = await axios.get(process.env.REACT_APP_ORDERED_PRODUCTS, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    return JSON.parse(response.data.body).data;
  };

  return await func();
};

export const getContentohProducts = async () => {
  const response = await axios.get(process.env.REACT_APP_ORDERED_PRODUCTS, {
    headers: {
      Authorization: sessionStorage.getItem("jwt"),
    },
  });
  const products = JSON.parse(response.data.body).data;
  const images = JSON.parse(response.data.body).images;
  return { products, images };
};

export const getCart = async (params) => {
  const func = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CART}/${params?.action}/`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const parsed = JSON.parse(res.data.body)?.data;
    if (params?.action === "general") {
      const arr = Object.keys(parsed.articles);
      sessionStorage.setItem("inCart", JSON.stringify(arr));
    }
    return parsed;
  };

  return await func();
};

export const addToCart = async (params = {}) => {
  const func = async () => {
    const res = await axios.post(
      process.env.REACT_APP_CART,
      { data: params?.data },
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    if (res.data.statusCode === 200) {
      const arr = sessionStorage.getItem("inCart")
        ? JSON.parse(sessionStorage.getItem("inCart"))
        : [];
      params.data.forEach((e) => {
        arr.push(e.articleId);
      });
      sessionStorage.setItem("inCart", JSON.stringify(arr));
    }
    return res;
  };

  return await func();
};

export const updatePriority = async (params) => {
  const func = async () => {
    const res = await axios.put(
      process.env.REACT_APP_ARTICLES_PRIORITY_ENDPOINT,
      params,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return res;
  };
  return await func();
};

export const deleteToCart = async (params = {}) => {
  const func = async () => {
    const res = await axios.delete(process.env.REACT_APP_CART, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
      data: params?.data,
    });
    return res;
  };

  return await func();
};

export const updateToCart = async (params = {}) => {
  const func = async () => {
    const data = params?.data;
    const res = await axios.put(
      `${process.env.REACT_APP_CART}/${params.action}/`,
      data,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return res;
  };

  return await func();
};

export const createToCart = async (params = {}) => {
  const func = async () => {
    const data = params?.data;
    const res = await axios.post(process.env.REACT_APP_ORDER_ENDPOINT, data, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    return res;
  };

  return await func();
};

export const updateRetailerAssignations = async (params = {}) => {
  const func = async () => {
    const data = params?.data;
    const res = await axios.post(
      process.env.REACT_APP_RETAILER_ASSIGNATIONS,
      data,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return res;
  };

  return await func();
};

export const requestToProvider = async (params = {}) => {
  const func = async () => {
    const res = await axios.post(
      process.env.REACT_APP_RETAILER_REQUEST,
      params?.data,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return res;
  };

  return await func();
};

export const getRetailerFilters = async () => {
  try {
    const filterOptions = await axios.get(
      `${process.env.REACT_APP_RETAILER_FILTERS}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(filterOptions.data.body).data;
  } catch (err) {
    console.log(err, "Unable to retrieve retailer filters from database");
    return [];
  }
};

export const getOrders = async (setError) => {
  try {
    const ordersResponse = await axios.get(
      `${process.env.REACT_APP_ORDER_ENDPOINT}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return Object.values(JSON.parse(ordersResponse.data.body).data).reverse();
  } catch (err) {
    setError(true);
    return [];
  }
};

/**
 *
 * @param {JSON} params object which contains the params required for the backend to work correctly
 * @param {string} params.upc UPC to check for duplicates in database
 * @param {number} params.company if user is retailer the product's owner company id will be sent
 * @returns {boolean} returns whether the UPC was found in the database for the specific company or not
 */
export const checkDuplicateUPC = async (params = {}) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_UPC}?upc=${params.upc}${
        params.company ? `&company=${params.company}` : ""
      }`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(res.data.body).upcIsDuplicated ?? false;
  } catch (err) {
    console.log(
      err,
      "No se pudo verificar si ya existe otro UPC igual registrado para la compañia, recomendamos buscar el producto al terminar"
    );
    return false;
  }
};

export const getOrderDetail = async (params = {}) => {
  const func = async () => {
    const query = objToQueryString(params.query);
    const res = await axios.get(
      `${process.env.REACT_APP_ORDER_DETAIL}?${query}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(res.data.body);
  };

  return await func();
};

export const postAcquiredProducts = async (params = {}) => {
  const func = async () => {
    return await axios.post(
      process.env.REACT_APP_DROPSHIP_ACQUIRED_ENDPOINT,
      params?.data,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
  };

  return await func();
};

export const getAcquiredProducts = async (params = {}) => {
  const func = async () => {
    const queryStringParameters = objToQueryString(params.query);
    return await axios.get(
      `${process.env.REACT_APP_DROPSHIP_ACQUIRED_ENDPOINT}?${queryStringParameters}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
  };

  return await func();
};

export const updateData = async (params = {}) => {
  const func = async () => {
    const queryStringParameters = objToQueryString(params?.query ?? {});
    return await axios.put(
      `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?${queryStringParameters}`,
      params.body,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
  };

  return await func();
};

export const updateVisibility = async (params = {}) => {
  const func = async () => {
    return await axios.put(
      process.env.REACT_APP_DROPSHIP_VISIBILITY_ENDPOINT,
      params.body,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
  };

  return await func();
};

export const sendToAudit = async (params = {}) => {
  const func = async () => {
    const queryStringParameters = objToQueryString(params?.query ?? {});
    return await axios.post(
      `${process.env.REACT_APP_AUDIT_ENDPOINT}?${queryStringParameters}`,
      params.body,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
  };

  return await func();
};

export const postNotifyAdcquiredTHD = async () => {
  const func = async () => {
    return await axios.post(
      process.env.REACT_APP_NOTIFY_ADCQUIRED_THD_ENDPOINT,
      null,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
  };

  return await func();
};
