export const orderArray = (array) => {
  return array.sort((a, b) => {
    let fa = `${a.name}`.toLowerCase(),
      fb = `${b.name}`.toLowerCase();
    if (fa < fb) return -1;
    if (fa > fb) return 1;
    return 0;
  });
};

export const fixArray = (array) => {
  return array.map((article) => ({
    ...article,
    services: {
      datasheet_status: article.datasheet_status,
      description_status: article.description_status,
      images_status: article.images_status,
    },
    prio: {
      prio: article.prio,
      articleId: article.id_article,
    },
  }));
};

export const getKeyValue = (item, path) => {
  return path.split(".").reduce((previous, current) => previous[current], item);
};

/**
 * @param {Array<object>} optionsList - array which contains the items in the ones the unique values from an specified key will be extracted
 * @param {string} keyPath - string which specifies the path of a key within the items in the array (I.E. "item.article.upc")
 */
export const getFilterOptions = (optionsList, keyPath) => {
  try {
    // Remove items where key value doesn't exists
    optionsList = optionsList.filter((item) => getKeyValue(item, keyPath));

    let uniqueOptions = [
      ...new Set(optionsList.map((item) => getKeyValue(item, keyPath))),
    ];
    return uniqueOptions.map((option) => ({ name: option, value: option }));
  } catch (err) {
    console.log(err, `Unable to set filter options for ${keyPath}`);
    return [];
  }
};

export const rowKeyGenerator = (product) => {
  const priority = product.prio ?? "none";
  const asignee = product.asignations[0] ?? 0;
  const order = product.orderId ?? 0;
  const checked = product.checked ?? false;
  return `product-row-${product.article.id_article}-${product.article.upc}-${product.version}-${checked}-${asignee}-${priority}-${order}`;
};

export const getKeyAttr = (filter) => {
  switch (filter) {
    case "categories":
      return "article.category";
    case "trademark":
      return "brand";
    case "company":
      return "article.company_name";
    default:
      return filter;
  }
};

/**
 *
 * @param {string} name filter name returned by the filterInput component
 * @returns {string} name param required by the backend to build the proper query to get the products
 */
export const getFilterName = (name) => {
  const filterNames = {
    company: "filterByCompany",
    trademark: "filterByBrand",
    categories: "filterByCategory",
    orderId: "filterByOrder",
    status: "filterByEstatus",
    upc: "filterByUpc",
    assignee: "filterByAssignee",
    retailers: "filterByRetailers",
  };
  return filterNames[name];
};

export const statusOption = [
  { name: "Recibido", value: "R" },
  { name: "En Progreso", value: "CA" },
  { name: "Asignado", value: "AS" },
  { name: "Aprobado Auditor", value: "AA" },
  { name: "Aprobado Proveedor", value: "AP" },
  { name: "Rechazado Proveedor", value: "RP" },
  { name: "Aprobado Cadena", value: "ACA" },
  { name: "Rechazado Cadena", value: "RCA" },
  { name: "Exportado", value: "Ex" },
];

export const compareCanonicValues = (canonic, updatedFilter) => {
  return (
    JSON.parse(decodeURI(canonic.filterValues)).length !==
    updatedFilter.values.length
  );
};

export const formatSelectedProduct = (product, handleAll, productsList) => {
  if (handleAll)
    return productsList.map((prod) => ({
      ...prod,
      retailersAvailable: prod.retailers,
      categoryName: prod.article.category,
      upc: prod.article.upc,
      id_article: prod.article.id_article,
      name: prod.article.name,
      id_category: prod.article.id_category,
    }));

  product.retailersAvailable = product.retailers;
  product.categoryName = product.article.category;
  product.id_category = product.article.id_category;
  product.name = product.article.name;
  product.upc = product.article.upc;
  product.id_article = product.article.id_article;
  return product;
};

export const removeUncheckedProduct = (
  product,
  selectedProducts,
  handleAll
) => {
  if (handleAll) return [];
  return selectedProducts.filter(
    (selected) =>
      !(
        selected.id_article === product.article.id_article &&
        selected.version === product.version
      )
  );
};

export const removeUncheckedOrdered = (
  product,
  selectedProducts,
  handleAll
) => {
  if (handleAll) return [];
  return selectedProducts.filter(
    (selected) =>
      !(
        selected.id_article === product.article.id_article &&
        selected.orderId === product.orderId
      )
  );
};

export const handleProductChecks = (
  productsList,
  checked,
  product,
  handleAll
) => {
  if (handleAll)
    return productsList.map((article) => ({ ...article, checked: checked }));
  else
    return productsList.map((article) => ({
      ...article,
      checked:
        article.article.id_article === product.article.id_article
          ? (article.checked = checked)
          : article.checked,
    }));
};

export const handleOrderedChecks = (
  productsList,
  checked,
  product,
  handleAll
) => {
  if (handleAll)
    return productsList.map((article) => ({ ...article, checked: checked }));
  else
    return productsList.map((article) => ({
      ...article,
      checked:
        article.article.id_article === product.article.id_article &&
        article.orderId === product.orderId
          ? (article.checked = checked)
          : article.checked,
    }));
};
