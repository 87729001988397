import styled from "styled-components";

export const SideTable = styled.div`
  flex: 1;
  box-sizing: border-box;
  overflow-y: clip;
  overflow-x: auto;

  .scrollable-container {
    position: relative;
    min-width: 100%;

    .table-container {
      height: calc(100vh - 245px);
      min-width: 100%;
      overflow: auto;
    }
  }

  &:first-child {
    border-right: 1px solid #ededed;
  }

  * {
    box-sizing: border-box;
  }

  &:last-child {
    .scrollable-container {
      min-width: fit-content;
    }
  }

  & + * {
    border-left: 1px solid #ededed;
  }
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
`;

export const TableHeader = styled.th`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  vertical-align: middle;
  color: #817393;
  white-space: nowrap;
  padding: 0 10px;

  > div {
    width: fit-content;
    margin: auto;
  }

  & + * {
    border-left: 1px solid #c4c4c4;
  }
`;

export const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #fbfbfb;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }
`;

export const TableRow = styled.tr``;

export const TableCell = styled.td`
  padding: 3px 5px;
  vertical-align: middle;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "auto")};
  border: ${(props) => (props.required ? "1px solid #E33AA9" : "none")};
  height: 94px;
  max-height: 84px;
  flex: 1;
  max-width: 94px;
  position: relative;
  text-align: center;
  overflow: hidden;

  p,
  input {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
    overflow: hidden;
    white-space: nowrap;
    color: #817393;
    background-color: transparent;
    border: none;
  }

  select {
    background: #fafafa;
    outline: none;
    cursor: pointer;
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    line-height: 21px;
    border: none;
    border-right: 2px solid #e33aa9;
    height: 100%;
  }

  .quill {
    position: relative;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    overflow: auto;

    .ql-toolbar {
      background-color: #d4d1d7;
      position: absolute;
      z-index: 10;
      border: 1px solid #d4d1d7;
      width: 45px;
      height: 45px;
      border-radius: 4px;
      display: ${({ selection }) => (selection ? "block" : "none")};
      top: ${({ position }) => (position ? position.top : 0)}px;
      left: ${({ position }) => (position ? position.left : 0)}px;

      .ql-formats {
        .ql-active {
          svg {
            color: #e33aa9;
          }
        }
      }
    }
    .ql-container {
      &.ql-snow {
        border: none;
      }
      .ql-editor {
        padding: 0;
        border-radius: 6px;
        height: 100%;
        p {
          font-family: Avenir Next;
          white-space: break-spaces;
          strong {
          }
        }
      }
    }

    & + * {
      margin-top: 5px;
    }
  }

  input {
    width: 100%;
    height: 100%;
  }

  > div {
    width: fit-content;
    margin: auto;
  }

  .image-container {
    height: 94px;
    min-width: 94px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.version {
    p {
      min-width: 27px;
      background-color: #f0eef2;
      text-align: center;
      border-radius: 10px;
    }
  }
  & + * {
    border-left: 1px solid #ededed;
  }

  &.chk-container {
    width: 33px;
  }

  .input-container {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f0eef2;
    z-index: 10;

    input {
      width: 100%;
      background-color: #f0eef2;
      height: 32px;
      border: 2px solid #d4d1d7;

      &[type="file"] {
        display: none;
      }
    }

    label {
      display: block;
      border: none;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #817393;
      padding: 0;
      cursor: pointer;
      padding: 15px 0;
    }

    .save-button {
      background-color: #e33aa9;
      display: flex;
      justify-content: flex-end;

      button {
        background-color: #fbfbfb;
        border: 2px solid #e33aa9;
        width: 20px;
        height: 20px;
        padding: 0;
        cursor: pointer;
        min-height: initial;
      }
    }
  }

  .null-container {
    background-color: #d4d1d7;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    p {
      text-align: left;
      padding-left: 5px;
    }
  }

  .show-popup-button {
    display: none;
    width: 13px;

    button {
      width: 13px;
      padding: 0;
      margin: 0;
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
    }
  }

  &:hover {
    .show-popup-button {
      display: block;
    }
  }
`;

export const RetailerCell = styled.td`
  .cell-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34px;

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
      overflow: hidden;
      white-space: nowrap;
      color: #817393;
    }

    #popup-component {
      right: 0;
    }
  }

  & + * {
    border-left: 1px solid #ededed;
  }
`;

export const FixedHeader = styled.div`
  background-color: #ffffff;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  position: absolute;
  top: -1px;
  border-bottom: 1px solid #c4c4c4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  z-index: 1;
`;

export const FixedCell = styled.div`
  width: ${(props) => props.width};
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  vertical-align: middle;
  color: #817393;
  white-space: nowrap;
  padding: 0 10px;
`;

export const PopUpContainer = styled.div`
  position: ${(props) => props.position};
  top: 100%;
  right: 0;
  z-index: 1;
  border: ${(props) => (props.border ? "1px solid #d4d1d7" : "none")};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px;
  background-color: ${(props) =>
    props.backColor ? props.backColor : "#f0eef2"};

  .save-button {
    background-color: #e33aa9;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: #fbfbfb;
      border: 2px solid #e33aa9;
      width: 20px;
      height: 20px;
      padding: 0;
      cursor: pointer;
      min-height: initial;
    }
  }
`;

export const ImagesPopUp = styled.div`
  background-color: inherit;
  position: relative;
  cursor: pointer;

  .option-selected {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${(props) => (props.isActive ? "#E33AA9" : "#d4d1d7")};
    padding: 2px 6px;
    min-width: 310px;
    border-radius: 7px;

    h2 {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: ${(props) => (props.color ? props.color : "#503d66")};
    }

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      color: ${(props) => (props.color ? props.color : "#817393")};
    }

    h2,
    p {
      white-space: nowrap;
    }
  }

  ul {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    background-color: #f0eef2;
    width: 100%;
    border: 1px solid #d4d1d7;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 10px 20px;
    z-index: 1;
    cursor: default;

    li {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      color: #817393;
      text-align: right;
      cursor: pointer;

      & + * {
        margin-top: 10px;
      }
    }
  }

  & + * {
    margin-top: 8px;
  }
`;

export const EstatusCell = styled.span`
  font-family: Lato;
  font-size: 13px;
  color: white;
  background-color: ${(props) => props.color};
  width: 100%;
  height: 90%;
  box-sizing: border-box;
  padding: 3px;
  border-radius: 5px;
`;
