export const tableTemplate = [
  {
    header: "checkbox",
    centered: true,
    component: "checkbox",
  },
  {
    header: "Proveedor",
    minWidth: "95px",
    centered: true,
    path: "article.company_name",
  },
  {
    header: "UPC",
    minWidth: "90px",
    centered: true,
    path: "article.upc",
  },
  {
    header: "Nombre",
    minWidth: "70px",
    centered: true,
    path: "article.name",
    direction: "flex-start",
  },
  {
    header: "Categoría",
    minWidth: "110px",
    centered: true,
    direction: "flex-start",
    component: "category",
    path: "article.category",
  },
  {
    header: "Asig.",
    minWidth: "55px",
    centered: true,
    component: "assignment",
    path: "asignations",
    withoutOverflow: true,
    noClick: true,
  },
  {
    header: "Datos",
    minWidth: "55px",
    centered: true,
    component: "attribute",
    path: "missing.datasheet",
  },
  {
    header: "Descripciones",
    minWidth: "100px",
    centered: true,
    component: "description",
    path: "missing.descriptions",
  },
  {
    header: "Imágenes",
    minWidth: "80px",
    centered: true,
    component: "image",
    path: "missing.images",
  },
  {
    header: "%",
    minWidth: "80px",
    centered: true,
    component: "percentage",
    path: "percentage",
  },
];

export const tableStyle = {
  display: "flex",
  justifyContent: "center",
  fontFamily: "Avenir Next",
  fontWeight: 600,
  fontSize: "14px",
  color: "#503D66",
  margin: 0,
};

export const rowStyle = {
  overflow: "unset",
  textAlign: "center",
  width: "100%",
};

export const ordersTableStyle = [
  {
    //checkbox
    minWidth: 30,
    maxWidth: 30,
    style: {
      width: "30px",
      textAlign: "center",
      margin: "0",
      padding: "0",
    },
  },
  {
    //order number
    width: 120,
    minWidth: 120,
    style: {
      textAlign: "center",
      maxWidth: "15%",
      minWidth: "120px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //upc
    width: 160,
    minWidth: 160,
    style: {
      textAlign: "center",
      maxWidth: "15%",
      minWidth: "160px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //provider
    width: 150,
    minWidth: 150,
    style: {
      textAlign: "left",
      maxWidth: "20%",
      minWidth: "150px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //category
    width: 230,
    minWidth: 230,
    style: {
      textAlign: "left",
      width: "19.5%",
      minWidth: "230px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //estat.
    width: 60,
    maxWidth: 60,
    minWidth: 60,
    style: {
      textAlign: "center",
      maxWidth: "60px",
      minWidth: "60px",
      flexGrow: 1,
      overflow: "unset",
      margin: 0,
    },
  },
  {
    //ver.
    width: 60,
    maxWidth: 60,
    minWidth: 60,
    style: {
      textAlign: "center",
      maxWidth: "60px",
      minWidth: "60px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //val.
    width: 50,
    maxWidth: 50,
    minWidth: 50,
    style: {
      textAlign: "center",
      maxWidth: "50px",
      minWidth: "50px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //prio
    width: 50,
    maxWidth: 50,
    minWidth: 50,
    style: {
      textAlign: "center",
      maxWidth: "50px",
      minWidth: "50px",
      flexGrow: 1,
      margin: 0,
      overflow: "unset",
    },
  },
  {
    //requesting date
    maxWidth: 170,
    minWidth: 170,
    style: {
      textAlign: "center",
      maxWidth: "170px",
      minWidth: "170px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //price
    maxWidth: 100,
    minWidth: 100,
    style: {
      textAlign: "center",
      maxWidth: "100px",
      minWidth: "100px",
      flexGrow: 1,
      margin: 0,
    },
  },
];

export const providersTableStyle = [
  {
    //checkbox
    minWidth: 30,
    maxWidth: 30,
    style: {
      width: "30px",
      maxWidth: "30px",
      minWidth: "30px",
      textAlign: "center",
      margin: "0",
      padding: "0",
    },
  },
  {
    //provider
    width: 150,
    minWidth: 150,
    style: {
      textAlign: "left",
      minWidth: "150px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //upc
    width: 160,
    minWidth: 160,
    style: {
      textAlign: "center",
      maxWidth: "15%",
      minWidth: "160px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //category
    width: 230,
    minWidth: 230,
    style: {
      textAlign: "left",
      width: "19.5%",
      minWidth: "230px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //estat.
    width: 80,
    maxWidth: 80,
    minWidth: 80,
    style: {
      textAlign: "center",
      maxWidth: "80px",
      minWidth: "80px",
      flexGrow: 1,
      overflow: "unset",
      margin: 0,
    },
  },
  {
    //ver.
    width: 60,
    maxWidth: 60,
    minWidth: 60,
    style: {
      textAlign: "center",
      maxWidth: "60px",
      minWidth: "60px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //val.
    width: 50,
    maxWidth: 50,
    minWidth: 50,
    style: {
      textAlign: "center",
      maxWidth: "50px",
      minWidth: "50px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //datos
    width: 60,
    maxWidth: 60,
    minWidth: 60,
    style: {
      textAlign: "center",
      maxWidth: "60px",
      minWidth: "60px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //descriptions
    maxWidth: 60,
    minWidth: 60,
    style: {
      textAlign: "center",
      maxWidth: "170px",
      minWidth: "170px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    //images
    maxWidth: 60,
    minWidth: 60,
    style: {
      textAlign: "center",
      maxWidth: "100px",
      minWidth: "100px",
      flexGrow: 1,
      margin: 0,
    },
  },
  {
    // percentage
    maxWidth: 75,
    minWidth: 75,
    style: {
      textAlign: "center",
      maxWidth: "100px",
      minWidth: "100px",
      flexGrow: 1,
      margin: 0,
    },
  },
];
