import { useState, useEffect } from "react";
import { CustomSelect } from "contentoh-components-library";
import { FormContainer } from "../styles";
import InOffice from "./InOffice";

const options = {
  sede: [
    { id: 1, name: "CDMX", value: "CDMX", isSelected: true },
    { id: 2, name: "Monterrey", value: "MTY" },
  ],
  request: [
    { id: 1, name: "Proveedor", value: "provider", isSelected: true },
    { id: 2, name: "Cadena", value: "retailer" },
  ],
  type: [
    { id: 1, name: "Alta", value: "new", isSelected: true },
    { id: 2, name: "Mantenimiento", value: "maintenance" },
  ],
  input: [
    { id: 1, name: "Ficha", value: "Ficha", isSelected: true },
    { id: 2, name: "Picking", value: "Picking" },
    { id: 3, name: "Muestra", value: "Muestra" },
    { id: 4, name: "Complemento", value: "Complemento" },
  ],
};

const Financed = ({ baseDirs, ...props }) => {
  const [currentSede, setCurrentSede] = useState("CDMX");

  const handleOnChange = (action, value) => {
    if (action === "sede") {
      const { street, zipCode, city, latlng } = baseDirs[value];
      props.setAppointment((prev) => ({
        ...prev,
        street,
        city,
        zipCode,
        latlng,
      }));
      setCurrentSede(value);
      props.setSede && props.setSede(value);
    } else {
      props.setAppointment((prev) => ({
        ...prev,
        [action]: value,
      }));
    }
  };

  useEffect(() => {
    const { street, zipCode, city, latlng } = baseDirs.CDMX;
    props.setAppointment((prev) => ({
      ...prev,
      street,
      city,
      zipCode,
      latlng,
      inputType: "provider",
      requestBy: "new",
      orderType: "Ficha",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContainer>
      <div className="financed_option">
        <p>Elige una sede</p>
        <CustomSelect
          isSingleSelect
          options={options.sede.map((e) => ({
            ...e,
            function: () => handleOnChange("sede", e.value),
          }))}
          parameterArray={[]}
          setParameterArray={() => {}}
        />
      </div>
      <div className="financed_option">
        <p>Tipo de input</p>
        <CustomSelect
          isSingleSelect
          options={options.input.map((e) => ({
            ...e,
            function: () => handleOnChange("inputType", e.value),
          }))}
          parameterArray={[]}
          setParameterArray={() => {}}
        />
      </div>
      <div className="financed_option">
        <p>Solicitado por</p>
        <CustomSelect
          isSingleSelect
          options={options.request.map((e) => ({
            ...e,
            function: () => handleOnChange("requestBy", e.value),
          }))}
          parameterArray={[]}
          setParameterArray={() => {}}
        />
      </div>
      <div className="financed_option mb-10">
        <p>Tipo</p>
        <CustomSelect
          isSingleSelect
          options={options.type.map((e) => ({
            ...e,
            function: () => handleOnChange("orderType", e.value),
          }))}
          parameterArray={[]}
          setParameterArray={() => {}}
        />
      </div>
      <InOffice baseDirs={baseDirs} sede={currentSede} />
    </FormContainer>
  );
};

export default Financed;
