import styled from "styled-components";

export const TablesContainer = styled.div`
  flex: 1;
  overflow-y: clip;
  overflow-x: auto;
  display: flex;

  .inter-table {
    min-width: fit-content;
  }
`;
