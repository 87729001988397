import React, { useState, useEffect } from "react";
import {
  CustomSelect,
  CheckBox,
  TableResizable,
} from "contentoh-components-library";
import { Container, Input } from "./styles.js";
import { getAcquiredProducts, updateData } from "../../../_utils/data";
import { getStatusComponent, getUserComponent, updateSKU } from "./utils.js";
import { FilterInput } from "../../../filterInput/index.js";
import Modal from "../../../merchantsLists/Modal/Modal";

export default function Acquired() {
  const [tableContent, setTableContent] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [newSKU, setNewSKU] = useState("");
  const [updateTable, setUpdateTable] = useState(0);
  const [isFirstExecution, setIsFirstExecution] = useState(true);
  const [isRetailer] = useState(
    JSON.parse(sessionStorage.getItem("user")).is_retailer
  );
  const [modal, setModal] = useState({
    show: false,
    articleId: null,
    versionId: null,
  });
  const [filters, setFilters] = useState({
    upc: [],
    providers: [],
    category: [],
  });
  const [tableHeaders] = useState([
    "SKU",
    "Nombre",
    "Modelo",
    "Proveedor",
    "Departamento",
    "Estatus",
    "Orden",
    "Adquirido por",
  ]);

  const setRows = (data, isRetailer) =>
    data.map((article) => {
      const params = [
        article.sku ?? "Sin Asignar",
        article.articleId,
        article.version,
        setModal,
      ];
      if (!isRetailer) params.pop();
      return {
        key: article.articleId,
        object: article,
        columns: [
          updateSKU(...params),
          article.name,
          article.model,
          article.companyName,
          article.category?.replace(/\|/g, " / "),
          getStatusComponent(article.status),
          article.orderId ?? "N/A",
          getUserComponent(article),
        ],
      };
    });

  const saveSKU = async (articleId, versionId, value) => {
    const articleData = [{ articleId, versionId, attributeId: 40001, value }];
    const params = {
      query: { datasheet: true, version: versionId },
      body: { articleId, articleData },
    };
    await updateData(params);
    const index = tableContent.findIndex(
      ({ object }) =>
        object.articleId === articleId && object.version === versionId
    );
    if (index !== -1) {
      const tempTableContent = tableContent.slice();
      tempTableContent[index].object.sku = value;
      setTableContent(tempTableContent);
      setUpdateTable((prev) => (prev += 1));
    }
  };

  useEffect(() => {
    const params = { query: { filters } };
    const controller = new AbortController();
    getAcquiredProducts(params).then((res) => {
      const { data, categories, providers } = JSON.parse(res.data.body);
      let articles = Object.values(data);
      if (isFirstExecution) {
        setProvidersList(providers ?? []);
        setCategoriesList(categories ?? []);
        setIsFirstExecution(false);
      }
      if (filters.upc.length > 0) {
        articles = articles.filter((f) => filters.upc.includes(f.sku));
      }
      setTableContent(setRows(articles, isRetailer));
    });
    return () => controller.abort("Exit Component");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, updateTable]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      providers: selectedProviders,
      category: selectedCategories,
    }));
  }, [selectedProviders, selectedCategories]);

  const filterInputFunct = (params) => {
    const values = params?.UPC?.values ?? [];
    setFilters((prev) => ({ ...prev, upc: values }));
  };

  return (
    <Container>
      <div className="filters">
        <FilterInput
          className="filter-input"
          classNameFilterContainer="container-InputSection"
          placeHolderInput="Buscar SKU"
          filterInputFunct={filterInputFunct}
          filtersArray={[{}]}
          filterArray={[{}]}
          hiddenLabelProductsLoaded
          hideButtons
        />
        {isRetailer ? (
          <CustomSelect
            customSelectId="select-provider"
            selectLabel="Todos los proveedores"
            showSearchBar
            placeHolder={"Buscar proveedor"}
            options={providersList}
            parameterArray={selectedProviders}
            setParameterArray={setSelectedProviders}
          />
        ) : null}
        <CustomSelect
          customSelectId="select-categories"
          selectLabel="Todos los Departamentos"
          showSearchBar
          placeHolder={"Buscar departamento"}
          options={categoriesList}
          parameterArray={selectedCategories}
          setParameterArray={setSelectedCategories}
        />
        <CheckBox
          id="checkbox-filter"
          label="Ver solo faltantes de SKU"
          onChange={(e) => {
            const values = e.target.checked ? [undefined] : [];
            setFilters((prev) => ({ ...prev, upc: values }));
          }}
        />
      </div>
      <div className="table">
        <TableResizable
          headers={tableHeaders}
          tableContent={tableContent}
          minCellWidth={120}
        />
      </div>
      {modal.show && (
        <Modal
          onClickBtnClose={(event) => {
            event.stopPropagation();
            setModal({ show: false });
          }}
          title="Actualizar SKU"
          message="A continuación escriba el nuevo SKU"
          customComponent={
            <Input onChange={(e) => setNewSKU(e.target.value)} />
          }
          buttons={[
            <button
              key={"modalInputAddlist-btnCancelar"}
              className="btnTransparent"
              onClick={(event) => {
                event.stopPropagation();
                setModal({ show: false });
              }}
            >
              Cancelar
            </button>,
            <button
              key={"modalInputAddlist-btnGuardar"}
              className="btnPink"
              onClick={async (event) => {
                event.stopPropagation();
                await saveSKU(modal.articleId, modal.versionId, newSKU);
                setModal({ show: false });
              }}
            >
              Guardar
            </button>,
          ]}
        />
      )}
    </Container>
  );
}
