import React, { useState, useEffect } from "react";
import { RowStatus, Container, StatusTab, Item } from "./styles";
import { getStatusColor, getShortStatus } from "../../helper";

export default function Status(props) {
  const [isHover, setIsHover] = useState(false);
  const isUserRetailer =
    JSON.parse(sessionStorage.getItem("user")).is_retailer === 1;
  const [statusList, setStatusList] = useState([
    { key: "PA", name: "Por Asignar" },
    { key: "AS", name: "Asignado" },
    { key: "R", name: "Recibido" },
    { key: "CA", name: "Capturando" },
    { key: "IE", name: "Información Enviada" },
    { key: "RC", name: "Rechazado Coordinador" },
    { key: "AC", name: "Aprobado Coordinador" },
    { key: "RA", name: "Rechazado Auditor" },
    { key: "AA", name: "Aprobado Auditor" },
    { key: "RP", name: "Rechazado Proveedor" },
    { key: "AP", name: "Aprobado Proveedor" },
    { key: "ACA", name: "Aprobado Cadena" },
    { key: "RCA", name: "Rechazado Cadena" },
    { key: "SAC", name: "Solicitud Auditoría Content-oh!" },
    { key: "AAC", name: "Aprobado Auditoría Content-oh!" },
    { key: "RAC", name: "Rechazado Auditoría Content-oh!" },
    { key: "FAP", name: "Finalización Auditoría por Proveedor" },
    { key: "Ex", name: "Exportado" },
    { key: "NS", name: "No Solicitado" },
    { key: "S/OT", name: "Versión sin OT" },
  ]);
  const userRevision = (status) => {
    if (status === "AA")
      return isUserRetailer ? "No hace falta revisión" : "Revisión pendiente";
    if (status === "AP")
      return isUserRetailer ? "Revisión pendiente" : "Revisión terminada";
    if (status === "RP")
      return isUserRetailer ? "No hace falta revisión" : "Revisión terminada";
    if (["ACA", "Ex"].includes(status)) return "Revisión terminada";
    if (status === "RCA")
      return isUserRetailer ? "Revisión terminada" : "No hace falta revisión";
  };
  const tasksStatus = [
    { key: "AA", name: `Aprobado Auditor (${userRevision("AA")})` },
    { key: "AP", name: `Aprobado Proveedor (${userRevision("AP")})` },
    { key: "ACA", name: `Aprobado Cadena (${userRevision("ACA")})` },
    { key: "RP", name: `Rechazado Proveedor (${userRevision("RP")})` },
    { key: "RCA", name: `Rechazado Cadena (${userRevision("RCA")})` },
    { key: "NS", name: "No Solicitado (No hace falta revisión)" },
    {
      key: "S/OT",
      name: "Versión sin orden de trabajo (No hace falta revisión)",
    },
  ];

  useEffect(() => {
    const typeIsTasks = props.type === "tasks";
    const typeSingleStatus = props.type === "singleStatus";
    if (typeIsTasks) setStatusList(tasksStatus);
    else if (typeSingleStatus)
      setStatusList(tasksStatus.filter((stat) => stat.key === props.status));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      onMouseEnter={() => {
        if (!props.noHover)
          getStatusColor(props.status) !== "#FFFFFFF" && setIsHover(true);
      }}
      onMouseLeave={() => setIsHover(false)}
    >
      <RowStatus width={props.width} bg={getStatusColor(props.status)}>
        <div>{getShortStatus(props.status)}</div>
      </RowStatus>
      {isHover && (
        <StatusTab>
          <div>
            <span>Estatus del producto</span>
            <p>Actual situación en el proceso</p>
          </div>
          <ul>
            {statusList.map((e) => (
              <Item status={e.key}>
                <RowStatus bg={"-"}>
                  <div>{getShortStatus(e.key)}</div>
                </RowStatus>
                {e.name}
              </Item>
            ))}
          </ul>
        </StatusTab>
      )}
    </Container>
  );
}
