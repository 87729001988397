import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  .buttonIcon {
    width: ${({ sizeIcon }) => `${sizeIcon}px`};
    height: ${({ sizeIcon }) => `${sizeIcon}px`};
    padding: 0;
    background-color: transparent;
    border: 1px solid;
    border-color: #f0f0f0;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60%;
      height: 60%;
    }
  }
  .list {
    width: 155px;
    position: absolute;
    top: 100%;
    z-index: 5;
    background: #fff;
    border-radius: 5px;
    padding: 10px 0;
    box-shadow: 0px 2px 4px #00000040;
    & > li {
      font-family: "Roboto";
      color: #262626;
      font-size: 12px;
      padding: 10px 20px;
      cursor: pointer;
      &:hover {
        background-color: #00000010;
      }
    }
    ${({ right }) => (right ? "right: 0;" : "left: 0;")};
  }
`;
