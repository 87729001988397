import { useEffect, useState } from "react";

const useDocTitle = (title) => {
  const [doctitle, setDocTitle] = useState(title ? title : "Dashboard");

  useEffect(() => {
    document.title = `Content-oh! | ${doctitle}`;
    sessionStorage.setItem("title", doctitle);
  }, [doctitle]);

  return [doctitle, setDocTitle];
};

export { useDocTitle };
