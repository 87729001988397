import { TablesContainer } from "./styles";
import { DinamicTable } from "../dinamicTable";
import React, { useState } from "react";
import { DescriptionsTables } from "../descriptionsTable";

const TableHeader = [
  "check",
  "UPC",
  "Nombre",
  "Departamento",
  "Categoría",
  "Cadenas",
  "Asig./Estatus",
  "Ver.",
];

export const TablesSection = (props) => {
  const informationHeader = props.informationHeader;
  const [articlesSelected, setArticlesSelected] = useState([]);

  const productListFinal = (list) => {
    let tempList = [];

    list.forEach((element) => {
      let tempElement = [];

      tempElement.push([element.upc || element.article.upc]);
      tempElement.push([element.name || element.article.name]);

      let arrCategory = (
        element.categoryName || element.article.category
      ).split("|");
      tempElement.push([arrCategory[0]]); //to Department
      tempElement.push([arrCategory[1]]); //to Category

      tempElement.push([
        [(element.retailersAvailable || element.retailers).length],
        [element.retailersAvailable || element.retailers],
      ]);
      if (!element.id_order) {
        element.asignations
          ? tempElement.push([element.asignations[0]?.id_user])
          : tempElement.push(["-"]);
      } else {
        tempElement.push([
          element.status,
          element.datasheet_status,
          element.description_status,
          element.images_status,
        ]);
      }
      tempElement.push([element.version]);
      tempList.push(tempElement);
    });

    return tempList;
  };

  const handleScrollLeft = (e) => {
    if (props.navSelected !== "description") {
      document.getElementById("rightScroll").scrollTop = e.target.scrollTop;
    } else {
      document
        .querySelectorAll(".rightScroll")
        ?.forEach((element) => (element.scrollTop = e.target.scrollTop));
    }
  };

  const handleScrollRight = (e) => {
    document.getElementById("leftScroll").scrollTop = e.target.scrollTop;
  };

  return (
    <TablesContainer>
      <DinamicTable
        id={"leftScroll"}
        onScroll={handleScrollLeft}
        tableHeader={TableHeader}
        concept={props.navSelected}
        productsList={productListFinal(props.productsList)}
        setProductsList={props.setProductsList}
        productsOriginalFormat={props.productsList}
        tableTitle="Información general"
        tableInfo={true}
        showCheckbox={true}
        tasks={
          props.productsList.filter(
            (product) => product.status || product.article_status
          ).length > 0
        }
        setArticlesSelected={setArticlesSelected}
        articlesSelected={articlesSelected}
        saveAll={props.saveAll}
        setLoading={props.setLoading}
        getDataSheets={props.getDataSheets}
      />
      {props.navSelected !== "description" ? (
        <DinamicTable
          id={"rightScroll"}
          onScroll={handleScrollRight}
          tableHeader={informationHeader}
          tableTitle="Información básica"
          subTitle={"Marca y modelo"}
          information={props.dataTable}
          navSelected={props.navSelected}
          keys={props.keys}
          dataSheets={props.dataSheets}
          images={props.images}
          imagesKeys={props.imagesKeys}
          articlesSelected={articlesSelected}
          updatedDatasheets={props.updatedDatasheets}
          setUpdatedDatasheets={props.setUpdatedDatasheets}
        />
      ) : (
        <DescriptionsTables
          scrollClass={"rightScroll"}
          onScroll={handleScrollRight}
          descriptions={props.descriptions}
          descriptionsHeader={props.descriptionsHeader}
          navSelected={props.navSelected}
          articlesSelected={articlesSelected}
          updatedDescriptions={props.updatedDescriptions}
          setUpdatedDescriptions={props.setUpdatedDescriptions}
        />
      )}
    </TablesContainer>
  );
};
