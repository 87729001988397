import styled from "styled-components";
import { grayS5 } from "../../../variables";

export const Container = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 40% 60%;
  grid-template-rows: 1fr;
  gap: 0 20px;
  grid-template-areas: "payment articles";
  font-family: Roboto, sans-serif;
  height: calc(100vh - 280px);
  width: 100%;
  .payment {
    grid-area: payment;
    overflow: hidden auto;
    padding-right: 10px;
    & > * {
      margin-bottom: 20px;
    }
    h2 {
      font-family: Roboto;
      font-size: 15px;
      line-height: 20px;
      color: #262626;
      margin-bottom: 10px;
    }
  }
  .articles {
    grid-area: articles;
  }
`;

export const TwoColumns = styled.div`
  display: grid;
  width: 100%;
  grid-auto-rows: 1fr;
  grid-template-columns: ${({ left, right }) =>
    left && right ? `${left} ${right}` : "50% 50%"};
  grid-template-rows: 100%;
  gap: ${({ gap }) => gap ?? "0"};
  grid-template-areas: "first second";
  .products,
  .first {
    grid-area: first;
    display: flex;
    overflow-y: auto;
    font-family: Roboto;
    & > div {
      display: block;
      width: 100%;
      overflow-x: hidden;
    }
    &.margin {
      padding-left: 50px;
    }
    label > p {
      color: #fff;
    }
    label:before {
      width: 12px;
      height: 12px;
      background-size: 12px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    height: 34px;
  }
  .products  {
    max-height: calc(100vh - 300px);
  }
  .first {
    overflow-y: hidden;
  }
  .cards {
    grid-area: second;
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
  }
`;

export const PaymentCard = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  width: 100%;
  padding: 15px 15px 5px 20px;
  position: relative;
  p {
    font-family: Roboto;
    font-size: 15px;
    line-height: 20px;
    color: #262626;
    margin-bottom: 10px;
  }
  label {
    font-family: Roboto;
    font-size: 12px;
    color: #808080;
    line-height: 28px;
  }
  .info {
    color: #262626;
  }
  > div {
    align-content: center;
  }
  ${({ isSelected }) =>
    isSelected &&
    `border-color: #8A6CAA;
  background: #F7F7FC;`}
  img {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &:focus-within {
    border-width: 0.5px;
    border-style: dashed solid;
    border-color: #8a6caa;
  }

  .checkbox {
    label {
      width: 100%;
      & > p {
        display: block;
        margin: 0 0 0 25px;
      }
    }
    margin-bottom: 20px;
  }
`;

export const Input = styled.input`
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  height: 28px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`;

export const RSShoppingTable = styled.div`
  flex: 1 0 0%;
  overflow: auto;
  width: 100%;
  /* padding: 20px; */
  height: calc(100vh - 280px);

  table {
    width: 100%;

    tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;

      th,
      td {
        flex: calc(60% / 4);
        color: ${grayS5};
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        text-align: center;

        &:first-child {
          text-align: left;
          flex: 40%;
        }

        &:last-child {
          flex: 15%;
          text-align: right;
        }

        &.retailers-images {
          padding: 0 10px;
          border-radius: 50%;
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;

          img {
            cursor: pointer;
            overflow: hidden;
            width: 18px;
            border: 1px solid #fff;
            border-radius: 50%;
            background-color: #fff;
            & + * {
              margin-left: -5px;
            }
          }
        }

        &.services-icons {
          cursor: default;
          div {
            display: inline;
            margin-right: 3px;
          }
          span {
            font-size: 14px;
          }
        }
      }

      td {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        color: #707070;
        white-space: nowrap;

        .product_name {
          font-size: 12px;
          color: #202020;
        }

        &:nth-child(1) {
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }

      border-bottom: 1px solid #f0f0f0;
    }

    thead {
      display: block;
      padding: 10px 20px;
      background-color: #f0f0f0;
      color: #262626;
      border-radius: 5px;
      position: sticky;
      top: 0;

      tr {
        padding: 0;
        th {
          color: #262626;
          font-family: Roboto;
        }
      }
    }

    tbody {
      height: 100%;
    }
  }
`;
