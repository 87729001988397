import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  background-color: #fff;
  padding-left: 50px;
  color: #707070;
  font-size: 12px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
  .retailer__img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .retailer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & > div:last-child {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      margin-right: 100px;
      & > div {
        margin-left: 25px;
        box-sizing: border-box;
      }
      /* padding-right: 10px; */
    }
    .ignored {
      color: #b64545;
      text-decoration: line-through;
    }
    .service,
    .service__select {
      cursor: pointer;
      /* margin-left: 5px; */
    }
    .service {
      color: #00000029;
    }
    .service__select {
      color: #8a6caa;
    }
    .disable {
      cursor: not-allowed;
      color: #f0f0f0;
      /* margin-left: 5px; */
    }
    div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ServiceDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: start !important;
  width: 85px;
  & > span {
    margin-right: 3px;
  }
`;
