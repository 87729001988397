import { tableStyle } from "../table_template";
import { Column, Table } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { RowColumn, PrincipalGrid, GridRow, ContainerGrid } from "../styles";
import GridView from "../../../_utils/TableComponents/GridView/index.js";
import Percentage from "../../../_utils/TableComponents/Percentage";
import CustomSelectLists from "../customSelectLists";

const GeneralProducts = (props) => {
  const formatPrice = (price) => {
    if (!price) return "Sin dato";
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Number(price));
  };

  const formatList = (values) => {
    const formatter = new Intl.ListFormat("es", {
      style: "long",
      type: "conjunction",
    });
    return formatter.format(values);
  };

  // cuando se da click en checkbox de la tarjeta de un producto
  const onClickCheckboxProduct = (isChecked, articleId) => {
    if (isChecked) {
      // new producto seleccionado
      props.eventsFunctions.setProductsSelected([
        ...props.productsSelected,
        articleId,
      ]);
    } else {
      // delete product seleccionado
      props.eventsFunctions.setProductsSelected(
        props.productsSelected.filter((id) => id !== articleId)
      );
    }
  };

  if (!props.viewMode) {
    return (
      <>
        {/*FIXME: Aqui comentamos todo los onclick para la vista de tabla nadamás para que no saliera el error que Alma andaba friegue y friegue*/}
        <Table
          className="table-products"
          rowClassName="table-row"
          headerStyle={tableStyle}
          // onRowClick={(evt) => {
          //   //props.eventsFunctions.rowClicked(props.articlesList[evt.index]);
          // }}
          height="720"
          width="1440"
          headerHeight={20}
          rowHeight={30}
          rowCount={props.articlesList.length}
          rowGetter={({ index }) => props.articlesList[index]}
          tableStyle={{
            overflow: "unset",
          }}
          rowStyle={{
            display: "flex",
            overflow: "unset",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Column
            className="table-column"
            flexGrow={1}
            maxWidth={35}
            minWidth={35}
            dataKey={"article"}
            style={{
              width: "35px",
              textAlign: "center",
              margin: "0",
            }}
            label={
              <input
                onClick={(evt) => {
                  evt.stopPropagation();
                  props.eventsFunctions.onClickSelectAllProducts(
                    evt.target.checked
                  );
                }}
                type="checkbox"
              />
            }
            cellRenderer={({ cellData, rowIndex, dataKey }) => {
              return (
                <input
                  key={`checkbox${rowIndex}-${cellData.id_article}`}
                  type="checkbox"
                  checked={props.productsSelected.includes(cellData.id_article)}
                  onClick={(evt) => {
                    evt.stopPropagation();
                  }}
                  onChange={(event) => {
                    onClickCheckboxProduct(
                      event.target.checked,
                      cellData.id_article
                    );
                  }}
                />
              );
            }}
          />
          <Column
            label={""}
            width={40}
            minWidth={40}
            dataKey={"article"}
            style={{
              textAlign: "center",
              minWidth: "75px",
              maxWidth: "75px",
              margin: "auto",
              overflow: "unset",
            }}
            cellRenderer={({ cellData, rowIndex, dataKey }) => {
              const prod = props.articlesList[rowIndex];

              return (
                <RowColumn>
                  {/* select de las listas del producto */}
                  {props.productsSelected.length === 0 ? (
                    <CustomSelectLists
                      idSelect={"selectProd_table" + cellData.id_article}
                      articleID={cellData.id_article}
                      articleLists={prod.lists.map((l) => ({ ...l }))}
                      allLists={props.allLists ?? []}
                      countNotifications={props.countNotifications}
                      setCountNotifications={
                        props.eventsFunctions.setCountNotifications
                      }
                      onClickItem={props.eventsFunctions.onClickItemList}
                      componentFooter={props.listsMenu_componentFooter}
                      isShowModal={props.isShowModal}
                    />
                  ) : (
                    " "
                  )}
                </RowColumn>
              );
            }}
          />
          <Column
            className="table-column"
            flexGrow={1}
            width={270}
            minWidth={270}
            label={"Nombre"}
            dataKey={"article"}
            style={{
              textAlign: "left",
              maxWidth: "24%",
              minWidth: "270px",
              flexGrow: 1,
              margin: 0,
            }}
            cellRenderer={({ cellData, rowIndex }) => (
              <RowColumn
              // onClick={(evt) => {
              //   props.eventsFunctions.rowClicked(
              //     props.articlesList[rowIndex]
              //   );
              // }}
              >
                <p>{cellData.name}</p>
              </RowColumn>
            )}
          />
          <Column
            className="table-column"
            flexGrow={1}
            width={150}
            minWidth={150}
            style={{
              textAlign: "left",
              maxWidth: "19%",
              minWidth: "150px",
              flexGrow: 1,
              margin: 0,
            }}
            label={"Proveedor"}
            dataKey={"article"}
            cellRenderer={({ cellData, rowIndex }) => (
              <RowColumn
              // onClick={(evt) => {
              //   props.eventsFunctions.rowClicked(
              //     props.articlesList[rowIndex]
              //   );
              // }}
              >
                <p>{cellData.company_name}</p>
              </RowColumn>
            )}
          />
          <Column
            className="table-column"
            flexGrow={1}
            width={250}
            minWidth={250}
            label={"Departamento"}
            dataKey={"article"}
            style={{
              textAlign: "left",
              width: "8%",
              minWidth: "250px",
              flexGrow: 1,
              margin: 0,
            }}
            cellRenderer={({ cellData, rowIndex }) => (
              <RowColumn
              // onClick={(evt) => {
              //   props.eventsFunctions.rowClicked(
              //     props.articlesList[rowIndex]
              //   );
              // }}
              >
                <p>{cellData?.category?.replaceAll("|", " / ")}</p>
              </RowColumn>
            )}
          />
          <Column
            className="table-column"
            flexGrow={1}
            width={100}
            minWidth={100}
            style={{
              textAlign: "left",
              width: "8%",
              minWidth: "250px",
              flexGrow: 1,
              margin: 0,
            }}
            label={"Precio"}
            dataKey={"data"}
            cellRenderer={({ cellData, rowIndex }) => (
              <RowColumn
              // onClick={(evt) => {
              //   props.eventsFunctions.rowClicked(
              //     props.articlesList[rowIndex]
              //   );
              // }}
              >
                <p>{formatPrice(cellData?.price)}</p>
              </RowColumn>
            )}
          />
          <Column
            className="table-column"
            flexGrow={1}
            width={250}
            minWidth={250}
            style={{
              textAlign: "left",
              maxWidth: "20%",
              minWidth: "250px",
              flexGrow: 1,
              margin: 0,
            }}
            label={"Listas"}
            dataKey={"lists"}
            cellRenderer={({ cellData, rowIndex }) => (
              <RowColumn
              // onClick={(evt) => {
              //   props.eventsFunctions.rowClicked(
              //     props.articlesList[rowIndex]
              //   );
              // }}
              >
                <p>{formatList(cellData.map(({ name }) => name))}</p>
              </RowColumn>
            )}
          />
          <Column
            className="table-column"
            flexGrow={1}
            maxWidth={60}
            minWidth={60}
            label={"%"}
            dataKey={"article"}
            style={{
              textAlign: "center",
              maxWidth: "85px",
              minWidth: "85px",
              flexGrow: 1,
              margin: 0,
            }}
            cellRenderer={({ cellData, rowIndex, dataKey }) => (
              <Percentage
                percentage={
                  parseFloat(
                    props.articlesList[rowIndex].percentage
                  )?.toFixed() ?? 0
                }
              />
            )}
          />
        </Table>
      </>
    );
  } else {
    return (
      <>
        <ContainerGrid>
          <PrincipalGrid>
            {props.articlesList?.map((prod, index) => {
              const articleId = prod.article.id_article;
              return (
                <GridRow key={articleId}>
                  {/* tarjeta de un producto */}
                  <GridView
                    dataProduct={prod}
                    checkedCheckboxProduct={props.productsSelected.includes(
                      articleId
                    )}
                    onClickCheckboxProduct={onClickCheckboxProduct}
                    productsSelected={props.productsSelected}
                    percentage={prod.percentage}
                    index={index}
                    eventsFunctions={props.eventsFunctions}
                    key={"card-" + articleId}
                    allLists={props.allLists ?? []}
                    articleLists={prod.lists.map((l) => ({ ...l }))}
                    countNotifications={props.countNotifications}
                    listsMenu_componentFooter={props.listsMenu_componentFooter}
                    isShowModal={props.isShowModal}
                    percentageRequired={prod.percentageRequired}
                    showModalAlert={props.showModalAlert}
                    setModalAlert={props.setModalAlert}
                    articlesList={props.articlesList}
                    updateVisibility={props.updateVisibility}
                    updateScenary={props.updateScenary}
                    setProductsSelected={props.setProductsSelected}
                    section={props.section}
                    showNotification={props.showNotification}
                  />
                </GridRow>
              );
            })}
          </PrincipalGrid>
        </ContainerGrid>
      </>
    );
  }
};
export default GeneralProducts;
