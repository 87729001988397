import React, { useState, useEffect } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import MyAccount from "../../../assets/IconComponents/MyAccount.svg";
import AccountLogOut from "../../../assets/IconComponents/AccountLogOut.svg";
import IconStore from "../../../assets/IconComponents/IconStore";
import Separator from "../../../assets/IconComponents/Separator";
import { Redirect } from "react-router-dom";
import {
  ContainerHeader,
  ContainIcon,
  PulsatingCircle,
  UserInfo,
  Container,
  ContainerInfo,
  ContainerInfoSubtitle,
  ContainerInfoRight,
  ContainerInfoTitle,
  HeaderTitle,
  HeaderInfo,
  HeaderSearch,
  ProfileMenu,
  ProfileNavLink,
} from "./styles";
import { DashboardRouters } from "../../../routers/DashboardRouters.js";
import IconNotifications from "../../../assets/IconComponents/IconNotifications";
import NotifyBar from "../../general/notifyBar/index";
import RequestedServices from "../requestedServices/RequestedServices";
import RequestedProduct from "../requestedProduct/RequestedProduct";
import { useDocTitle } from "../../../hooks/useTitles";
import { useDescripionPage } from "../../../hooks/useDescripionPage";
import GenericModal from "../../general/GenericModal";
import ReactImageFallback from "react-image-fallback";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";
import spinner from "../../../assets/defaultImages/Spinner.gif";

import { getRoleUser } from "../../_utils/helper.js";

export const ContainerDashboard = () => {
  const [doctitle, setDocTitle] = useDocTitle(
    sessionStorage.getItem("title")
      ? sessionStorage.getItem("title")
      : "Dashboard"
  );
  const [description, setDescription] = useDescripionPage(
    sessionStorage.getItem("description")
      ? sessionStorage.getItem("description")
      : ""
  );
  const history = useHistory();
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [notifications, setNotifications] = useState([]);
  const [display, setdisplay] = useState(false);
  const [notifyMenu, setCount] = useState(false);
  const [unreadNotif, setUnreadNotif] = useState(false);
  const [productsLimitReached, setProductsLimitReached] = useState(false);
  const [upgradePlanRedirect, setUpgradePlanRedirect] = useState(false);

  const getNotifications = async () => {
    const notificationsResponse = await axios.get(
      process.env.REACT_APP_NOTIFICATIONS_ENDPOINT,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const notif = JSON.parse(notificationsResponse?.data?.body).data;
    setNotifications(notif);
  };
  const checkImage = 1000;
  useEffect(() => {
    getNotifications();
    const intervalImg = setInterval(() => {
      if (user.src !== JSON.parse(sessionStorage.getItem("user")).src) {
        setUser(JSON.parse(sessionStorage.getItem("user")));
      }
    }, checkImage);
    return () => {
      clearInterval(intervalImg);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUnreadNotif(notifications.filter((not) => not.read === 0).length > 0);
  }, [notifications]);

  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [title, setTitle] = useState({});

  useEffect(() => {
    title.title = sessionStorage.getItem("title");
    title.info = sessionStorage.getItem("description");
    setTitle(title);
  }, [title]);

  const openMenuUser = (e) => {
    if (
      !e.target.closest("#menu-user") &&
      display &&
      window.location.pathname !== "/DashboardContentoh"
    ) {
      document.removeEventListener("click", openMenuUser, false);
      setdisplay(false);
    }
  };

  useEffect(() => {
    if (display) {
      document.addEventListener("click", openMenuUser, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display]);

  useEffect(() => {
    if (upgradePlanRedirect) {
      setProductsLimitReached(false);
      setUpgradePlanRedirect(false);
    }
  }, [upgradePlanRedirect]);

  return (
    <>
      <Container>
        <ContainerHeader>
          <ContainerInfo>
            <HeaderTitle>{doctitle}</HeaderTitle>
            <HeaderInfo>{description}</HeaderInfo>
          </ContainerInfo>
          <HeaderSearch></HeaderSearch>
          <Separator />
          <ContainIcon
            onClick={() => {
              setShow(true);
            }}
          >
            <IconStore />
            <PulsatingCircle notifications={false} />
            {showNotification && <RequestedProduct />}
          </ContainIcon>
          <ContainIcon
            onClick={() => {
              setCount(true);
            }}
          >
            <IconNotifications />
            <PulsatingCircle notifications={unreadNotif} />
          </ContainIcon>
          <UserInfo
            id="menu-user"
            onClick={(e) => {
              setdisplay(!display);
              if (display)
                document.removeEventListener("click", openMenuUser, false);
              e.stopPropagation();
            }}
          >
            <div className="avatar-container">
              <ReactImageFallback
                src={user.src}
                fallbackImage={defaultProfile}
                initialImage={spinner}
                alt="avatar"
                className="avatar"
              />
            </div>
            <ContainerInfoRight>
              <ContainerInfoTitle>{`${user.name} ${user.last_name}`}</ContainerInfoTitle>
              <ContainerInfoSubtitle>{user.position}</ContainerInfoSubtitle>
            </ContainerInfoRight>
            {display && (
              <ProfileMenu>
                <div className="title-role">
                  <p>{getRoleUser(user.id_role)}</p>
                </div>
                <ProfileNavLink to="/profile">
                  <div className="icon-container">
                    <img src={MyAccount} alt="My account circle" />
                  </div>
                  <p>Mi cuenta</p>
                </ProfileNavLink>
                <ProfileNavLink
                  to="#"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowGenericModal(!showGenericModal);
                  }}
                >
                  <div className="icon-container">
                    <img src={AccountLogOut} alt="log out icon" />
                  </div>
                  <p>Cerrar sesión</p>
                </ProfileNavLink>
              </ProfileMenu>
            )}
          </UserInfo>
        </ContainerHeader>
        <DashboardRouters
          setShowNotification={setShowNotification}
          setDocTitle={setDocTitle}
          setDescription={setDescription}
        />
        {notifyMenu && (
          <NotifyBar
            notifyMenu={notifyMenu}
            setCount={setCount}
            setNotifications={setNotifications}
          />
        )}
        {show && <RequestedServices show={show} setShow={setShow} />}
        {productsLimitReached && (
          <GenericModal
            close={() => setProductsLimitReached(!productsLimitReached)}
            message="Excediste el número máximo de productos permitidos en tu plan"
            button1={{
              name: "Entendido",
              action: () => setProductsLimitReached(!productsLimitReached),
            }}
            button2={{
              name: "Cambiar de plan",
              action: () => {
                setUpgradePlanRedirect(true);
              },
            }}
          />
        )}
        {upgradePlanRedirect && (
          <Redirect
            to={{
              pathname: `/upgradeplan`,
            }}
          />
        )}
      </Container>
      {showGenericModal && (
        <GenericModal
          close={() => setShowGenericModal(!showGenericModal)}
          message="¿Estás seguro que quieres cerrar sesión?"
          button1={{
            name: "Cancelar",
            action: () => setShowGenericModal(!showGenericModal),
          }}
          button2={{
            name: "Cerrar sesión",
            action: async () => {
              try {
                await Auth.signOut({ global: true });
                history.push({ pathname: "/login" });
                window.location.reload();
                sessionStorage.clear();
              } catch (error) {}
            },
          }}
        />
      )}
    </>
  );
};
