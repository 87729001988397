import React, { useState, useEffect } from "react";
import { Container } from "../products/productsGeneral/styles";
import { TitleOrders, ContainOrderLeft } from "./styles";
import axios from "axios";

export default function HeaderOrdersL(props) {
  const [tableData, setTableData] = useState([]);

  const getData = async () => {
    try {
      const appointmentsData = await axios({
        method: "get",
        url: process.env.REACT_APP_APPOINTMENT_ENDPOINT,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      const appointments = JSON.parse(appointmentsData.data.body).data;
      setTableData([]);

      for (let i = 0; i < appointments.length; i++) {
        setTableData((prev) => [...prev, appointments[i]]);
        if (i === 2) break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container justifycontent="flex-start" className="orders-header">
      <ContainOrderLeft>
        <TitleOrders className="work-orders-title">
          Órdenes de trabajo
        </TitleOrders>
      </ContainOrderLeft>
    </Container>
  );
}
