import React, { useEffect, useState } from "react";
import { TableContainer, ColumnHead, RowHead, Column, Row } from "./styles";

export default function Table(props) {
  const [configArray, setConfigArray] = useState([]);

  useEffect(() => {
    const tempArray = [];
    const sumWidths = props.dataHead?.reduce((prev, curr) => {
      return prev + +curr.width;
    }, 0);
    props.dataHead?.forEach((e) => {
      tempArray.push({
        minWidth: e.width,
        align: e.align,
        width: (e.width * 100) / sumWidths,
        noOverflow: e.noOverflow,
        noRowEvent: e.noRowEvent,
      });
    });
    setConfigArray(tempArray);
  }, [props.dataHead]);

  const reduceArray = (e) => {
    const newArray = [];
    for (let i = props.dataHead?.length; i < e.length; i++) {
      newArray.push(e[i]);
    }
    return newArray;
  };

  return (
    <TableContainer
      id={props.id || "table"}
      onScroll={(e) => props.onScroll && props.onScroll(e)}
    >
      <RowHead>
        {props.dataHead?.map((e, i) => (
          <ColumnHead
            key={`head${i}`}
            minWidth={configArray[i]?.minWidth}
            width={configArray[i]?.width}
          >
            {e.name}
          </ColumnHead>
        ))}
      </RowHead>
      {props.data?.map((e, idx) => (
        <Row index={idx} key={`rowData${idx}`}>
          {e.length > 0 &&
            e.map(
              (col, i) =>
                i < props.dataHead?.length && (
                  <Column
                    key={`col${idx}-${i}`}
                    minWidth={configArray[i]?.minWidth}
                    align={configArray[i]?.align}
                    width={configArray[i]?.width}
                    noOverflow={configArray[i]?.noOverflow}
                    onClick={(event) =>
                      !configArray[i]?.noRowEvent &&
                      props.rowClick &&
                      props.rowClick(reduceArray(e), idx)
                    }
                  >
                    {col}
                  </Column>
                )
            )}
        </Row>
      ))}
    </TableContainer>
  );
}
