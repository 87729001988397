import styled from "styled-components";
import { grayS2, grayS5, grayS6, grayS7, greenS1, pinkS1 } from "../variables";

export const Container = styled.div`
  padding: 25px 0 0 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  //border: 1px solid red;
  height: 100%;
  margin-bottom: 15px;
  overflow: hidden;

  .filter-input {
    //border: 1px solid red;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    //gap: 12px 30px;
    margin-bottom: 35px;
    padding: 0 20px 0 0;

    > * {
      //border: 1px solid blue;
    }

    > .filter-container {
      width: auto;
      min-width: 300px;
      flex-grow: 2;
      gap: 12px;
      //border: 1px solid red;

      input {
        font-family: "Roboto Medium", "Segoe UI", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #262626;
      }

      // divButtons
      + div {
        //border: 1px solid red;
        flex-grow: 2;
      }
    }
  }

  /*
	.dropdownEditList {
		background: transparent;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;

		input{
			border: none;
			font-family: Averia Next , 'Segoe UI' , sans-serif;
			font-size: 16px;
			min-width: 200px;
			width: 80vw;
			max-width: 425px;
		}

		button{
			width: 25px;
			height: 25px;
			border-radius: 100%;
			border: none;
			background-color: transparent;
			overflow: hidden;
			cursor: pointer;
			background-color: whitesmoke;
			transition: background 0.25s;

			img{
				width: 100%;
				height: 100%;
			}

			&:hover{
				background-color: ${greenS1};
			}
		}
	}
	*/

  .inputEditName {
    width: 100%;
    padding: 9px 8px;
    border-radius: 6px;
    border: 1px solid ${grayS7};
    font-family: "Roboto Regular", "Segoe UI", sans-serif;
    font-size: 13px;
    color: #262626;
    font-weight: 500;
    line-height: 1.1;
    //border: 1px solid red;
  }
`;

// css del componente div texto mensaje
export const GridWarning = styled.div`
  font-family: Avenir Next;
  box-sizing: border-box;
  font-size: 20px;
  color: #603888;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
`;

// css del componente <ContainerGrid />
export const ContainerGrid = styled.div`
  width: 100%;
  flex-grow: 2;
  overflow: auto;
  padding: 2px 20px 2px 0;
  //border: 2px solid green;
`;

// css del componente <GridLists />
export const ContainerGridLists = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  grid-column-gap: 18px;
  grid-row-gap: 22px;
  grid-auto-flow: "row";
  place-items: center;
  //border: 1px solid blue;
  padding-top: 18px;
`;
