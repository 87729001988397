import styled from "styled-components";

const getPercentageColor = (percentage) => {
  if (percentage === 0) return "white";
  if (percentage > 0 && percentage < 33) return "#ED9A4D";
  if (percentage >= 33 && percentage < 66) return "#E57432";
  if (percentage >= 66 && percentage < 100) return "#18A0FB";
  if (percentage === 100) return "#71DE56";
};

export const Container = styled.div`
  width: 40px;
  height: 20px;
  position: relative;
  text-align: center;
  color: #3b1366;
  border-radius: 5px;
  border: 1px solid #3b1366;
  font: normal normal bold 10px/13px Roboto;
  // margin-top: -20px;
  margin-left: 6px;
  background: white;

  &:after {
    border-radius: 5px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${(props) => (props.percentage ? props.percentage : 100)}%;
    /*background: ${({ percentage }) => getPercentageColor(percentage)};*/
  }
  & > p {
    /*color: ${(props) => (props.percentage === 0 ? "#3b1366" : "#3b1366")};*/
    position: relative;
    z-index: 1;
    line-height: 20px;
  }
`;
