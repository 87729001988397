import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import { BotSide, BoxBtn, IconBox } from "./styles";
import bags from "../../../../assets/IconComponents/bags.svg";
import sendToProvider from "../../../../assets/IconComponents/sendToProvider.svg";
import upload from "../../../../assets/IconComponents/upload.svg";
import DownloadXSLTemplate from "../../../../assets/IconComponents/DownloadXSLTemplate.svg";
import { createArticles, addToCart } from "../../../_utils/data";
import GenericModal from "../../../general/GenericModal";
import Loading from "../../../general/loading/index";
import { UploadFileComponent, onLoad, UPCList } from "../utils.js";
import SliderToolTip from "../../../main/SliderToolTip.js";

export default function Bottom(props) {
  const { isRetailer, articles, setArticles, lists } = props;
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [flagsState, setFlagsState] = useState({
    isLoadingCreate: false,
    isLoadingOrder: false,
    isOrder: false,
  });
  const [modalData, setModalData] = useState({
    message: "",
    detail: "",
    img: "",
    customComponent: undefined,
  });

  const canCreateOrder = () => {
    const { is_retailer: isRetailer } = JSON.parse(
      sessionStorage.getItem("user")
    );
    if (isRetailer === 1) return true;
    const { financedRetailers } = JSON.parse(sessionStorage.getItem("company"));
    const idsRetailers = financedRetailers?.map(({ id }) => id) ?? [];
    if (idsRetailers.includes(68) || idsRetailers.includes(74)) return false;
    return true;
  };

  const validate = useCallback(() => {
    let isValid = true;
    articles.every((article) => {
      const { provider, error } = article;
      const keysToValidate = [
        "sku",
        "upc",
        "name",
        "depot",
        "category",
        "type",
      ];
      let tempArray = [];
      const company = JSON.parse(sessionStorage.getItem("company"));
      const userFinanced = company?.hasOwnProperty("financedRetailers");
      const ids = company?.financedRetailers?.map(({ id }) => id) ?? [];
      const isTHDFinanced =
        userFinanced && [58, 59, 60, 61].some((id) => ids?.includes(id));
      keysToValidate.forEach((key) => {
        if (key === "sku") {
          const company = JSON.parse(sessionStorage.getItem("company"));
          const userFinanced = company.hasOwnProperty("financedRetailers");
          if (userFinanced && isTHDFinanced) tempArray.push(article[key] || "");
        } else {
          tempArray.push(article[key] || "");
        }
      });

      if (
        tempArray.includes("") ||
        error ||
        (isRetailer ? provider === "" : provider.length === 0)
      ) {
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  }, [articles, isRetailer]);

  const createOrder = async (res) => {
    const { articlesInserted } = JSON.parse(res.data.body);
    const articleToOrder = [];
    const user = JSON.parse(sessionStorage.getItem("user"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    articlesInserted.forEach((e) => {
      const retailersSelected = isRetailer
        ? company.retailers.map((retailer) => retailer.id)
        : e.data.retailersSelected;
      articleToOrder.push({
        articleId: e.idArticle,
        version: 1,
        userId: user.id_user,
        discount: 0,
        subtotal: 0,
        companyId: user.id_company,
        datasheet: JSON.stringify(retailersSelected),
        description: JSON.stringify(retailersSelected),
        image: JSON.stringify(retailersSelected),
        attributeTranslations: false,
        descriptionTranslations: false,
        build: "[]",
        manual: "[]",
        userCreated: user.id_user,
      });
    });

    const resCart = await addToCart({ data: articleToOrder });
    if (resCart.data.statusCode === 200) {
      setRedirect("checkout");
    }
  };

  const onSubmit = useCallback(
    async (isOrder = false) => {
      const data = articles.map((e) => {
        const article = { ...e };
        article.idCategory = `${e.depot}|${e.category}|${e.type}`;
        article.retailersSelected = isRetailer ? [+e.provider] : e.provider;
        delete article.lists;
        delete article.depot;
        delete article.category;
        delete article.type;
        delete article.provider;
        return article;
      });
      const res = await createArticles({ data });
      const { upcExisting } = JSON.parse(res?.data?.body);
      const { statusCode } = res.data;
      if (statusCode === 404) {
        setModalData({
          message: "Error al crear artículos",
          detail: "Al parecer se excede el limite de usuarios",
        });
        setShowGenericModal(true);
        setFlagsState({
          isLoadingCreate: false,
          isLoadingOrder: false,
          isOrder: false,
        });
        return;
      }
      if (upcExisting?.length > 0) {
        setShowGenericModal(true);
        setModalData({
          message: "Algunos de los UPC ya se encuentran en plataforma",
          detail: "Se insertaron todos los UPC's a excepción de:",
          customComponent: (
            <UPCList
              upcExisting={upcExisting}
              isOrder={isOrder}
              createOrder={() => createOrder(res)}
              setRedirect={setRedirect}
            />
          ),
        });
      } else if (isOrder) {
        createOrder(res);
      }
      return res;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articles, isRetailer]
  );

  const createArticle = useCallback(async () => {
    if (!flagsState.isLoadingCreate) {
      if (validate()) {
        setFlagsState((prev) => ({ ...prev, isLoadingCreate: true }));
        const res = await onSubmit();
        const { upcExisting } = JSON.parse(res.data.body);
        if (upcExisting?.length === 0) setRedirect("products");
      } else {
        setModalData({
          message: "Tienes algunos errores",
          detail:
            "Es probable que necesites completar un campo o que tengas UPC repetidos",
        });
        setShowGenericModal(true);
      }
    }
  }, [flagsState.isLoadingCreate, onSubmit, validate]);

  const onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = async (evt) => {
      const args = {
        evt,
        setArticles,
        lists,
        setShowGenericModal,
        setModalData,
      };
      await onLoad(args);
    };
    reader.readAsBinaryString(file);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const objUploadFile = {
    message: "¿Ya tienes el archivo de plantilla?",
    detail:
      "Completa la información como cada columna lo solicita. Al terminar sube tu archivo en este módulo.  Máx. 100 productos.",
    img: DownloadXSLTemplate,
    customComponent: <UploadFileComponent onChange={onChange} />,
  };

  return (
    <BotSide>
      <IconBox>
        <figure
          onClick={() => {
            const list = Object.values(props.lists?.categories || {});
            if (list.length > 0) {
              setModalData(objUploadFile);
              setShowGenericModal(true);
            }
          }}
        >
          <img src={upload} alt="" />
        </figure>
        <p>
          Sube tus productos múltiples
          <br /> en un <span>archivo csv o xsl</span>
          <br />
          <span>Máx.100 productos.</span>
        </p>
      </IconBox>
      <div>
        <BoxBtn
          onClick={() => {
            if (articles.length === 0) {
              setShowGenericModal(true);
              setModalData({
                message: "Lista de productos vacía",
                detail: "Ingresa al menos un producto para continuar",
                button2: {
                  name: "Aceptar",
                  action: () => setShowGenericModal(false),
                },
              });
            } else {
              createArticle();
            }
          }}
        >
          {flagsState.isLoadingCreate ? (
            <Loading />
          ) : (
            <>
              <figure>
                <img src={sendToProvider} alt="" />
              </figure>
              <div>
                <p>{isRetailer ? "Solicitar a proveedor" : "Crear producto"}</p>
                <p>
                  {isRetailer
                    ? "Envia la solicitud a cada fabricante"
                    : "Añade estos productos a tu lista"}
                </p>
              </div>
            </>
          )}
        </BoxBtn>
        <SliderToolTip />
        {canCreateOrder() ? (
          <BoxBtn
            onClick={() => {
              if (articles.length === 0) {
                setModalData({
                  message: "Lista de productos vacía",
                  detail: "Ingresa al menos un producto para continuar",
                  button2: {
                    name: "Aceptar",
                    action: () => setShowGenericModal(false),
                  },
                });
                setShowGenericModal(true);
              } else {
                if (!flagsState.isLoadingOrder) {
                  if (validate()) {
                    setFlagsState((prev) => ({
                      ...prev,
                      isOrder: true,
                      isLoadingOrder: true,
                    }));
                    onSubmit(true);
                  } else {
                    setModalData({
                      message: "Tienes algunos errores",
                      detail:
                        "Es probable que necesites completar un campo, que tengas UPC repetidos o ya registrados",
                    });
                    setShowGenericModal(true);
                  }
                }
              }
            }}
          >
            {flagsState.isLoadingOrder ? (
              <Loading />
            ) : (
              <>
                <figure>
                  <img src={bags} alt="" />
                </figure>
                <div>
                  <p>Solicitar a Content-oh!</p>
                  <p>Crea la órden de compra</p>
                </div>
              </>
            )}
          </BoxBtn>
        ) : null}
      </div>
      {showGenericModal && (
        <GenericModal close={() => setShowGenericModal(false)} {...modalData} />
      )}
    </BotSide>
  );
}
