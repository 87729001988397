import React, { useEffect, useState } from "react";
import { OrderDetail } from "contentoh-components-library";
import moment from "moment";
import OrdersTable from "./ordersTable/Table";
import { getOrders, getOrderDetail } from "../../_utils/data";
import { isElementClicked } from "../../_utils/helper";
import { MainContainer, MessageContainer } from "./styles";
import {
  markChecked,
  markAll,
  updateSelected,
  estatusOptions,
  filterOrders,
  monthsOptions,
} from "./utils";
import Loading from "../../general/loading";
import { FilterInput } from "../../filterInput/index.js";
import HeaderOrdersL from "../HeaderOrdersL";
import "moment/locale/es";
import axios from "axios";

const OrdersView = (props) => {
  const [ordersList, setOrdersList] = useState([]);
  const [ordersInitialList, setOrdersInitialList] = useState([]);
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [initFetchError, setInitFetchGetError] = useState(false);
  const [orderDetail, setOrderDetail] = useState(false);
  const [orderClicked, setOrderClicked] = useState();
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [filterArray] = useState([
    { name: "Estatus", active: false },
    { name: "Mes", active: false },
  ]);
  const [filtersOptions] = useState({
    Estatus: estatusOptions,
    Mes: monthsOptions,
  });
  const [downloadingDetails, setDownloadingDetails] = useState(false);

  useEffect(() => {
    try {
      const setInitialList = async () => {
        const initList = await getOrders(setInitFetchGetError);
        setOrdersInitialList(initList);
        setOrdersList(initList);
        setLoading(false);
      };
      setInitialList();
      moment.locale("es");
    } catch (err) {
      console.log(err, "Unable to retrieve orders");
    }
  }, []);

  useEffect(() => {
    if (ordersList.length === 0) {
      setResultMessage("No se encontraron órdenes con esas características");
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
    if (initFetchError) {
      setResultMessage("No se pudieron recuperar las ordenes de trabajo");
      setShowMessage(true);
    }
  }, [ordersList, initFetchError]);

  const checkboxClicked = (orderItem, checked) => {
    try {
      setOrdersList(
        markChecked(ordersList.slice(), orderItem.order.id_order, checked)
      );
      setOrdersSelected(
        updateSelected(checked, orderItem, ordersSelected.slice())
      );
    } catch (err) {
      console.log(err, "Unable to update checkbox");
    }
  };

  const checkAll = (checked) => {
    try {
      setOrdersList(markAll(ordersList.slice(), checked));
      if (checked)
        setOrdersSelected(
          ordersList.map((orderItem) => orderItem.order.id_order)
        );
      else {
        setOrdersSelected([]);
      }
    } catch (err) {
      console.log(err, "Unable to mark complete list");
    }
  };

  const rowClicked = (order) => {
    setOrderClicked({ ...order, payment: "pagado" });
  };

  const filterHandler = (filtersObject) => {
    try {
      setShowMessage(false);
      const filters = Object.values(filtersObject);
      const filtersActive = filters.length > 0;
      if (filtersActive)
        filterOrders(filters, setOrdersList, ordersInitialList);
      else
        setOrdersList(
          ordersInitialList.map((item) => {
            item.checked = false;
            return item;
          })
        );
    } catch (err) {
      console.log(err, "Unable to handle filters");
      setResultMessage(
        "Hubo un error al filtrar por esa última característica"
      );
      setShowMessage(true);
    }
  };

  const clickOutsideToClose = (evt) => {
    if (!isElementClicked(evt, "orderDetail")) {
      document.removeEventListener("click", clickOutsideToClose);
      setOrderDetail(false);
      setOrderClicked();
    }
  };

  useEffect(() => {
    if (orderClicked && !orderDetail) {
      setOrderDetail(true);
      document.addEventListener("click", clickOutsideToClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderClicked]);

  const downloadDetails = async () => {
    setDownloadingDetails(true);
    const email = encodeURIComponent(
      JSON.parse(sessionStorage.getItem("user")).email
    );
    const filterForDownload = {
      filterByOrder: [orderClicked.id_order],
    };
    const filtersForQuery = encodeURIComponent(
      JSON.stringify(filterForDownload)
    );
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REPORTS}?email=${email}&filters=${filtersForQuery}&reportType=1`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      if (response.data.statusCode === 200) {
        //console.log(response);
        //More logic here
      }
      setDownloadingDetails(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <MainContainer>
      {orderDetail && (
        <OrderDetail
          id="orderDetail"
          order={orderClicked}
          isDownloading={downloadingDetails}
          download={downloadDetails}
          setShowOrderDetail={setOrderDetail}
          getOrderDetail={getOrderDetail}
          isProvider
        />
      )}
      <HeaderOrdersL />
      <FilterInput
        filterInputFunct={filterHandler}
        total={ordersInitialList.length}
        charged={ordersList.length}
        filterArray={filterArray}
        filtersArray={filtersOptions}
        mainFilter="Orden"
        hideButtons
      />
      {loading && <Loading />}
      {showMessage ? (
        <MessageContainer>{resultMessage}</MessageContainer>
      ) : (
        <OrdersTable
          clickEvents={{ checkboxClicked, checkAll, rowClicked }}
          ordersList={ordersList}
        />
      )}
    </MainContainer>
  );
};

export default OrdersView;
