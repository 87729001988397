import { useEffect, useState } from "react";
import { CustomSelect } from "contentoh-components-library";
import { ContainerCustomModal } from "./styles";

const RetailerComponentModal = (props) => {
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [optionsGeneral, setOptionsGeneral] = useState([]);

  useEffect(() => {
    setOptionsSelected(props.selected);
    sessionStorage.setItem("retailersSelected", JSON.stringify(props.selected));
    const options = Object.entries(props.options).map(([id, name]) => ({
      id,
      name,
      isSelected: props.selected.includes(id),
    }));
    setOptionsGeneral(options);
  }, [props]);

  const setSelected = (data) => {
    setOptionsSelected(data);
    sessionStorage.setItem("retailersSelected", JSON.stringify(data));
  };

  const removeOption = (index) => {
    let temp = optionsSelected.slice();
    temp.splice(index, 1);
    setOptionsSelected(temp);
    sessionStorage.setItem("retailersSelected", JSON.stringify(temp));
  };

  return (
    <ContainerCustomModal>
      <p>Se agregarán a tus productos seleccionados.</p>
      <ul>
        {optionsSelected.map((id, index) => (
          <li key={id}>
            <span>{props.options[id]}</span>
            <span
              onClick={() => removeOption(index)}
              className="material-icons-outlined delete"
            >
              delete_forever
            </span>
          </li>
        ))}
      </ul>
      <CustomSelect
        selectLabel="Agregar cadena"
        options={optionsGeneral}
        placeHolder="Buscar cadena"
        customSelectId="retailer-select"
        showFilterString
        showSearchBar
        parameterArray={optionsSelected}
        setParameterArray={setSelected}
      />
    </ContainerCustomModal>
  );
};

export default RetailerComponentModal;
