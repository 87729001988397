import React, { useState, useRef, useEffect } from "react";
import { Container, PriorityFlag, FlagsList, FlagOption } from "./styles";
import { getPrio, priorityOptions } from "../../helper";
import { updatePriority } from "../../data";

export default function Priority(props) {
  const wrapperRef = useRef(null);
  const { prio, articleId } = props.prio;
  const [isOpen, setIsOpen] = useState(false);

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutsidePriority(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutsidePriority);
      return () => {
        document.removeEventListener("mousedown", handleClickOutsidePriority);
      };
    }, [ref]);
  }

  const changePriority = async (newPriority) => {
    updatePriority({
      articleId: articleId,
      newStatus: newPriority,
    }).then((r) => {
      setIsOpen(false);
      props.setList({
        type: "updatePriority",
        index: props.index,
        value: newPriority,
      });
    });
  };

  useOutside(wrapperRef);

  return (
    <Container onClick={(evt) => evt.stopPropagation()}>
      <PriorityFlag onClick={() => setIsOpen(!isOpen)}>
        <img src={getPrio(prio)} alt="" />
      </PriorityFlag>
      {isOpen && (
        <FlagsList ref={wrapperRef}>
          {prio && (
            <FlagOption>
              <PriorityFlag>
                <img src={getPrio(prio)} alt="" />
              </PriorityFlag>
              {priorityOptions.find((f) => f.value === prio).name}
            </FlagOption>
          )}
          <div>
            {priorityOptions.map(
              (e, i) =>
                e.value !== prio && (
                  <FlagOption onClick={() => changePriority(e.value)} key={i}>
                    <PriorityFlag>
                      <img src={getPrio(e.value)} alt="" />
                    </PriorityFlag>
                    {e.name}
                  </FlagOption>
                )
            )}
          </div>
        </FlagsList>
      )}
    </Container>
  );
}
