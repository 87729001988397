import React, { useState, useEffect } from "react";
import {
  MidSide,
  Column,
  Row,
  RetailersBtn,
  RetailersDiv,
  AlertError,
} from "./styles";
import addProductActive from "../../../../assets/IconComponents/addProductActive.svg";
import addProduct from "../../../../assets/IconComponents/addProduct.svg";
import deleteIcon from "../../../../assets/IconComponents/deleteGrayCircle.svg";
import Input from "../../../customInputs/Input/index";
import Select from "../../../customInputs/Select/index.js";
import LabelToInput from "../../../customInputs/LabelToInput";
import PopUpEditRetailers from "../../../general/PopUpEditRetailers";
import { checkDuplicateUPC } from "../../../_utils/data";
import { validateUPC } from "../utils";
import { getUniqueCodeLabel } from "../../../_utils/helper";
import { useCloseModal } from "../../../../hooks/useCloseModal";

export default function Mid(props) {
  const { isRetailer, newArticle, articles, lists } = props;
  const [flagsObject, setFlagsObject] = useState({
    popUpRetailer: null,
    canAdd: false,
  });
  const [isUserFinanced] = useState(
    JSON.parse(sessionStorage.getItem("company")).hasOwnProperty(
      "financedRetailers"
    )
  );
  const [isTHDFinanced, setIsTHDFinanced] = useState(false);
  const [isFirstExecution, setIsFirstExecution] = useState(true);
  const [alertUpc, setAlertUpc] = useState(false);
  const [showPopUpRetailers, setShowPopUpRetailers] =
    useCloseModal("popUpRetailers");

  const dataUpc = props.newArticle.upc;
  const dataProv = props.newArticle.provider;
  let params = {
    upc: dataUpc,
  };

  useEffect(() => {
    const { sku, upc, name, depot, category, type, provider } = newArticle;
    const validProvider = isRetailer ? provider !== "" : provider?.length > 0;
    const valid = isUserFinanced && isTHDFinanced ? sku : false;
    const condition = [valid, upc, name, depot, category, type].includes("");
    if (condition || validProvider === false) {
      setFlagsObject((prev) => ({ ...prev, canAdd: false }));
    } else {
      setFlagsObject((prev) => ({ ...prev, canAdd: true }));
    }
    if (isFirstExecution && isUserFinanced) {
      const ids = JSON.parse(sessionStorage.getItem("company"))?.retailers?.map(
        ({ id }) => id
      );
      const isTHDFinanced = [58, 59, 60, 61].some((id) => ids?.includes(id));
      if (isTHDFinanced) setIsTHDFinanced(true);
      setIsFirstExecution(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newArticle, isRetailer, isTHDFinanced]);

  const updateCadena = (evt, id, index = undefined) => {
    let { provider } = index !== undefined ? articles[index] : newArticle;
    if (evt.target.checked) {
      provider.push(id);
    } else {
      provider = provider.filter((f) => f !== id);
    }
    if (index === undefined)
      props.setNewArticle((prev) => ({ ...prev, provider }));
    else {
      props.setArticles({
        index: index,
        action: "provider",
        value: provider,
      });
    }
  };

  const addTable = async () => {
    let error = undefined;
    if (await checkDuplicateUPC(params)) {
      error = true;
      setAlertUpc(true);
      props.setNewArticle((prev) => ({
        ...prev,
        upc: dataUpc,
        company: dataProv,
        error,
      }));
    } else {
      if (flagsObject.canAdd) {
        setShowPopUpRetailers(false);
        props.setArticles({
          action: "newArticle",
          value: {
            ...newArticle,
            lists: {
              depot: lists.depot,
              category: lists.category,
              type: lists.type,
            },
          },
        });
        props.setNewArticle({
          sku: "",
          upc: "",
          name: "",
          depot: "",
          category: "",
          type: "",
          provider: isRetailer ? lists.providers[0].value : [],
        });
        props.setLists((prev) => ({
          ...prev,
          category: [],
          type: [],
        }));
        props.changeSelects("depot", lists.depot[0]);
        setFlagsObject((prev) => ({ ...prev, canAdd: false }));
      }
      setAlertUpc(false);
    }
  };
  const checkUpcInTable = (upcValue) => {
    if (isRetailer) {
      params.company = dataProv;
      if (articles.find((f) => f.upc === upcValue && f.provider === dataProv)) {
        return true;
      }
    } else if (articles.find((f) => f.upc === upcValue)) {
      return true;
    }
    return false;
  };
  const hasProducts = props.articles.length > 0;

  return (
    <MidSide hasProducts={hasProducts}>
      <div className="header">
        {isTHDFinanced && (
          <Column minWidth="130px" width="9%">
            SKU
          </Column>
        )}
        <Column minWidth="130px" width="10%">
          UPC
        </Column>
        <Column minWidth="130px" width="12%">
          Nombre
        </Column>
        <Column minWidth="185px" width="14%">
          Departamento
        </Column>
        <Column minWidth="250px" width="20%">
          Categoría
        </Column>
        <Column minWidth="320px" width="23%">
          Tipo de producto
        </Column>
        <Column minWidth="100px" width="9%">
          {isRetailer ? "Proveedor" : "Cadenas"}
        </Column>
        <Column minWidth="20px" width="3%" />
      </div>
      <div className="mid">
        {articles.map((item, i) => (
          <Row key={i}>
            {isTHDFinanced && (
              <Column minWidth="130px" width="9%">
                <LabelToInput
                  width="90%"
                  defaultValue={item.sku}
                  onChange={(e) => {
                    props.setArticles({
                      index: i,
                      action: "sku",
                      value: e.target.value,
                    });
                  }}
                />
              </Column>
            )}
            <Column minWidth="130px" width="10%">
              <LabelToInput
                width="90%"
                defaultValue={item.upc}
                error={item.error}
                blurFunction={(e) => {
                  let parameters = {
                    valueUpc: e.target.value,
                    valueCompany: item.provider,
                    isRetailer: isRetailer,
                    alertDuplicate: props.setArticles,
                    index: i,
                  };
                  validateUPC(parameters);
                }}
              />
            </Column>
            <Column minWidth="130px" width="12%">
              <LabelToInput
                width="90%"
                defaultValue={item.name}
                onChange={(e) => {
                  props.setArticles({
                    index: i,
                    action: "name",
                    value: e.target.value,
                  });
                }}
              />
            </Column>
            <Column minWidth="185px" width="14%">
              <Select
                width="95%"
                placeholder="Departamento"
                options={item.lists?.depot}
                valueSelected={item.depot}
                onChange={(e) => {
                  props.changeSelects("depot", e.target.value, {
                    depot: e.target.value,
                    index: i,
                  });
                }}
              />
            </Column>
            <Column minWidth="250px" width="20%">
              <Select
                width="95%"
                placeholder="Categoría"
                options={item.lists?.category}
                valueSelected={item.category}
                onChange={(e) => {
                  props.changeSelects("category", e.target.value, {
                    depot: item.depot,
                    index: i,
                  });
                }}
              />
            </Column>
            <Column minWidth="320px" width="23%">
              <Select
                width="95%"
                placeholder="Tipo de producto"
                options={item.lists?.type}
                valueSelected={item.type}
                onChange={(e) => {
                  props.changeSelects("type", e.target.value, {
                    depot: item.depot,
                    index: i,
                  });
                }}
              />
            </Column>
            <Column minWidth="100px" width="9%">
              {isRetailer ? (
                <Select
                  width="100%"
                  placeholder={isRetailer ? "Proveedor" : "Cadenas"}
                  options={lists.providers}
                  valueSelected={item.provider}
                  onChange={(e) => {
                    props.setArticles({
                      action: "provider",
                      index: i,
                      value: e.target.value,
                    });
                  }}
                />
              ) : (
                <RetailersDiv>
                  <RetailersBtn
                    color="#817393"
                    onClick={() => {
                      setFlagsObject((prev) => ({
                        ...prev,
                        popUpRetailer: prev.popUpRetailer === i ? null : i,
                      }));
                      setShowPopUpRetailers(false);
                    }}
                  >
                    <p>{item.provider?.length}</p>
                  </RetailersBtn>
                  {flagsObject.popUpRetailer === i && (
                    <PopUpEditRetailers
                      row={i}
                      className="chk-inputs"
                      category={`${item.depot}|${item.category}|${item.type}`}
                      retailers={item.provider}
                      updateCadena={(evt, id) => updateCadena(evt, id, i)}
                      prefix="edit"
                    />
                  )}
                </RetailersDiv>
              )}
            </Column>
            <Column minWidth="20px" width="3%">
              <figure
                onClick={() => {
                  props.setArticles({
                    index: i,
                    action: "delete",
                  });
                }}
              >
                <img src={deleteIcon} alt="Eliminar elemento" />
              </figure>
            </Column>
          </Row>
        ))}
      </div>
      <div className="bottom">
        {isTHDFinanced && (
          <Input
            id="sku"
            width="10%"
            placeholder="SKU"
            value={newArticle.sku}
            onChange={(e) => {
              props.setNewArticle((prev) => ({ ...prev, sku: e.target.value }));
            }}
          />
        )}
        <Input
          id="upc"
          width="10%"
          placeholder={getUniqueCodeLabel()}
          value={newArticle.upc}
          error={newArticle.error}
          onBlur={(e) => {
            let error = undefined;
            if (checkUpcInTable(e.target.value)) {
              error = true;
              setAlertUpc(true);
              props.setNewArticle((prev) => ({
                ...prev,
                upc: dataUpc,
                company: dataProv,
                error,
              }));
            } else {
              let parameters = {
                valueUpc: e.target.value,
                valueCompany: dataProv,
                isRetailer: isRetailer,
                alertDuplicate: setAlertUpc,
                setNewArticle: props.setNewArticle,
              };
              validateUPC(parameters);
            }
          }}
          onChange={(e) => {
            let error = undefined;
            setAlertUpc(false);
            if (checkUpcInTable(e.target.value)) {
              error = true;
              setAlertUpc(true);
            }
            props.setNewArticle((prev) => ({
              ...prev,
              upc: e.target.value,
              company: dataProv,
              error,
            }));
          }}
        />
        <Input
          id="name"
          width="10%"
          placeholder="Nombre"
          value={newArticle.name}
          onChange={(e) => {
            props.setNewArticle((prev) => ({ ...prev, name: e.target.value }));
          }}
        />
        <Select
          id="depot"
          width="150px"
          placeholder="Departamento"
          options={lists.depot}
          valueSelected={newArticle.depot}
          onChange={(e) => {
            props.changeSelects("depot", e.target.value);
          }}
        />
        <Select
          id="category"
          width="200px"
          placeholder="Categoría"
          options={lists.category}
          valueSelected={newArticle.category}
          onChange={(e) => {
            props.changeSelects("category", e.target.value);
          }}
        />
        <Select
          id="type"
          width="250px"
          placeholder="Tipo de producto"
          options={lists.type}
          valueSelected={newArticle.type}
          onChange={(e) => {
            props.changeSelects("type", e.target.value);
          }}
        />
        {isRetailer ? (
          <Select
            width="90px"
            placeholder="Proveedor"
            options={lists.providers}
            valueSelected={newArticle.provider}
            onChange={(e) => {
              props.setNewArticle((prev) => ({
                ...prev,
                provider: e.target.value,
              }));
            }}
          />
        ) : (
          <RetailersDiv id="popUpRetailers">
            <RetailersBtn
              onClick={() => setShowPopUpRetailers(!showPopUpRetailers)}
            >
              <p>Cadenas</p>
            </RetailersBtn>
            {showPopUpRetailers && (
              <PopUpEditRetailers
                className="chk-inputs"
                category={`${newArticle.depot}|${newArticle.category}|${newArticle.type}`}
                retailers={newArticle.provider}
                updateCadena={updateCadena}
                prefix="new"
              />
            )}
          </RetailersDiv>
        )}
        <figure
          onClick={() => {
            if (checkUpcInTable(dataUpc)) setAlertUpc(true);
            else addTable();
          }}
        >
          <img
            src={flagsObject.canAdd ? addProductActive : addProduct}
            alt="Agregar producto"
          />
        </figure>
      </div>
      {alertUpc && (
        <AlertError>
          <label>El UPC ya existe</label>
        </AlertError>
      )}
    </MidSide>
  );
}
