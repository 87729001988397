import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.width ? props.width : "150px")};
  height: 20px;
  padding: 0 5px;
  box-sizing: border-box;
  border-radius: 2px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  & > p {
    color: #000;
    min-height: 20px;
    min-width: 70px;
    background: #fafafa;
  }
  border: ${(props) => props.error && "1px solid red"};
`;
