import { InviteUsersContainer } from "./styles";
import { CustomSelect } from "contentoh-components-library";
import { useState } from "react";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Modal } from "contentoh-components-library/dist/components/organisms/Modal";
import { ButtonV2 } from "contentoh-components-library/dist/components/atoms/ButtonV2";

export const InviteUser = ({
  acces,
  categories,
  setShowInvite,
  onSubmit,
  inviteProviders,
  loadData,
  showParams,
  inviteMoreUsers,
}) => {
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [accesSelected, setAccesSelected] = useState([]);
  const [email, setEmail] = useState("");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [providersToInvite, setProvidersToInvite] = useState([
    { email: "", userName: "" },
  ]);
  const [usersToInvite, setUsersToInvite] = useState([
    { email: "", userName: "" },
  ]);
  const [position, setPosition] = useState("");
  const [modalData, setModalData] = useState({
    show: false,
    title: "Actualización completa",
    message: "",
    icon: "success",
  });

  const sendInvitation = async () => {
    if (user.is_retailer && inviteProviders) {
      const body = {
        emailToInvite: encodeURIComponent(JSON.stringify(providersToInvite)),
      };
      console.log("Invite user request")
      await axios.post(`${process.env.REACT_APP_INVITE_USER}`, body, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      loadData();
    } else if (inviteMoreUsers) {
      const body = {
        emailToInvite: `[${usersToInvite.map((user) => `{"email":"${user.email}"}`).join(",")}]`,
      };
      await onSubmit(body);
    } else {
      await onSubmit(usersToInvite[0].email, categoriesSelected, position);
    }
    setShowInvite(false);
  };

  const emailOnChange = (e) => {
    setEmail(e.target.value);
  };

  const changePosition = (e) => {
    setPosition(e.target.value);
  };

  const invitationOnChange = (e, field, id, isProvider) => {
    const invitations = isProvider
      ? providersToInvite.slice()
      : usersToInvite.slice();
    invitations[id][field] = e.target.value;
    isProvider
      ? setProvidersToInvite(invitations)
      : setUsersToInvite(invitations);
  };

  return (
    <InviteUsersContainer>
      <div className="form-container">
        <p>Invitar Usuarios</p>
        {inviteProviders ? (
          <div className="invitation-container">
            {providersToInvite.map((provider, index) => (
              <div className="invite-provider" key={index}>
                <input
                  value={provider.email}
                  type="email"
                  placeholder="ejemplo@correo.com"
                  onChange={(e) => invitationOnChange(e, "email", index, true)}
                />
                <input
                  value={provider.userName}
                  type="text"
                  placeholder="Nombre"
                  onChange={(e) =>
                    invitationOnChange(e, "userName", index, true)
                  }
                />
              </div>
            ))}
            <button
              className="add-invitation"
              onClick={() =>
                setProvidersToInvite((current) => [
                  ...current,
                  { email: "", userName: "" },
                ])
              }
            >
              + Añadir otra invitación
            </button>
          </div>
        ) : (
          <>
            {inviteMoreUsers ? (
              <div className="email-container">
                {usersToInvite.map((user, index) => (
                  <div key={index} className="invite-user">
                    <Input
                      sx={{
                        m: "8px 0",
                        width: "100%",
                        height: "30px",
                        border: "1px solid #F0F0F0",
                        borderRadius: "5px",
                        p: "0px 10px",
                        "&.MuiInput-underline:before, &.Mui-error:before, &.MuiInput-underline:hover":
                          {
                            borderBottom: "none",
                            border: "none",
                          },
                        "&.MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "1px solid #F0F0F0",
                            borderRadius: "5px",
                            height: "30px",
                            p: "0px 10px",
                          },
                        "&.MuiInput-underline:after": {
                          borderBottom: "1px solid #E33AA9",
                        },
                        "&::placeholder": {
                          fontSize: "12px",
                        },
                      }}
                      placeholder="Correo electrónico"
                      value={user.email}
                      type="email"
                      onChange={(e) =>
                        invitationOnChange(e, "email", index, false)
                      }
                      startAdornment={
                        <InputAdornment
                          position="start"
                          sx={{ border: "none" }}
                        >
                          <MailOutlineIcon
                            sx={{ color: "#CBCBCB", fontSize: "14px" }}
                          />
                        </InputAdornment>
                      }
                    />
                  </div>
                ))}
                <button
                  className="add-invitation"
                  onClick={() =>
                    setUsersToInvite((current) => [
                      ...current,
                      { email: "", userName: "" },
                    ])
                  }
                >
                  + Añadir otra invitación
                </button>
              </div>
            ):(
              <div className="email-container">
                {usersToInvite.map((user, index) => (
                  <div key={index} className="invite-user">
                    <Input
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        height: "30px",
                        border: "1px solid #F0F0F0",
                        borderRadius: "5px",
                        p: "0px 10px",
                        "&.MuiInput-underline:before, &.Mui-error:before, &.MuiInput-underline:hover":
                          {
                            borderBottom: "none",
                            border: "none",
                          },
                        "&.MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "1px solid #F0F0F0",
                            borderRadius: "5px",
                            height: "30px",
                            p: "0px 10px",
                          },
                        "&.MuiInput-underline:after": {
                          borderBottom: "1px solid #E33AA9",
                        },
                        "&::placeholder": {
                          fontSize: "12px",
                        },
                      }}
                      placeholder="Correo electrónico"
                      value={user.email}
                      type="email"
                      onChange={(e) =>
                        invitationOnChange(e, "email", index, false)
                      }
                      startAdornment={
                        <InputAdornment
                          position="start"
                          sx={{ border: "none" }}
                        >
                          <MailOutlineIcon
                            sx={{ color: "#CBCBCB", fontSize: "14px" }}
                          />
                        </InputAdornment>
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        <div className="inputs-container">
          {acces && (
            <div className="input-container">
              <p className="label">Acceso</p>
              <CustomSelect
                options={acces}
                showSearchBar={true}
                placeHolder={"Buscar accesos"}
                selectLabel={"Accesos"}
                customSelectId={"acces-select"}
                defaultOption={"Todos los Accesos"}
                parameterArray={accesSelected}
                setParameterArray={setAccesSelected}
              />
            </div>
          )}
          {categories && (
            <div className="input-container">
              <p className="label">Puesto</p>
              <input
                type="text"
                placeholder="Puesto"
                onChange={changePosition}
              />
            </div>
          )}
          {categories && (
            <div className="input-container">
              <p className="label">Departamento</p>
              <CustomSelect
                options={categories}
                showSearchBar={true}
                placeHolder={"Buscar departamento"}
                selectLabel={"Departamentos"}
                customSelectId={"category-select"}
                defaultOption={"Todos los departamentos"}
                parameterArray={categoriesSelected}
                setParameterArray={setCategoriesSelected}
              />
            </div>
          )}
        </div>
        <div className="buttons-container">
          <button
            className="cancel-button"
            onClick={() => setShowInvite && setShowInvite(false)}
          >
            Cancelar
          </button>
          <button className="invite-button" onClick={() => sendInvitation()}>
            Invitar
          </button>
        </div>
      </div>
      <Modal
          {...modalData}
          onClickBtnDefault={() =>
            setModalData((prev) => ({ ...prev, show: false }))
          }
        ></Modal>
    </InviteUsersContainer>
  );
};
