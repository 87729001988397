import { getUniqueCodeLabel } from "../../../../_utils/helper";
export const tableTemplate = [
  {
    header: "checkbox",
    styles: {
      minWidth: "20px",
      centered: true,
      component: "checkbox",
    },
    attributes: {
      type: "checkbox",
    },
    data: "checkbox",
    path: "checked",
  },
  {
    header: "Proveedor",
    styles: {
      minWidth: "35px",
      centered: true,
    },
    data: "company",
    path: "article.company_name",
  },
  {
    header: getUniqueCodeLabel(),
    styles: {
      minWidth: "30px",
      centered: true,
    },
    data: "upc",
    path: "article.upc",
  },
  {
    header: "Categoría",
    styles: {
      minWidth: "34px",
      centered: true,
      component: "category",
    },
    data: "category",
    path: "article.category",
  },
  {
    header: "Estat.",
    styles: {
      minWidth: "15px",
      display: "flex",
      justifyContentn: "center",
      overflow: "none",
      noClick: true,
    },
    data: "status",
    path: "article_status",
  },
  {
    header: "Ver.",
    styles: {
      minWidth: "10px",
      centered: true,
    },
    data: "version",
    path: "version",
  },
  {
    header: "Val.",
    styles: {
      minWidth: "15px",
      centered: true,
      noClick: true,
      withoutOverflow: true,
    },
    data: "valid",
    path: "article_status",
  },
  {
    header: "Datos",
    minWidth: "55px",
    centered: true,
    component: "attribute",
    path: "missing.datasheet",
    data: "missingData",
  },
  {
    header: "Desc.",
    minWidth: "55px",
    centered: true,
    component: "description",
    path: "missing.descriptions",
    data: "missingData",
  },
  {
    header: "Imgs.",
    minWidth: "55px",
    centered: true,
    component: "image",
    path: "missing.images",
    data: "missingData",
  },
  {
    header: "%",
    minWidth: "60px",
    centered: true,
    component: "",
    path: "percentage",
    data: "percentage",
  },
];
