import { useState, useCallback } from "react";
import { Button, Modal, ButtonV2 } from "contentoh-components-library";
import { Container, Center, Menu } from "./styles";
import RetailerComponentModal from "./RetailerComponentModal.js";
import ServiceComponentModal from "./ServiceComponentModal";
import { updateToCart } from "../../_utils/data";
import edit from "../../../assets/IconCheckout/edit.svg";

const DropdownButton = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [modalData, setModalData] = useState({
    show: false,
    title: "",
    message: "",
  });

  const close = useCallback(() => {
    setShowOptions((prev) => !prev);
    setModalData((prev) => ({ ...prev, show: false }));
    sessionStorage.removeItem("retailersSelected");
    sessionStorage.removeItem("serviceSelected");
  }, []);

  const updateRetailers = async () => {
    setModalData({
      show: true,
      title: "Actualizando...",
      message: "Esta acción puede demorar unos segundos...",
      buttons: [<></>],
    });
    const articles = props.articlesSelected.map((id) => Number(id));
    const retailers = JSON.parse(
      sessionStorage.getItem("retailersSelected")
    ).map((id) => Number(id));
    const data = { retailers, articles };
    const response = await updateToCart({ action: "updateRetailers", data });
    if (response?.data?.statusCode === 200) {
      const { message } = JSON.parse(response?.data?.body);
      setModalData({
        show: true,
        title: "Se actualizó con exito",
        icon: "success",
        message,
        onClickBtnDefault: close,
      });
      props.setReload((prev) => (prev += 1));
    } else {
      setModalData({
        show: true,
        title: "Algo salio mal",
        icon: "error",
        message: "Puedes reportar esto con el equipo de TI",
        onClickBtnDefault: close,
      });
    }
  };

  const updateService = async () => {
    setModalData({
      show: true,
      title: "Actualizando...",
      message: "Esta acción puede demorar unos segundos...",
      buttons: [<></>],
    });
    const articles = props.articlesSelected.map((id) => Number(id));
    const services = JSON.parse(sessionStorage.getItem("serviceSelected"));
    const data = { articles, services };
    const response = await updateToCart({
      action: "updateArticleService",
      data,
    });
    if (response?.data?.statusCode === 200) {
      const { message } = JSON.parse(response?.data?.body);
      setModalData({
        show: true,
        title: "Se actualizó con exito",
        icon: "success",
        message,
        onClickBtnDefault: close,
      });
      props.setReload((prev) => (prev += 1));
    } else {
      setModalData({
        show: true,
        title: "Algo salio mal",
        icon: "error",
        message: "Puedes reportar esto con el equipo de TI",
        onClickBtnDefault: close,
      });
    }
  };

  const setDataToModal = (action) => {
    const data = {
      show: true,
      buttons: [
        <ButtonV2
          key="btn-Cancelar"
          type="white"
          label="Cancelar"
          size={12}
          onClick={close}
        />,
      ],
    };
    const btn2Base = {
      key: "btn-Aceptar",
      type: "pink",
      label: "Aceptar",
      size: 12,
    };
    if (action === "retailer") {
      data.title = "Agregar cadenas";
      data.customComponent = (
        <RetailerComponentModal
          selected={props.retailersSelected}
          options={props.retailers}
        />
      );
      data.buttons.push(<ButtonV2 {...btn2Base} onClick={updateRetailers} />);
    } else {
      data.title = "Agregar servicios";
      data.customComponent = <ServiceComponentModal />;
      data.buttons.push(<ButtonV2 {...btn2Base} onClick={updateService} />);
    }
    setModalData(data);
  };

  return (
    <Container>
      <Button
        buttonType="general-transparent-button"
        onClick={() => setShowOptions((prev) => !prev)}
        label={
          <Center>
            <img src={edit} alt="Editar" />
          </Center>
        }
      />
      {showOptions ? (
        <Menu>
          <li onClick={() => setDataToModal("service")}>
            Actualizar Servicios
          </li>
          <li onClick={() => setDataToModal("retailer")}>Actualizar Cadenas</li>
        </Menu>
      ) : null}
      <Modal {...modalData} />
    </Container>
  );
};

export default DropdownButton;
