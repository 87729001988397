import { MetricsBar } from "./MetricsBar/index.js";
import ContentohTable from "./Table.js";
import { useState, useEffect } from "react";
import ProductDetail from "../../products/productDetail/index.js";
import { FilterInput } from "../../filterInput/index.js";
import { getContentohProducts } from "../../_utils/data.js";
import { setFilters, filterProducts } from "./utils";
import { rowClicked } from "./tableEvents.js";
import { ContentohProductsContainer } from "./styles";
import Loading from "../../general/loading";
import { GridProducts } from "contentoh-components-library";
import {
  getProductsToTable,
  chkOnChange,
  chkChecked,
  checkAll,
  chkCheckedAll,
  renderColumns,
} from "./ContentohProductsGridUtils";

export const ContentohProducts = () => {
  const [sidebool, setSidebool] = useState(false);
  const [productSelected, setProductSelected] = useState({});
  const [productsSelected, setProductsSelected] = useState([]);
  const [addedToCart, setAddedToCart] = useState(false);
  const [addedPopUp, setAddedPopUp] = useState(null);
  const [modalClass, setModalClass] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [initialList, setInitialList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtersArray, setFiltersArray] = useState({});
  const [filterArray, setFilterArray] = useState([]);
  const [gridView, setGridView] = useState(true);
  const [gridProducts, setGridProducts] = useState([]);
  const [images, setImages] = useState({});

  const closeModal = (e) => {
    if (
      (!e.target.closest("#product-detail") && sidebool) ||
      e.target.closest(".close-button")
    ) {
      setModalClass("");
      document.removeEventListener("click", closeModal, false);
      setTimeout(() => setSidebool(false), 500);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      const getInitialProducts = async () => {
        const { products, images } = await getContentohProducts();
        setImages(images);
        setProductsList(products);
        setInitialList(products);
        setFilters(products, setFilterArray, setFiltersArray);
        setLoading(false);
      };
      getInitialProducts();
    } catch (err) {
      console.log(err, "Unable to set initial products");
    }
  }, []);

  useEffect(() => {
    if (sidebool) {
      setTimeout(() => {
        setModalClass("modal-active");
        document.addEventListener("click", closeModal, false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebool]);

  useEffect(() => {
    const products = getProductsToTable(productsList, images);
    setGridProducts(products);
  }, [productsList, images]);

  const filterInputFunct = async (filtersObject) => {
    const filters = Object.values(filtersObject);
    const filtersActive = filters.length > 0;
    if (filtersActive) filterProducts(filters, setProductsList, initialList);
    else
      setProductsList(
        initialList.map((item) => {
          item.checked = false;
          return item;
        })
      );
  };

  return (
    <ContentohProductsContainer>
      {addedToCart && addedPopUp}
      <MetricsBar />
      <div className="container-grid">
        <FilterInput
          total={initialList?.length}
          charged={productsList?.length}
          filterArray={filterArray}
          className={"filter-input"}
          filtersArray={filtersArray}
          loadMoreProducts={false}
          filterInputFunct={filterInputFunct}
          gridView={gridView}
          setGridView={setGridView}
          hideExportButton
        />
        {loading ? (
          <Loading />
        ) : (
          <GridProducts
            products={gridProducts}
            gridView={gridView}
            selected={productSelected}
            columnsArray={renderColumns()}
            chkOnChange={(e, product) =>
              chkOnChange(e, product, productsSelected, setProductsSelected)
            }
            chkChecked={(product) => {
              return chkChecked(product, productsSelected);
            }}
            checkAll={(e) => checkAll(e, setProductsSelected, productsList)}
            chkCheckedAll={(e) => {
              return chkCheckedAll(productsSelected, productsList);
            }}
            onGridClick={(e, product) => {
              rowClicked(product, setProductSelected, setSidebool);
            }}
          />
        )}
      </div>

      {sidebool && (
        <ProductDetail
          productSelected={productSelected}
          setAddedToCart={setAddedToCart}
          setAddedPopUp={setAddedPopUp}
          className={modalClass}
          setModalClass={setModalClass}
          setSidebool={setSidebool}
        />
      )}
    </ContentohProductsContainer>
  );
};
