import styled from "styled-components";

export const RequestModal = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(59, 59, 59, 0.53);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(4px);
  z-index: 10;

  &.modal-number {
    min-height: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  p {
    width: 90%;
    padding: 10px;
  }
`;
export const ModalContainerProduct = styled.div`
  box-sizing: border-box;
  background-color: #503d66;
  max-width: 638px;
  height: fit-content;
  border-radius: 30px;
  padding: 35px 52px 83px 52px;
  position: relative;
  .donut {
    margin: 0 auto;
    position: relative;
    width: 152px;
    height: 152px;
  }
  .instalment4 .donut-case {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #d74ded;
    position: absolute;
    top: 0;
    left: 0;
    background-clip: border-box;
    overflow: hidden;
  }

  .donut-text {
    top: 16px;
    left: 16px;
    width: 118px;
    height: 118px;
    background: #503d66;
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .donut-text span {
    color: #48b2c1;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 96px;
    line-height: 131px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ededed;
  }
`;
export const ModalContainer = styled.div`
  box-sizing: border-box;
  background-color: #503d66;
  max-width: 638px;
  height: fit-content;
  border-radius: 30px;
  padding: 44px 52px 35px;
  position: relative;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 47px;
  top: 54px;
  .close{
    cursor:pointer;
  }

  button {
    border: 1px solid #ededed;
    background-color: transparent;
    color: #ededed;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
  }
  button::before,
  button::after {
    right: 3px;
    position: absolute;
    content: "";
    width: 80%;
    height: 1px; /* cross thickness */
    background-color: #ededed;
  }

  button::before {
    transform: rotate(45deg);
  }

  button::after {
    transform: rotate(-45deg);
  }
`;

export const ModalImage = styled.div`
  width: 100%;
  .image-container {
    width: 178px;
    margin: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
`;
export const TitleProduct = styled.div`
  margin-top: 15px;
  margin: auto;

  color: #fbfbfb;
  font-family: Raleway;
  font-style: normal;
  h2 {
    margin-top: 15px;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    font-weight: normal;
    line-height: 43px;
    span {
      font-weight: 600;
    }
    & + p {
      margin-top: 11px;
    }
  }
  p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
  }
`;
export const Title = styled.div`
  max-width: 480px;
  margin: auto;
  color: #fbfbfb;
  font-family: Raleway;
  font-style: normal;
  h2 {
    font-size: 36px;
    text-align: center;
    margin: 0;
    font-weight: normal;
    line-height: 43px;
    span {
      font-weight: 600;
    }
    & + p {
      margin-top: 16px;
    }
  }
  p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
  }
`;
export const FormActiveContainerProduct = styled.div`
  form {
    padding-top: 5px;
    p {
      margin-bottom: 5px;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #cdcdcd;

      & + .checkbox-container {
        margin-top: 10px;
      }
    }
  }
`;

export const CheckboxContainer = styled.div`
  background-color: #6a5880;
  border-radius: 7px;
  max-height: 150px;
  overflow-y: auto;
  &.active-options {
    max-width: 317px;
    margin: auto;
    padding: 9px 15px;
    display: flex;
    justify-content: space-between;
    & + p {
      margin-top: 14px;
    }
  }
  &.linked-companies {
    padding: 16px 20px;
    display: flex;
    flex-wrap: wrap;
    label {
      display: block;
      flex: 25%;
      & + label {
        margin-top: 5px;
      }
      &:nth-child(-n + 4) {
        margin-top: 0;
      }
    }
    & + * {
      margin-top: 33px;
    }
  }
`;

export const Label = styled.label`
  color: #fafafa;
  font-family: Lato;
  font-size: 11px;
  line-height: 21px;
  position: relative;
  input {
    margin: 0;
    margin-right: 6px;
    opacity: 0;
  }
  &:before {
    content: url(${(props) => props.unchecked});
    display: block;
    width: 16px;
    height: 16px;
    background-color: #523f6a;
    border-radius: 2px;
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  &.active {
    &:before {
      content: url(${(props) => props.checked});
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  button {
    background-color: transparent;
    border: 1px solid #f0eef2;
    color: #f0eef2;
    line-height: 25px;
    border-radius: 30px;
    padding: 7px 22px;
    font-size: 18px;
    min-width: 158px;
    & + button {
      margin-left: 45px;
    }
    &.add-button {
      background-color: #e33aa9;
      border: none;
      cursor:pointer;
    }
    &.back-button{
      cursor:pointer;
    }
  }
`;

export const CheckboxItem = styled.div`
  display: flex;
  font-family: Raleway;
  align-items: center;
  flex-wrap: wrap;
  width: ${(props) => (props.top ? "50%" : "33%")};
  font-size: 12px;
  color: white;
`;

export const CustomSelect = styled.select`
  border: 1px solid #b6a1cc;
  border-radius: 15px;
  color: #d8d7e1;
  margin-left: 7px;
  width: 135px;
  padding: 5px;
  background-color: transparent;

  &:focus {
    background: #817393;
    border-radius: 15px;
    color: white;
    border: none;
  }
  & > option {
    background: #d4d1d7;
    border-radius: 10px;
    color: black;
  }
  & > option:hover {
    background: #817393;
    border-radius: 10px;
    color: white;
  }
`;

export const FormActiveContainer = styled.div`
  form {
    padding-top: 16px;
    p {
      font-family: Raleway; //this font is only for temporal styling.
      color: #cdcdcd;
      font-size: 18px;
      text-align: center;
      margin: 0;
      & + .checkbox-container {
        margin-top: 5px;
      }
    }
  }
  .containerSelectVersion {
    display: flex;
    align-items: right;
    justify-content: end;
    width: 100%;
    margin: 10px 0px 10px 0px;
    text-align: right !important;
    color: white;
    font-family: Avenir Next;
    p {
      margin-top: 8px;
      margin-right: 5px;
      font-size: 13px;
      text-align: right;
    }
  }
`;
