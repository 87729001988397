import { Base64 } from "js-base64";
import defaultImage from "../../../assets/defaultImages/defaultUpdate.png";

export const getImage = (src, width, height) => {
	try {
		let imgURL = "https://d24s337exbt5zr.cloudfront.net/"
		imgURL += Base64.encode( JSON.stringify({
			bucket: process.env.REACT_APP_IMAGES_BUCKET,
			key: src,
			edits: {
				resize: {
					width,
					height,
					fit: "contain",
				background: { r: 255, g: 255, b: 255, alpha: 1 },
			  	}
			}
		}))
		return { imgURL };
	} 
	catch (err) {
		console.log("getImage error", err);
	  	return { imgURL: defaultImage };
	}
  };