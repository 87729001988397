import { Container, HeaderEstatus } from "../products/productsGeneral/styles";

export default function HeaderInvoicingR() {
  return (
    <Container className="status-color-container">
      <HeaderEstatus bg="#71DE56" padding="0">
        <div></div>
        ODT Cerrada
      </HeaderEstatus>
      <HeaderEstatus bg="#ED9A4D" padding="0">
        <div></div>En proceso
      </HeaderEstatus>
      <HeaderEstatus bg="#E57432" padding="4px 0 0 0 ">
        <div></div>Entrega de producto
      </HeaderEstatus>
      <HeaderEstatus bg="#EDD34D" padding="4px 0 0 0 ">
        <div></div>Pago validado
      </HeaderEstatus>
    </Container>
  );
}
