import { MainContainer, InputCustom } from "./style";

export default function Input(props) {
  return (
    <MainContainer width={props.width} error={props.error}>
      <InputCustom
        onChange={(event) =>
          props.onChange && props.onChange(event.target.value)
        }
        {...props}
      />
    </MainContainer>
  );
}
