import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const IconContainer = styled.figure`
  img {
    width: 25px;
    height: 25px;
  }
`;
