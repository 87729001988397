import axios from "axios";
import React, { useEffect, useState } from "react";
import { UpgradePlanContainer } from "./styles";
import { Plan } from "./plan";
import closeButton from "../../../../assets/IconComponents/planIcons/closeButton.svg";
import info from "../../../../assets/IconComponents/info.svg";
import launch from "../../../../assets/IconComponents/launch.svg";
import { features } from "./utils";

const UpgradePlan = () => {
  const [planList, setPlanList] = useState([]);
  const [plansResponse, setPlansResponse] = useState([]);
  const [stateFlags, setStateFlags] = useState({
    showPlansPyMES: true,
    showAnnualPlans: true,
  });

  const getPlans = async () => {
    const r = await axios.get(process.env.REACT_APP_PLANS);
    setPlansResponse(
      JSON.parse(r.data.body).plans.sort(
        (a, b) => a.last_update - b.last_update
      )
    );
  };

  useEffect(() => {
    const activePlan = JSON.parse(sessionStorage.getItem("user")).membership
      .planID;
    const recurring = stateFlags.showAnnualPlans ? "year" : "month";
    const keyPrice = `recurring_${recurring}`;
    if (plansResponse.length === 0) getPlans();
    else {
      const keyCategory = stateFlags.showPlansPyMES ? "PyMES" : "Enterprise";
      const plans = plansResponse.filter(
        (f) => f.metadata.type === keyCategory
      );
      const actualPrice = plans.find((f) => f.metadata.id === activePlan)?.[
        keyPrice
      ].price;
      const pList = plans.map((plan) => ({
        id: +plan.metadata.id,
        title: plan.name,
        products: plan.metadata.products_limit || "∞",
        users: plan.metadata.user_limit,
        active: activePlan === +plan.metadata.id,
        price: plan[keyPrice].price,
        priceId: plan[keyPrice].priceId,
        actualPrice,
        actualPlanId: activePlan,
        featuresNotIncluded: plan.metadata?.featuresNotIncluded,
        recurring,
        prodId: plan.id,
      }));
      if (!stateFlags.showPlansPyMES) {
        pList.push({
          id: 9,
          title: "Plan Enterprise Full",
          products: "+10000",
          users: "30",
          active: activePlan === 9,
          price: "Contáctanos",
          priceId: "",
          actualPrice,
          actualPlanId: activePlan,
          featuresNotIncluded: undefined,
          recurring,
        });
      }
      setPlanList(pList);
    }
  }, [plansResponse, stateFlags.showAnnualPlans, stateFlags.showPlansPyMES]);

  return (
    <UpgradePlanContainer>
      <div className="header">
        <div
          className="changePlans"
          onClick={() =>
            setStateFlags((prev) => ({
              ...prev,
              showAnnualPlans: !prev.showAnnualPlans,
            }))
          }
        >
          <figure className="launch">
            <img src={launch} alt="Cambiar" />
          </figure>
          <p>
            {stateFlags.showAnnualPlans ? "Planes Anuales" : "Planes Mensuales"}
          </p>
          <p>Pymes y Enterprise</p>
        </div>
        <div
          className={`tabPlan${stateFlags.showPlansPyMES ? " activeTab" : ""}`}
          onClick={() =>
            setStateFlags((prev) => ({ ...prev, showPlansPyMES: true }))
          }
        >
          <p>Planes PyMES</p>
        </div>
        <div
          className={`tabPlan${!stateFlags.showPlansPyMES ? " activeTab" : ""}`}
          onClick={() =>
            setStateFlags((prev) => ({ ...prev, showPlansPyMES: false }))
          }
        >
          <p>Planes Enterprise</p>
        </div>
        <div className="close-button">
          <button onClick={() => (window.location.href = "/profile")}>
            <img src={closeButton} alt="close button" />
          </button>
        </div>
      </div>
      <div style={{ overflowY: "auto" }}>
        <div>
          <div className="features-container">
            <h2 className="border-solid">Plataforma</h2>
            {features.map((e, i) => (
              <div
                className={`${
                  [2, 12].includes(i) ? "border-solid" : "border-bottom"
                }`}
              >
                <p>{e.name}</p>
                <figure>{e.info && <img src={info} alt="info" />}</figure>
              </div>
            ))}
          </div>
          {planList?.map((plan, key) => (
            <Plan key={key} plan={plan} features={features} />
          ))}
        </div>
      </div>
    </UpgradePlanContainer>
  );
};

export default UpgradePlan;
