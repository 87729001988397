import React from "react";
import { tableStyle, rowStyle, ordersTableStyle } from "../table_template";
import { tableTemplate } from "./templates/contentohTemplate";
import { Column, Table } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { renderer, headerHandler } from "./renderer";
import { RowColumn } from "../styles";

const OrderedProducts = (props) => {
  return (
    <Table
      className="table-products"
      rowClassName="table-row"
      headerStyle={tableStyle}
      tableStyle={{ overflow: "unset" }}
      rowStyle={rowStyle}
      onRowClick={(evt) => {
        props.eventsFunctions.rowClicked(props.articlesList[evt.index]);
      }}
      height="720"
      width="1440"
      minWidth="1180"
      style={{
        overflow: "auto",
      }}
      headerHeight={20}
      rowHeight={30}
      rowCount={props.articlesList.length}
      rowGetter={({ index }) => props.articlesList[index]}
    >
      {tableTemplate.map((col, i) => (
        <Column
          className="table-column"
          style={ordersTableStyle[i]?.style}
          flexGrow={1}
          flexShrink={0}
          width={ordersTableStyle[i]?.width}
          maxWidth={ordersTableStyle[i]?.maxWidth}
          minWidth={ordersTableStyle[i]?.maxWidth}
          label={headerHandler(
            col.header,
            col.attributes,
            props.eventsFunctions
          )}
          dataKey={"version"}
          cellRenderer={({ cellData, rowIndex, dataKey }) => (
            <RowColumn>
              {renderer(
                col,
                props.articlesList[rowIndex],
                props.eventsFunctions,
                rowIndex
              )}
            </RowColumn>
          )}
        />
      ))}
    </Table>
  );
};
export default OrderedProducts;
