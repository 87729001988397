import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid;
  border-color: #f0f0f0;
  ${({ isVisible }) =>
    isVisible === 0 &&
    `
    background: rgb(223, 51, 159);
    border: 0px;
  `};
`;
