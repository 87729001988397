import styled from "styled-components";

export const TwoColumns = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 200px);
  grid-auto-rows: 1fr;
  grid-template-columns: 34% 66%;
  grid-template-rows: 100%;
  gap: 0px 20px;
  grid-template-areas: "form map";
  font-family: Roboto;
  font-size: 12px;
  color: #808080;
  .form {
    grid-area: form;
  }
  .map {
    grid-area: map;
    height: calc(100vh - 280px);
    width: 100%;
    & > div {
      height: 100%;
      width: 100%;
    }
  }
  .title {
    font-size: 15px;
    line-height: 20px;
    color: #262626;
  }

  .subTitle {
    font-weight: medium;
    margin-bottom: 3px;
    color: #262626;
  }
  .fixButton {
    display: flex;
    align-items: center;
    font-size: 10px;
    height: 20px;
    & > span {
      font-size: 14px;
      margin-right: 5px;
    }
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mr-5 {
    margin-right: 5px;
  }
  hr {
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
  }
`;

export const FormContainer = styled.div`
  font-family: Roboto;
  font-weight: 500;
  .financed_option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    & > p {
      color: #262626;
      font-size: 12px;
      line-height: 16px;
    }
    & > div {
      width: 163px;
      & > div {
        background: #e6e6e6;
        border-radius: 3px;
        padding: 3px 10px;
        font-size: 10px;
        line-height: 13px;
        color: #707070;
      }
      .select-container {
        background: #fff;
        width: 100%;
      }
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }
`;

export const Select = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #262626;
  font-family: Roboto;
  cursor: pointer;
  margin-bottom: 10px;
  & > p {
    font-size: 15px;
  }
  .select_button {
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f0f0f0;
    border-radius: 50px;
    background: #ffffff;
    padding: 8px 15px 10px 16px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  & > div {
    position: relative;
    & > ul {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      background: #fff;
      min-width: 210px;
      padding: 10px;
      z-index: 1001;
      border-radius: 5px;
      box-shadow: 0px 2px 4px #00000040;
      & > li {
        color: #262626;
        font-size: 12px;
        line-height: 16px;
        & > span {
          display: block;
          color: #808080;
          font-size: 10px;
          line-height: 13px;
          margin: 5px 0 15px;
        }
      }
    }
  }
`;

export const Input = styled.input`
  width: ${({ width }) => width ?? "100%"};
  max-width: ${({ maxWidth }) => maxWidth ?? "100%"};
  padding: 8px 10px 5px;
  height: 28px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 5px;
  &::placeholder {
    color: #808080;
  }
`;
