import { FilterInput } from "./styles";
import { FilterOption } from "../filterOption";
import searchIcon from "../iconsFolder/searchIcon.svg";
import { useEffect } from "react";
import Loading from "../../general/loading/index";

export const FiltersInput = (props) => {
  const filters = props.filtersOptions;

  function copy(obj) {
    let result;
    if (obj instanceof Array) {
      result = [...obj];
    } else if (typeof obj === "object") {
      result = { ...obj };
    } else {
      return obj;
    }
    for (let prop of Reflect.ownKeys(result)) {
      result[prop] = copy(result[prop]);
    }
    return result;
  }

  /*-----------------------
  	evento pegar texto en el input
	-------------------------*/
  const handlerOnPaste = (event, inputElement) => {
    let filtersObject = copy(props.globalFilters);
    const pastedText = event.clipboardData.getData("Text");
    const mainFilter = props.mainFilter ? props.mainFilter : "UPC";
    let pastedTextFormatter = ""; // modificacion del pastedText
    let filter = {}; // config del filtro por defecto
    event.preventDefault();

    // checar si el filtro por defecto es "UPC"
    if (mainFilter === "UPC" && pastedText.length >= 5) {
      const split = pastedText.split("");
      split.forEach((e) => {
        pastedTextFormatter +=
          e.charCodeAt(0) === 13 || e.charCodeAt(0) === 10 ? "," : e.trim();
      });
      filter = {
        filter: "upc",
        values: pastedTextFormatter
          .split(",")
          .filter((e) => e.trim().length > 0),
      };
    } else {
      // cualquier otro filtro por defecto
      pastedTextFormatter = pastedText;
      filter = {
        filter: mainFilter,
        values: pastedTextFormatter.trim(),
      };
    }

    inputElement.value += pastedTextFormatter;
    props.setKeyword(inputElement.value);
    filtersObject[`${mainFilter}`] = filter;
    props.setGlobalFilters(filtersObject);
  };
  // codigo base de handlerOnPaste()
  /*
		let filtersObject = copy(props.globalFilters);
        const pastedText = event.clipboardData.getData("Text");
        event.preventDefault();
        if (pastedText.length >= 5) {
          let string = "";
          const split = pastedText.split("");
          split.forEach((e) => {
            string +=
              e.charCodeAt(0) === 13 || e.charCodeAt(0) === 10 ? "," : e.trim();
          });
          inputElement.value += string;
          let filter = {
            filter: "upc",
            values: string.split(",").filter((e) => e.trim().length > 0),
          };

          props.setKeyword(inputElement.value);
          filtersObject[`${props.mainFilter ? props.mainFilter : "UPC"}`] =
            filter;
          props.setGlobalFilters(filtersObject);
        }
	*/

  /*-----------------------
  	evento key down (enter)
	-------------------------*/
  const handlerOnKeyDownEnter = (event, inputElement) => {
    let filtersObject = copy(props.globalFilters);
    const mainFilter = props.mainFilter ? props.mainFilter : "UPC";
    let filter = {};

    // cuando el input esta vacio
    if (inputElement.value.length === 0) {
      props.setKeyword("");
      delete filtersObject[`${mainFilter}`];
      props.setGlobalFilters(filtersObject);
    }
    // cuando se preciono la tecla enter
    else if (event.key === "Enter") {
      props.setKeyword(inputElement.value);
      event.preventDefault();

      // cuando el filtro por defecto es UPC
      if (mainFilter === "UPC") {
        const split = inputElement.value.split(",");
        filter = {
          filter: "upc",
          values: split.filter((e) => e.trim().length > 0),
        };
      } else {
        // cualquier otro filtro por defecto
        filter = {
          filter: mainFilter,
          values: inputElement.value.trim(),
        };
      }

      filtersObject[`${mainFilter}`] = filter;
      props.setGlobalFilters(filtersObject);
    }
  };
  // codigo base de handlerOnKeyDownEnter
  /*
	let filtersObject = copy(props.globalFilters);
        if (inputElement.value.length === 0) {
          props.setKeyword("");
          delete filtersObject[
            `${props.mainFilter ? props.mainFilter : "UPC"}`
          ];
          props.setGlobalFilters(filtersObject);
        }
        if (event.key === "Enter") {
          props.setKeyword(inputElement.value);
          event.preventDefault();
          const split = inputElement.value.split(",");
          let filter = {
            filter: "upc",
            values: split.filter((e) => e.trim().length > 0),
          };

          filtersObject[`${props.mainFilter ? props.mainFilter : "UPC"}`] =
            filter;
          props.setGlobalFilters(filtersObject);
        }
	*/

  useEffect(() => {
    const inputElement = document.getElementById("input-search");
    if (inputElement) {
      // onPaste listener
      inputElement.addEventListener("paste", function (event) {
        handlerOnPaste(event, inputElement);
      });

      // key down listener ("enter")
      inputElement.addEventListener("keydown", function (event) {
        handlerOnKeyDownEnter(event, inputElement);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterInput id="input-filter">
      <div className="real-input">
        <img src={searchIcon} alt="search icon" className="search-icon" />
        <input
          type="text"
          autoFocus
          id="input-search"
          placeholder={props.keyWords ? props.keyWords : props.placeHolderInput}
        />
      </div>

      {/* Menu de opciones filtros  */}
      <div className="filters-container">
        <div className="filters">
          {filters?.length ? (
            filters
              ?.filter((f) => props?.filters[f.name]?.length)
              ?.map((filter, index) => (
                <FilterOption
                  key={"filter-option-" + index}
                  label={filter.name}
                  id={"filter" + index}
                  filters={props.filters[filter.name]}
                  filterInputFunct={props.filterInputFunct}
                  setKeyword={props.setKeyword}
                  globalFilters={props.globalFilters}
                  setGlobalFilters={props.setGlobalFilters}
                />
              ))
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </FilterInput>
  );
};
