import styled from "styled-components";

export const ContentohProductsContainer = styled.div`
  height: calc(100vh - 100px);
  padding: 10px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .table-products {
    .table-column {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      box-sizing: border-box;

      &:first-child {
        div {
          width: 30px;
          height: 30px;
          padding: 0;

          input {
            margin: auto;
          }
        }
      }

      & + .table-column {
        border-left: 1px solid #f0eef2;
      }
    }
    .table-row {
      cursor: pointer;

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #d4d1d7;
      }
    }

    .ReactVirtualized__Grid {
      will-change: auto !important;

      .ReactVirtualized__Grid__innerScrollContainer {
        overflow: unset !important;
      }
    }
  }

  .container-grid {
    height:100%;
    display:flex;
    flex-direction:column;
    gap:1rem;
    margin-left:0px;
  }
`;
