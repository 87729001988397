import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  color: #707070;
  font-size: 12px;
  box-sizing: border-box;
`;

export const TwoColumns = styled.div`
  display: grid;
  width: 100%;
  grid-auto-rows: 1fr;
  grid-template-columns: 75% 25%;
  grid-template-rows: 100%;
  gap: 0;
  grid-template-areas: "first second";
  font-family: Roboto;
  .product__info {
    .remove__margin {
      margin-left: 0px;
      display: flex;
    }
    grid-area: first;
    width: fit-content;
    label > p {
      display: flex;
      flex-direction: column;
      .name {
        font-family: Roboto;
        margin-left: 0px;
        font-weight: 600;
        font-size: 14px;
        color: #000;
      }
    }
    label:before {
      width: 12px;
      height: 12px;
      background-size: 12px;
      cursor: pointer;
    }
    .upc {
      display: block;
      font-family: Roboto;
      margin-left: 0px;
      color: #707070;
      font-size: 12px;
      width: 130px;
      &:last-child {
        margin-left: 40px;
      }
    }
  }
  .price {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-area: second;
    margin-right: 55px;
    & > div > img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    .group__price {
      display: flex;
      flex-direction: column;
      color: #262626;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      .full {
        font-size: 10px;
        text-decoration: line-through;
        color: #707070;
      }
      .discountText {
        color: #85bc5b;
      }
    }
  }
  .discount {
    border-radius: 3px;
    background-color: #85bc5b;
    color: #ffffff;
    font-family: Roboto;
    font-size: 10px;
    margin-right: 15px;
    padding: 5px;
  }
`;

export const ContentTooltipDiscount = styled.div`
  color: #707070;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .title {
    color: #000;
    font-weight: 500;
  }
`;
