import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 235px);
  width: 100%;
  overflow: auto;
`;

export const ColumnHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #503d66;
  min-width: ${(props) => (props.minWidth ? props.minWidth + "px" : "100px")};
  width: ${(props) => (props.width ? props.width + "%" : "100px")};
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`;

export const RowHead = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  height: 50px;
  background-color: white;
`;

export const Column = styled.div`
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #817393;
  min-width: ${(props) => (props.minWidth ? props.minWidth + "px" : "100px")};
  width: ${(props) => (props.width ? props.width + "%" : "100px")};
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  justify-content: ${(props) =>
    props.align === "center"
      ? "center"
      : props.align === "right"
      ? "flex-end"
      : "flex-start"};
  overflow-y: ${(props) => (props.noOverflow ? "none" : "auto")};

  .chk-custom-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  height: 50px;
  background-color: ${(props) => (props.index % 2 === 0 ? "#FAFAFA" : "#FFF")};
  .image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .imageInput {
    height: 20px;
    width: 20px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .divInputSearch {
    display: flex;
    background: transparent;
    box-sizing: border-box;
    border-radius: 6px;
    height: 40px;
    z-index: 2;

    & + * {
      margin-top: 5px;
    }

    .inputSearch {
      border: none;
      margin-top: 0px;
      width: 175px;
      background-color: #f0eef2;
      border: 1px solid #e33aa9;
      border-radius: 2px;
      height: 27px;
    }
  }

  .divBoxOptions {
    background: #f0eef2;
    border: 1px solid #d4d1d7;
    box-sizing: border-box;
    border-radius: 6px;
    z-index: 2;
    padding: 14px 0;
    overflow-y: auto;

    img {
      border-radius: 50%;
    }
    & + * {
      margin-top: 5px;
    }
  }

  .dataAsign {
    display: flex;
    max-width: 80%;
    margin: auto;

    img {
      width: 30px;
      height: 30px;

      & + * {
        margin-left: 20px;
      }
    }
  }

  .textItem {
    padding: 5px;
    overflow: hidden;
    max-width: 180px;
    text-align: left;
  }

  .dataAsign:hover {
    display: flex;
    background-color: #d4d1d7;
  }

  &:nth-child(1) {
    min-width: 40px;
    max-width: 40px;
  }
`;

export const SplitTableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  & > div {
    width: 50%;
  }
  & > div:first-child {
    border-right: 1px solid black;
    box-sizing: border-box;
  }
`;
