import defaultImage from "../../../assets/defaultImages/defaultUpdate.png";


export const getImages = ( list ) => {
	let images = []
	for(let k=0;  k<4;  k++) {
		let key = "list" + list.id + "-" + k;
		if(list.images[k]) images.push((
			<img src={ list.images[k] }  alt="foto"  key={key}/>
		))
		else if(list.articles_count>=k+1) images.push((
			<img src={defaultImage}  key={key} />
		))
	}
	return images
}