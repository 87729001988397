import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { PlanMainContainer, CurrentPlan, CompanyCollaborators } from "./styles";
import { getCollaborators, getProduct } from "../../../_utils/data";
import { CollaboratorTable } from "./collaboratorsTable";

import newUser from "../../../../assets/IconComponents/planIcons/newUser.svg";

const Plan = (props) => {
  const { products_limit, user_limit, name, start_date } = JSON.parse(
    sessionStorage.getItem("user")
  ).membership;
  const [usedUsers, setUsedUsers] = useState(0);
  const [usedProducts, setUsedProducts] = useState(0);
  const [redirect, setRedirect] = useState(false);

  const getUsers = async () => {
    const collaborators = await getCollaborators();
    const products = await getProduct({ query: { countCheck: true } });
    setUsedProducts(JSON.parse(products.data.body).count);
    setUsedUsers(collaborators);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <PlanMainContainer>
      <CurrentPlan>
        <div className="info-header-container">
            <div className="plan-info-container">
            <h2>{name || "Plan"}</h2>
            <p>Miembro desde {new Date(start_date).getFullYear()}</p>
          </div>
          <div className="products-colab-container">
            <div className="used-products-container">
              <h2>
                <span className="used-products">{"0" || usedProducts} de</span>
                <span className="products-limit"> {products_limit || "∞"} </span>
                <span className="products-title">Productos</span>
              </h2>
            </div>
            <CompanyCollaborators>
              <div className="collaborators-counter">
                <p>
                  <span className="current-collaborators">{usedUsers.length} de</span>
                  <span className="collaborators-limit"> {user_limit} colaboradores</span>
                </p>
              </div>
            </CompanyCollaborators>
          </div>
        </div>
        
       
        <div className="plan-button-container">
          <button
            onClick={() => {
              props.history.push({
                pathname: "/upgradeplan",
              });
              setRedirect(true);
            }}
            className="rise-your-plan"
          >
            Crece tu plan
          </button>
        </div>
      </CurrentPlan>
      {!props.hasChild && <CollaboratorTable listUsers={usedUsers} />}
      {redirect && (
        <Redirect
          to={{
            pathname: `/upgradePlan`,
          }}
        />
      )}
    </PlanMainContainer>
  );
};

export default Plan;
