import { Filter } from "./styles";
import removeFilter from "../iconsFolder/removeFilter.svg";
import { CheckboxCustom } from "../../customInputs/checkboxCustom";
import { FiltersPopUp } from "./filtersPopUp";
import { useState } from "react";

export const FilterOption = (props) => {
  const [showFilters, setShowFilters] = useState(false);

  function copy(obj) {
    let result;
    if (obj instanceof Array) {
      result = [...obj];
    } else if (typeof obj === "object") {
      result = { ...obj };
    } else {
      return obj;
    }
    for (let prop of Reflect.ownKeys(result)) {
      result[prop] = copy(result[prop]);
    }
    return result;
  }

  return (
    <Filter>
      {props.optionSelected ? (
        <div className="option-selected">
          <p className="filter-name">{props.label}</p>
          <button
            className="remove-filter"
            onClick={(e) => {
              e.stopPropagation();
              let filtersObject = copy(props.globalFilters);
              delete filtersObject[props.label];
              props.setGlobalFilters(filtersObject);
              //if (props.label === "UPC") props.setKeyword("");
              if (props.mainFilter && props.label === props.mainFilter)
                props.setKeyword("");
            }}
          >
            <img src={removeFilter} alt="remove icon" />
          </button>
        </div>
      ) : (
        <div
          className="filters-selection"
          onMouseEnter={() => setShowFilters(true)}
          onMouseLeave={() => setShowFilters(false)}
        >
          <CheckboxCustom
            labelText={props.label}
            id={props.id}
            name={props.id}
            htmlFor={props.id}
            size={16}
            disabled={true}
            defaultChecked={props.globalFilters[props.label]}
            isChecked={props.globalFilters[props.label]}
          />
          {showFilters && props.filters?.length > 0 && (
            <FiltersPopUp
              filters={props.filters}
              filterName={props.label}
              filterInputFunct={props.filterInputFunct}
              globalFilters={props.globalFilters}
              setGlobalFilters={props.setGlobalFilters}
            />
          )}
        </div>
      )}
    </Filter>
  );
};
