import styled, { css } from "styled-components";
export const MainContainer = styled.div`
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  & > .filters-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;

    .filters {
      display: flex;
      align-items: center;

      .search-user-input {
        display: flex;
        align-items: center;
        border: 1px solid #f0f0f0;
        border-radius: 50px;
        padding: 9px;

        img {
          width: 14px;
          height: 14px;
          & + * {
            margin-left: 15px;
          }
        }
        input {
          font-family: Roboto;
          font-size: 12px;
          outline: none;
          border: none;
        }
      }

      & > * + * {
        margin-left: 20px;
      }
    }
    .container-def {
        margin-top: 10px;
        box-shadow: 0px 2px 4px #00000040;
        border-radius: 5px;
        background-color: #fff;
        padding-bottom: 10px;
        position: absolute;
        z-index: 20;
        max-height: 500px;
        overflow: auto;
    }
    .button-save {
      cursor: pointer;
      font-family: RobotoMedium, sans-serif;
      font-style: normal;
      font-weight: 500;
      border: none;
      button {
        padding: 9px 18px;
      }
    }
    .modal-save {
      width: 20%;
      background: transparent;
    }
  }
  .table-attributes-container {
      width: 100%;
      height: 600px;
      overflow: auto;
      border-radius: 5px;
    }
  }

  .modal-masive-change .contentModal{
    max-width: 260px;
    .container-customComponent .texto{
      text-align: center;
      font-family: RobotoRegular,sans-serif;
      font-size: 13px;
      color: #707070;
      white-space: pre-wrap;
    }
  }
`;


export const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 24px 0px;
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }
`;

export const BasicData = styled(Section)`
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #817393;
  width: 100%;
  border:1px solid #F0F0F0;
  border-radius:5px;
  padding:0;
  .no-users {
    padding: 10px 5px;
    p {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #707070;
    }
  }

  .text_center {
    text-align: center;
  }
  .space {
    justify-content: space-between;
  }
  .align_end {
    justify-content: flex-end;
  }
  .th-custom p{
    font-family: "RobotoMedium", sans-serif;
  }
  .margin_row {
    .col.chk-cont {
      display: flex;
      justify-content: flex-start;
      & > div {
        & + * {
          margin-left: 10px;
        }
      }
    }
    .col + * {
      margin-left: 10px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;

    p {
      color: #817393;

      & + * {
        margin-top: 5px;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border:0.5px solid #F0F0F0;
    &:first-child {
      border:none;
    }

    .col {
      flex: 1;
      overflow: hidden;
      align-items: center;

      figure {
        & + * {
          margin-left: 10px;
        }
      }

      &:first-child {
        padding: 10px;
        align-items: center;
        overflow: inherit;
      }
    }

    &.header {
      background-color: #f7f7fc;
      height: 34px;
      border-radius: 5px 5px 0 0;
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      z-index: 9;

      .col {
        color: #262626;
        font-weight: 500;
      }
    }
  }
  .center {
    justify-content: center;
    align-items: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  input[type="email"] {
    width: 360px;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 7px;
    margin-right: 20px;
    ::placeholder {
      color: #d4d1d7;
    }
  }
  input[type="checkbox"]:checked {
    background-color: #e33aa9;
    color: green;
  }
  select {
    width: 116px;
    height: 30px;
    padding: 0 8px;
    border: 1px solid #e33aa9;
    border-radius: 7px;
    box-sizing: border-box;
    background: #fff;
  }
  button {
    width: 130px;
    height: 30px;
    font-size: 14px;
    line-height: 19px;
    color: #e33aa9;
    background: #fff;
    border: 1px solid #e33aa9;
    box-sizing: border-box;
    border-radius: 30px;
    :hover {
      cursor: pointer;
    }
  }
  .modal-save {
    width: 20%;
    background: transparent;
  }
  .contentModal {
    width: 20%;
  }
  .flex{
    display:flex;
  }
  .gap-10{
    gap:10px;
  }
  .width-atributo{
    min-width:200px;
    max-width: 290px;
    border-right:1px solid #F0F0F0!important;
  }
  .elipsis{
    text-overflow: ellipsis;
  }
`;