import "react-notifications-component/dist/theme.css";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { Tooltip } from "contentoh-components-library";
import ReactImageFallback from "react-image-fallback";
import { GridProduct, ContainerIcon, NewNotificationAlert } from "./styles";
import Percentage from "../Percentage";
import { getGridImage } from "../../helper";
import defaultImage from "../../../../assets/defaultImages/defaultUpdate.png";
import spinner from "../../../../assets/defaultImages/Spinner.gif";
import CustomSelectLists from "../../../products/merchants/customSelectLists";
import DropdownScenary from "../../../products/merchants/DropdownScenary";
import Visibility from "../../../products/merchants/Visibility";

export default function GridView({
  articleLists = [],
  allLists = [],
  countNotifications,
  listsMenu_componentFooter,
  isShowModal,
  ...props
}) {
  let { name, category, id_article, company_name } = props.dataProduct.article;
  const [isVisible, setIsVisible] = useState(
    props.dataProduct.article.isVisible
  );
  const [redirect, setRedirect] = useState(undefined);
  const [isRetailer] = useState(
    JSON.parse(sessionStorage.getItem("user")).is_retailer
  );

  const formatPrice = (price) => {
    if (!price) return "Sin dato";
    var formatter = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(price);
  };

  const imageSrc = () => {
    if (props.section === "RetailerProducts") {
      return getGridImage(props.dataProduct.article.src, 570, 420);
    } else if (props.section === "merchants") {
      return getGridImage(props.dataProduct.src, 500, 420);
    } else {
      return getGridImage(props.dataProduct.article.src, 570, 420);
    }
  };

  const redirectEdit = () => {
    const membership = JSON.parse(sessionStorage.getItem("user")).membership;
    const canEdit = membership.planID !== 1;
    try {
      if (!canEdit) {
        return true;
      }
      let newArray = JSON.parse(JSON.stringify(props.dataProduct.article));
      newArray.version = props.dataProduct.version;
      newArray.retailersAvailable = props.dataProduct.article.retailers;
      sessionStorage.setItem("productSelected", JSON.stringify(newArray));
      sessionStorage.setItem(
        "productEdit",
        JSON.stringify({
          idCategory: props.dataProduct.article.id_category,
          ArticleId: props.dataProduct.article.id_article,
          product: props.origin !== undefined ? newArray : [newArray],
        })
      );
      sessionStorage.setItem(
        "asignations",
        JSON.stringify(props.dataProduct.asignations ?? [])
      );
      setRedirect(`/EditProducts`);
    } catch (error) {
      console.log(error);
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: {
            origin: props.origin,
            withChat: true,
            chatType: "merchant_product",
          },
        }}
      />
    );
  }

  return (
    <>
      <ReactNotifications />
      <GridProduct>
        <div className="split">
          <input
            type="checkbox"
            onClick={(event) => {
              event.stopPropagation();
            }}
            checked={props.checkedCheckboxProduct}
            onChange={(event) => {
              if (props.onClickCheckboxProduct)
                props.onClickCheckboxProduct(
                  event.target.checked,
                  props.dataProduct?.article?.id_article
                );
            }}
          />
          {props.dataProduct?.missingChat > 0 ? <NewNotificationAlert /> : null}
        </div>
        <figure>
          <ReactImageFallback
            src={imageSrc()}
            fallbackImage={defaultImage}
            initialImage={spinner}
            className="image"
            onClick={() => {
              props.section !== "RetailerProducts" && redirectEdit();
            }}
          />
        </figure>

        <div id="body">
          <div className="contentPrincipal">
            {props.section !== "RetailerProducts" && (
              <Percentage percentage={props?.percentage?.toFixed()} />
            )}

            <Tooltip
              componentTooltip={
                <>
                  <p id="title" className="labelGeneral">
                    {name}
                  </p>
                </>
              }
              children={
                <ContainerIcon className="fondo">
                  <p id="title" className="labelGeneral">
                    {name.substring(0, 22)}
                  </p>
                </ContainerIcon>
              }
              classNameTooltip="container-tooltip"
              position="bottomCenter"
            />
            <Tooltip
              componentTooltip={
                <>
                  <p className="labelGeneral">{category}</p>
                </>
              }
              children={
                <ContainerIcon className="fondo">
                  <p className="labelGeneral">{category.substring(0, 25)}</p>
                </ContainerIcon>
              }
              classNameTooltip="container-tooltip"
              position="bottomCenter"
            />
            <p className="labelGeneral">{company_name.substring(0, 30)}</p>
            {props.dataProduct?.data?.model && (
              <p className="labelGeneral">
                Modelo:{props.dataProduct?.data?.model}
              </p>
            )}
            {props.dataProduct?.data?.price && (
              <p className="labelGeneral">
                {formatPrice(props.dataProduct?.data?.price)}
              </p>
            )}
          </div>
          <div id="actions">
            <div id="footer">
              {props.dataProduct?.data?.disponibility && (
                <p className="category">
                  {props.dataProduct?.data?.disponibility}
                </p>
              )}
              <Tooltip
                componentTooltip={
                  <>
                    <p>
                      <b>Campos requeridos</b>
                    </p>
                    <p>
                      Este nuevo icono te indicará cuando <br></br> completes
                      todos los campos requeridos
                    </p>
                  </>
                }
                children={
                  <ContainerIcon
                    className="fondo"
                    percentageRequired={props.percentageRequired ?? 0}
                  >
                    {props.section !== "RetailerProducts" && (
                      <span className="isCompleted">local_police</span>
                    )}
                  </ContainerIcon>
                }
                classNameTooltip="container-tooltip"
                position="bottomCenter"
              />
              {props.section === "RetailerProducts" && (
                <div style={{ display: "flex", "flex-wrap": "wrap" }}>
                  <div
                    style={{
                      width: "43%",
                      "box-sizing": "border-box",
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      background: "#2692F6",
                      color: "white",
                      "margin-right": "3px",
                      border: "none",
                      "border-radius": "3px",
                    }}
                  >
                    {props.dataProduct.article.review_state}
                  </div>
                  <div
                    style={{
                      width: "54%",
                      "box-sizing": "border-box",
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      padding: "3px",
                      background: "#F0F0F0",
                      color: "#707070",
                      border: "none",
                      "border-radius": "3px",
                    }}
                  >
                    {
                      <Tooltip
                        componentTooltip={
                          <>
                            <p id="title" className="labelGeneral">
                              {props.dataProduct.article.phaseName}
                            </p>
                          </>
                        }
                        children={
                          <ContainerIcon className="fondo">
                            {props.dataProduct.article.phaseName.substring(
                              0,
                              10
                            )}
                            ...
                          </ContainerIcon>
                        }
                        classNameTooltip="container-tooltip"
                        position="bottomCenter"
                      />
                    }
                  </div>
                  <div
                    style={{
                      width: "100%",
                      "margin-top": "5px",
                      "box-sizing": "border-box",
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      padding: "3px",
                      color: "#707070",
                      border: "1px solid #F0F0F0",
                      "border-radius": "3px",
                    }}
                  >
                    {props.dataProduct.article.groupName}
                  </div>
                </div>
              )}
            </div>
            {props.section === "merchants" && (
              <div className="group-buttons">
                {isRetailer === 1 ? (
                  !props.dataProduct.stage ? (
                    <DropdownScenary
                      productsSelected={props.productsSelected}
                      setProductsSelected={props.setProductsSelected}
                      sizeIcon={24}
                      id={`DropdownScenary${id_article}`}
                      showModalAlert={props.showModalAlert}
                      setModalAlert={props.setModalAlert}
                      articleId={id_article}
                      articlesList={props.articlesList}
                      updateScenary={props.updateScenary}
                    />
                  ) : null
                ) : (
                  <Visibility
                    id={`Visibility${id_article}`}
                    productsSelected={props.productsSelected}
                    articleId={id_article}
                    isVisible={isVisible}
                    articlesList={props.articlesList}
                    showModalAlert={props.showModalAlert}
                    setModalAlert={props.setModalAlert}
                    updateVisibility={props.updateVisibility}
                    showNotification={props.showNotification}
                  />
                )}
                <CustomSelectLists
                  idSelect={`select_grid${props.index}-${id_article}`}
                  articleID={id_article}
                  articleLists={articleLists}
                  allLists={allLists}
                  countNotifications={countNotifications}
                  setCountNotifications={
                    props.eventsFunctions.setCountNotifications
                  }
                  onClickItem={props.eventsFunctions.onClickItemList}
                  componentFooter={listsMenu_componentFooter}
                  isShowModal={isShowModal}
                />
              </div>
            )}
          </div>
        </div>
      </GridProduct>
    </>
  );
}
