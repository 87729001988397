import styled from "styled-components";

export const GeneralProductsContainer = styled.div`
  height: calc(100vh - 100px);
  padding: 10px;
  box-sizing: border-box;
  overflow-y: scroll;

  * {
    box-sizing: border-box;
  }


  .container-grid {
    height:100%;
    display:flex;
    flex-direction:column;
    gap:1rem;
    margin-left:0px;
  }
`;
