import { isEqual } from "lodash";
import React, { useState, memo } from "react";
import { Button } from "./styles";
import ProductItem from "../ProductItem/index";
import arrowDown from "../../../assets/IconCheckout/arrow_drop_down.svg";
import arrowUp from "../../../assets/IconCheckout/arrow_drop_up.svg";

const Collapsed = ({ className, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        className={className}
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <img
          className={isOpen ? "down" : "up"}
          src={isOpen ? arrowDown : arrowUp}
          alt="Indicador de colapso"
        />
        <ProductItem {...props} />
      </Button>
      {isOpen ? children : null}
    </>
  );
};

export default memo(Collapsed, isEqual);
