import React, { useState, useEffect } from "react";
import { MainContainer } from "./style";
import Input from "../Input";

export default function LabelToInput(props) {
  const [isActive, setIsActive] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  useEffect(() => {
    setDefaultValue(props.defaultValue === "" ? "-" : props.defaultValue);
  }, [props.defaultValue]);
  return (
    <MainContainer width={props.width} error={!isActive && props.error}>
      {!isActive ? (
        <p onClick={() => setIsActive(true)}>{defaultValue}</p>
      ) : (
        <Input
          {...props}
          width={"100%"}
          onBlur={(e) => {
            if (props.blurFunction) props.blurFunction(e);
            props.onBlur && props.onBlur();
            setIsActive(false);
          }}
        />
      )}
    </MainContainer>
  );
}
