import { useEffect } from "react";
import { getGraphicColor, getMetricsProducts } from "../../../../_utils/helper";

import { Container, Title, Subtitle } from "../../styles";

export const MetricsBar = () => {
  const retailer = sessionStorage.getItem("retailerSelected")
    ? JSON.parse(sessionStorage.getItem("retailerSelected"))
    : null;

  const getMetrics = async () => {
    const response = await getMetricsProducts();
    let percentArray = response[2];
    let colorPercentArray = [];

    Object.keys(percentArray).forEach((key) => {
      let element = {
        color: "",
        value: 0,
      };

      element.color = getGraphicColor(key);
      element.value = percentArray[key];
      colorPercentArray.push(element);
    });

    colorPercentArray.sort((a, b) => (a.value > b.value ? 1 : -1));
  };

  useEffect(() => {
    getMetrics();
  }, []);

  return (
    <Container className="title-container">
      <Title>{retailer ? retailer.name : "Lista de productos"}</Title>
      <Subtitle>
        Visualiza de forma general todos los productos, da click sobre alguno
        para ver el detalle o selecciona varios y trabaja en multiedición.
      </Subtitle>
    </Container>
  );
};
