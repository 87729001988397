import { useEffect, useState } from "react";
import { faStar as IconStarFill } from "@fortawesome/free-solid-svg-icons";
import { faStar as IconStarStroke } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import CustomSelectItemsLoad from "../../../custom/CustomSelectItemsLoad";
import { ContainerFooter } from "./styles";
import { faSpinner as LoadingIcon } from "@fortawesome/free-solid-svg-icons";

export default function CustomSelectListMultiple({
  idSelect, // id unico
  allLists = [], // todas las listas merchants
  onClickBtnDelete, //cuando se hace click en el boton eliminar
  //(listsID, unique) => {}
  onClickBtnAdd, //cuando se hace click en el boton agregar
  //(listsID, unique) => {}
  isShowModal,
  ...props
}) {
  const [customLists, setCustomlists] = useState([]); // fusion de allLists con articleLists
  const [valueInput, setValueInput] = useState(""); // valor del inputSearch del select
  const [optionItems, setOptionItems] = useState([]); // items que seran mostrados en el select
  const [listsSelected, setListsSelected] = useState([]); // items que seran mostrados en el select
  const [icon, setIcon] = useState({
    icon: <Icon icon={IconStarStroke} />,
    className: "iconNormal",
  });
  const [countN, setCountN] = useState(0);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [disabledItems, setDisabledItems] = useState(false);
  const [isLoadingBtnAdd, setIsLoadingBtnAdd] = useState(false);
  const [isLoadingBtnDelete, setIsLoadingBtnDelete] = useState(false);

  // cuando se crea el componente
  useEffect(() => {
    let lists = allLists.map((list) => ({ ...list })) ?? [];
    lists = lists.map((list) => ({ ...list, isSelected: false }));
    setCustomlists(lists);
    setOptionItems(lists);
    setValueInput("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // cada vez que cambien las listas selecionadas
  useEffect(() => {
    if (listsSelected.length === 0) setDisabledButtons(true);
    else setDisabledButtons(false);
  }, [listsSelected]);

  // realizar filtro cada vez que cambia el inputSearch
  const onChangeValueInput = (newValue) => {
    // filtrar por input
    let itemsListFiltered = customLists.map((l) => ({ ...l }));
    if (newValue.trim().length > 0) {
      itemsListFiltered = itemsListFiltered.filter((list) =>
        list.name.toLowerCase().includes(newValue.trim().toLowerCase())
      );
    }
    setValueInput(newValue);
    setOptionItems(itemsListFiltered);
  };

  // funcion que cambia el estado del checkbox de una lista
  const setIsSeletedItem = (listId, isSelected) => {
    customLists.forEach((list) => {
      if (list.id === listId) list.isSelected = isSelected;
    });
    // filtrar por input search
    let itemsListFiltered = customLists.map((l) => ({ ...l }));
    if (valueInput.trim().length > 0) {
      itemsListFiltered = itemsListFiltered.filter((list) =>
        list.name.toLowerCase().includes(valueInput.trim().toLocaleLowerCase())
      );
    }
    setCustomlists(customLists.map((l) => ({ ...l })));
    setOptionItems(itemsListFiltered);
  };

  // evento click en el chexbox de una lista
  const onClickItemList = async (isChecked, listId) => {
    //verificar si se pueda dar click
    if (disabledItems) return;

    setIsSeletedItem(listId, isChecked);
    if (isChecked) {
      // add list selected
      setListsSelected([...listsSelected, listId]);
    } else {
      // delete list selected
      setListsSelected(listsSelected.filter((id) => id !== listId));
    }
  };

  // al abrir/cerrar el select
  const isShowSelect = (show) => {
    if (show) {
      setIcon({ icon: <Icon icon={IconStarFill} />, className: "iconClick" });
    } else {
      setIcon({
        icon: <Icon icon={IconStarStroke} />,
        className: "iconNormal",
      });
    }
  };

  return (
    <CustomSelectItemsLoad
      options={optionItems}
      showSearchBar
      placeHolder="Buscar lista"
      valueInputSearch={valueInput}
      onChangeValueInputSearch={onChangeValueInput}
      icon={icon.icon}
      iconClassName={icon.className}
      isShowSelect={isShowSelect}
      customSelectId={idSelect}
      onClickItem={onClickItemList}
      isShowModal={isShowModal}
      componentFooter={
        <ContainerFooter>
          <button
            disabled={disabledButtons}
            onClick={async (event) => {
              const unique = countN;
              const newUnique = countN + listsSelected.length;
              setCountN(newUnique);
              setDisabledItems(true);
              setDisabledButtons(true);
              setIsLoadingBtnDelete(true);
              await onClickBtnDelete(listsSelected, unique, idSelect);
            }}
          >
            {isLoadingBtnDelete ? (
              <i>
                <Icon className="fa-pulse" icon={LoadingIcon} />
              </i>
            ) : (
              "Eliminar de la wishlist"
            )}
          </button>

          <button
            disabled={disabledButtons}
            onClick={async (event) => {
              const unique = countN;
              const newUnique = countN + listsSelected.length;
              setCountN(newUnique);
              setDisabledItems(true);
              setDisabledButtons(true);
              setIsLoadingBtnAdd(true);
              await onClickBtnAdd(listsSelected, unique, idSelect);
            }}
          >
            {isLoadingBtnAdd ? (
              <Icon className="fa-pulse" icon={LoadingIcon} />
            ) : (
              "Agregar a la wishlist"
            )}
          </button>
        </ContainerFooter>
      }
    />
  );
}
