import { BlurContainer, ModalContainer } from "./styles";
import closeWhite from "../../../../assets/IconComponents/closeWhite.svg";
import { Redirect } from "react-router-dom";
import { useState } from "react";

export const ExitModal = (props) => {
  const [redirect, setRedirect] = useState(false);

  return (
    <BlurContainer>
      <ModalContainer className="exit-modal">
        <button
          className="close-button"
          onClick={() => {
            props.setExitModal(false);
          }}
        >
          <img src={closeWhite} alt="Cerrar" />
        </button>
        <div className="title-container">
          <h2>¿Deseas salir de edición de producto múltiple?</h2>
        </div>
        <div className="buttons-container">
          <button
            className="btn-exit"
            onClick={() => {
              sessionStorage.removeItem("multipleEditionList");
              setRedirect(true);
            }}
          >
            Salir
          </button>
          <button
            className="btn-return"
            onClick={() => {
              props.setExitModal(false);
            }}
          >
            Regresar
          </button>
        </div>
      </ModalContainer>
      {redirect && (
        <Redirect
          to={{
            pathname: `/products/`,
          }}
        />
      )}
    </BlurContainer>
  );
};
