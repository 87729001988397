import { useState, useEffect, useMemo, useRef } from "react";
import { Calendar } from "react-datepicker2";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import * as L from "leaflet";
import { dateFormatter } from "../../../_utils/helper";
import InOffice from "./Forms/InOffice";
import DefaultForm from "./Forms/DefaultForm";
import Financed from "./Forms/Financed";
import { TwoColumns, Select } from "./styles";
import style from "./styleCalendarOveride.css";
import icon from "../../../../assets/IconComponents/pin.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const baseDirs = {
  CDMX: {
    address: "Cto. Interior Melchor Ocampo #469, Anzures, Miguel Hidalgo",
    street: "Cto. Interior Melchor Ocampo #469, Anzures, Miguel Hidalgo",
    zipCode: "11590",
    city: "CDMX",
    cel1: "+52 (55) 4165-1497",
    cel2: "01-800-26-91-338",
    latlng: { lat: 19.4245718, lng: -99.1772829 },
  },
  MTY: {
    address: "Av. Revolución 2096, Villa Estadio, 64830 Monterrey, N.L.",
    street: "Av. Revolución 2096, Villa Estadio",
    city: "MTY",
    zipCode: "64830",
    cel1: "81 8369 4400",
    cel2: "",
    latlng: { lat: 25.6558509, lng: -100.2805023 },
  },
};

const Second = ({ company, ...props }) => {
  const [map, setMap] = useState(null);
  const [deliverySelected, setDeliverySelected] = useState("En oficina");
  const [isOpenDeliveryOptions, setIsOpenDeliveryOptions] = useState(false);
  const [isTHDFinanced, setIsTHDFinanced] = useState(false);
  const [position, setPosition] = useState({
    lat: 19.4245718,
    lng: -99.1772829,
  });
  const [sede, setSede] = useState("CDMX");
  const [today] = useState(new Date());
  const markerRef = useRef(null);

  const changeDelivery = (newDelivery) => {
    setDeliverySelected(newDelivery);
    setIsOpenDeliveryOptions(false);
    props.setAppointment((prev) => ({ ...prev, type: newDelivery }));
  };

  useEffect(() => {
    const isTHDFinanced =
      company?.financedRetailers?.filter((f) => f.id === 58)?.length === 1;
    setIsTHDFinanced(isTHDFinanced);
  }, [company]);

  useEffect(() => {
    const isCDMX = deliverySelected === "En oficina" || sede === "CDMX";
    const isMTY = sede === "MTY";
    let latlng = {};
    if (isMTY) {
      latlng = { lat: 25.6558509, lng: -100.2805023 };
      setPosition(latlng);
    } else if (isCDMX) {
      latlng = { lat: 19.4245718, lng: -99.1772829 };
      setPosition(latlng);
    }
    props.setAppointment((prev) => ({ ...prev, latlng }));
    if (map) map.setView(latlng, 15);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverySelected, sede]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker !== null) {
          const { lat, lng } = marker.getLatLng();
          const latlng = { lat, lng };
          setPosition(latlng);
          props.setAppointment((prev) => ({ ...prev, latlng }));
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  L.Marker.prototype.options.icon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [30],
  });

  return (
    <TwoColumns>
      <div className="form">
        {isTHDFinanced ? (
          <Financed
            setSede={setSede}
            baseDirs={baseDirs}
            setAppointment={props.setAppointment}
          />
        ) : (
          <>
            <Select>
              <p className="title">Tipo de entrega</p>
              <div>
                <div
                  className="select_button"
                  onClick={() => setIsOpenDeliveryOptions((p) => !p)}
                >
                  {deliverySelected}
                  <span className="material-icons-outlined">
                    arrow_drop_down
                  </span>
                </div>
                {isOpenDeliveryOptions ? (
                  <ul>
                    <li onClick={() => changeDelivery("En oficina")}>
                      En oficina
                      <span>
                        Tu nos entregas los productos en nuestras instalaciones.
                      </span>
                    </li>
                    <li onClick={() => changeDelivery("Pick Up")}>
                      Pick Up
                      <span>
                        Nosotros pasamos por los productos a tus instalaciones.
                      </span>
                    </li>
                    <li onClick={() => changeDelivery("In situ")}>
                      In situ
                      <span>
                        Trabajamos los productos en tus instalaciones. Solo para
                        CDMX.
                      </span>
                    </li>
                  </ul>
                ) : null}
              </div>
            </Select>
            <hr />
            {(() => {
              switch (deliverySelected) {
                case "En oficina":
                  return <InOffice baseDirs={baseDirs} sede="CDMX" />;
                default:
                  return <DefaultForm setAppointment={props.setAppointment} />;
              }
            })()}
            <hr />
            <p className="subTitle">Agenda tu visita</p>
            <p className="mb-10">
              Elige el día que llevarás tus productos a nuestras instalaciones.
            </p>
            <Calendar
              style={style}
              id="calendar"
              isGregorian={true}
              min={today.toDateString()}
              onChange={(value) => {
                props.setAppointment((prev) => ({
                  ...prev,
                  date: dateFormatter.format(value._d),
                }));
              }}
            />
          </>
        )}
      </div>
      <div className="map">
        <MapContainer center={position} zoom={18} scrollWheelZoom ref={setMap}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}
          />
        </MapContainer>
      </div>
    </TwoColumns>
  );
};

export default Second;
