import { HeaderTitleRCont, HeaderTitleR, HeaderSubtitleR } from "./styles";
export default function HeaderOrdersR() {
  return (
    <HeaderTitleRCont>
      <HeaderTitleR>Estado de cuenta a Facturar</HeaderTitleR>
      <HeaderSubtitleR>
        Aquí encontrarás todas las facturas pendientes o concluídas con
        Content-oh!
      </HeaderSubtitleR>
    </HeaderTitleRCont>
  );
}
