import styled from "styled-components";

export const GridProduct = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  border-radius: 5px 5px 0px 0px;
  padding:5px 5px;
  box-shadow: 0px 2px 6px #0000000d;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #f0f0f0;
  figure {
    width: 190px;
    height: 140px;
    margin: auto;
    margin-bottom: 3px;
    > img {
      width: 190px;
      height: 140px;
      border-radius: 5px 5px 0px 0px;
      cursor: pointer;
      object-fit: contain;
    }
  }
  #body {
    font: normal normal normal 12px/14px Roboto;
    height: 145px;
    padding-right: 5px;
    display: flex;
    flex-direction:column;
  }
  p {
    margin-left: 5px;
    margin-bottom: 4px;
  }
  .contentPrincipal {
    width: calc(100% - 30px);
    padding: 0 10px;
  }
  .group-buttons{
    display:flex;
    flex-direction:column;
    gap:5px;
  }
  .labelGeneral {
    color: #808080;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #title {
    color: #262626;
    margin-top: 5px;
  }
  #actions {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    align-items: center;
  }
  #footer {
    opacity: 1;
    font: normal normal normal 12px/14px Roboto;
    display: flex;
  }
  #upc {
    margin-top: 2px;
    color: #808080;
  }
  .split {
    display: flex;
    justify-content: space-between;
    margin: 5px 10px 0;
  }
`;

export const NewNotificationAlert = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: newMessage 2s linear 0s infinite normal forwards;
  background-color: #85bc5b;
  @keyframes newMessage {
    0% {
      animation-timing-function: ease-out;
      transform: scale(1);
      transform-origin: center center;
    }

    10% {
      animation-timing-function: ease-in;
      transform: scale(0.81);
    }

    17% {
      animation-timing-function: ease-out;
      transform: scale(0.88);
    }

    33% {
      animation-timing-function: ease-in;
      transform: scale(0.77);
    }

    45% {
      animation-timing-function: ease-out;
      transform: scale(1);
    }
  }
`;

export const Container = styled.div`
  background-color: "#D4D1D7";
  width: fit-content;
  height: 27px;
  padding: 4px;
  border-radius: 15px;
  font-family: "Avenir Next";
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${(backgroundColor) =>
    backgroundColor === "s2" || backgroundColor === "s1"
      ? "#817393"
      : "#FFFFFF"};

  &.status-CA,
  &.status-IE {
    background-color: "#ED9A4D";
  }

  &.status-R,
  &.status-AS,
  &.status-PA {
    background-color: "#E57432";
  }

  &.status-AA,
  &.status-AP,
  &.status-ACA,
  &.status-AC {
    background-color: "#18A0FB";
  }

  &.status-RA,
  &.status-RC,
  &.status-RP,
  &.status-RCA {
    background-color: "#D74DED";
  }

  &.status-Dat,
  &.status-Dsc,
  &.status-Imgs {
    background-color: "#817393";
  }

  &.status-Ex {
    background-color: "#71DE56";
  }

  &.status-DDI {
    background-color: "#603888";
  }

  &.status-GLD {
    background-color: "#ED9A4D";
  }

  &.status-TAB {
    background-color: "#281F33";
  }

  &.status-Pt {
    background-color: "#F0EEF2";
    color: "#817393";
  }
`;
export const ContainerIcon = styled.div`
  cursor: pointer;
  @font-face {
    font-family: "Material Symbols Outlined";
    font-style: normal;
    font-weight: 100 700;
    src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v68/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsLjBuVY.woff2)
      format("woff2");
  }

  .isCompleted {
    font-family: "Material Symbols Outlined";
    font-size: 30px;
    //line-height: 1;
    margin-top: 10px;
  }
  .isCompleted {
    font-variation-settings: "FILL"
        ${(props) => (props.percentageRequired < 100 ? 0 : 1)},
      "wght" 400, "GRAD" 0, "opsz" 48;
    color: ${(props) =>
      props.percentageRequired < 100 ? "#d4d1d7" : "#e33aa9"};
  }
`;
