import styled from "styled-components";
import { COLORS } from "../variables";
import { NavLink } from "react-router-dom";

export const ContainerHeader = styled.header`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  height: 100px;
  flex-shrink: 0;
`;
export const ContainerPrincipal = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: space-between;

  .avatar-container {
    width: 40px;
    height: 40px;
    .avatar {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 3.5rem;
`;
export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
`;
export const ContainerInfoRight = styled.div`
  display: flex;
  width: 156px;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ContainerInfoTitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
  text-align: right;
  letter-spacing: -0.015em;
  color: #b12d84;
`;
export const ContainerLogUser = styled.div`
  display: flex;
  text-align: center;
`;

export const ContainerInfoSubtitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 8px;
  text-align: right;
  color: #817393;
`;

export const UserInfo = styled.div`
  width: 230px;
  display: flex;
  align-items: center;
  padding: 0 15px 0 10px;
  height: 53px;
  background: #f0eef2;
  border-radius: 25px;
  justify-content: space-between;
  position: relative;
`;

export const ProfileMenu = styled.button`
  border: none;
  display: block;
  position: absolute;
  padding: 20px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0eef2;
  margin-top: 10px;
  border-radius: 15px;
  z-index: 10;

  .title-role {
    background-color: #603888;
    width: 168px;
    height: 25px;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #ededed;
    text-align: center;
    border-radius: 15px;
  }

  .account-item {
    display: flex;
    margin-left: 30px;
    text-decoration: none;

    .icon-container {
      img {
        height: 100%;
      }

      & + * {
        margin-left: 12px;
      }
    }

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #817393;
    }
  }

  * + a {
    margin-top: 10px;
  }
`;

export const ContainerDashboard = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  flex-direction: column;
`;

export const HeaderImg = styled.img`
  max-width: 100%;
  height: auto;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;
export const HeaderTitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #b12d84;
`;
export const HeaderInfo = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 8px;
  color: #817393;
`;
export const ContainIcon = styled.div`
  height: 35px;
  position: relative;
`;

export const HeaderSearch = styled.div`
  display: flex;
  width: 40%;
  background-color: #ffffff;
  height: 30px;
  padding: 0 15px 0 0;
`;

export const PulsatingCircle = styled.div`
  position: relative;
  right: -95%;
  top: -30px;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: ${(props) => (props.notifications ? "15px" : "0px")};

  &:before {
    content: "";
    position: relative;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ff009a;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;
export const HeaderInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: #ffffff;
`;
export const HeaderToggle = styled.div`
  color: #19181b;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const NavContainer = styled.div`
  width: ${(props) => props.latcontain};
  padding: 20px;
  box-sizing: border-box;
  background-color: ${COLORS.primary};
  z-index: 0;
  transition: 0.4s;
`;

export const Navbar = styled.nav`
  height: 100%;
  width: ${(props) => props.nav};
  box-sizing: border-box;
  padding: 0 5px 0 5px;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  border-radius: 20px;
  background: linear-gradient(180deg, #e33aa9 0%, #3b1366 100%);
  scrollbar-width: none;
  display: flex;
`;
export const NavLogo = styled.p`
  font-weight: 600;
  padding: ${(props) => props.padlogo};
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #58555e;
`;
export const NavLogoName = styled.span`
  opacity: 0;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
`;
export const NavItems = styled.div`
  row-gap: 1.5rem;
  display: grid;
`;

export const NavList = styled.div`
  row-gap: 2.5rem;
`;
export const NavLinks = styled.div`
  margin: ${(props) => props.navlink};
`;
export const NavSubtitle = styled.h3`
  font-size: 0.938rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #a5a1aa;
`;
export const NavLinkz = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => props.height};
`;
NavLinkz.defaultProps = {
  height: "34px",
  width: "100%",
};
export const StyledNavLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
  height: 34px;
  align-items: center;
  color: #58555e;
  border-radius: 18px;
  background-color: ${(props) => props.bgcolor};
  &.active {
    border: 1px solid #e33aa9;
  }

  &:hover {
    background-color: #603888;
  }
`;
NavLink.defaultProps = {
  bgcolor: "",
};

export const NavName = styled.span`
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #fcfcfc;
  cursor: pointer;
`;

export const NavDropdownCollapse = styled(NavLink)`
  text-decoration: none;
  position: relative;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border-left: 1px solid #f0eef2;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #f0eef2;
  padding-top: 10px;
  padding-left: 26px;
  &::before {
    border-left: 1px solid #e33aa9;
    width: 10px;
    content: "";
    border-radius: 50%;
    height: 10px;
    background-color: white;
    position: absolute;
    left: -4%;
    bottom: 1%;
  }
`;

export const NavDropdown = styled.div`
  overflow: hidden;
  height: ${(props) => props.height};
  transition: 0.4s ease-in-out;
`;

export const NavDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 33px;
`;

export const NavDropdownItem = styled.a`
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 0;
  height: 40px;
  color: white;
  &:hover {
    height: 40px;
    visibility: visible;
    z-index: 1;
  }
`;
export const NavContain = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
`;
export const ContainerIcons = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  .open {
    margin-bottom: 60px;
  }
`;
export const IconsBut = styled.div`
  cursor: pointer;
`;

export const ProfileNavLink = styled(NavLink)`
  display: flex;
  margin-left: 30px;
  text-decoration: none;

  .icon-container {
    img {
      height: 100%;
    }

    & + * {
      margin-left: 12px;
    }
  }

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #817393;
  }
`;

export const GeneralModalAlert = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(4px);
  background-color: rgba(40, 31, 51, 0.6);

  .general-modal-container {
    width: 590px;
    padding: 41px 40px 58px;
    background-color: #281f33;
    border-radius: 39px;
    text-align: center;

    .modal-icon-container {
      & + * {
        margin-top: 18px;
      }
    }

    .modal-text-container {
      p {
        font-family: Raleway;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: #f7f7f7;

        span {
          font-weight: 700;
        }
      }

      & + * {
        margin-top: 21px;
      }
    }

    .modal-buttons-container {
      display: flex;
      justify-content: center;

      button {
        border: none;
        border-radius: 30px;
        height: 41px;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        min-width: 158px;
        color: #fff;

        &.cancel-button {
          background-color: transparent;
          border: 1px solid #f0eef2;
          color: #f0eef2;
        }

        &.accept-button {
          background-color: #e33aa9;
        }

        & + * {
          margin-left: 45px;
        }
      }
    }
  }
`;

export const Container = styled.div`
  background: transparent;
  cursor: pointer;
  width: 40px !important;
  margin: 9px;
  position: relative;
`;
export const Slider = styled.div`
  font-family: sans-serif;
  margin: auto;
  background: #281f33;
  border-radius: 6px;
  width: 310px !important;
  padding: 16px;
  padding-top: 20px;
  box-sizing: border-box;
  position: absolute;
  cursor: default;
  z-index: 2;
  top: calc(100% - 373px);
  left: calc(50% - 309px / 2);
  #div-slider {
    width: 310px;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .slider {
    position: relative;
    max-width: 300px;
    width: 100%;
    height: 270px;
    margin: 0;
    li {
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0;
      width: inherit;
      height: inherit;
      transition: opacity 0.5s;
      background: transparent;
      img {
        width: 100%;
        height: 180px;
        border-radius: 4px;
        background-color: #fafafa;
        & + * {
          margin-top: 10px;
        }
      }
      p {
        font-family: "Avenir Next";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: -0.015em;
        color: #fafafa;
        background-color: #281f33;
      }
      div {
        background-color: #fafafa;
        height: 180px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        img {
          height: 40px;
        }
        & + * {
          margin-top: 13px;
        }
      }
    }
    #slide5 {
      div {
        height: 130px;
        width: 100%;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        border-radius: 4px;
        img {
          width: 100%;
          height: 115px;
          object-fit: contain;
        }
      }
      .lema-end {
        justify-content: center;
        justify-content: space-evenly;
        height: 100px;
        background-color: #281f33;
        flex-direction: column;
        justify-content: space-evenly;
        border-radius: inherit;
        span {
          font-family: "Avenir Next";
          font-style: normal;
          font-weight: 700;
          font-size: 17px;
          line-height: 15px;
          text-align: center;
          letter-spacing: -0.015em;
          color: #fafafa;
        }
      }
      button {
        display: none;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 160px;
        height: 30px;
        border: 1px solid #d4d1d7;
        border-radius: 15px;
        color: #fafafa;
        background-color: #281f33;
        font-family: "Avenir Next";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.015em;
        position: absolute;
        left: 22%;
        top: 89%;
        cursor: pointer;
      }
    }
  }
  li:first-child {
    opacity: 1;
  }
  li:target {
    opacity: 1;
  }
  .menu {
    margin: 0;
    text-align: center;
    li {
      display: inline-block;
      text-align: center;
      & + * {
        margin-left: 15px;
      }
      a {
        display: inline-block;
        text-decoration: none;
        background-color: #c4c4c4;
        width: 10px;
        height: 10px;
        border-radius: 100%;
      }
      #slidea1 {
        background-color: #e33aa9;
      }
    }
  }
`;

export const ContainerMenu = styled.div`
  height: 98%;
  box-sizing: border-box;
  padding: 23px 10px 70px 10px;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  width: ${({ bar }) => (bar ? 270 : 58)}px;
  border-radius: ${({ bar }) => (bar ? 20 : 50)}px;
  background: linear-gradient(180deg, #e33aa9 0%, #3b1366 100%);
  scrollbar-width: none;
  display: flex;
  text-align: center;
  transition: width 0.3s linear;
  margin: 10px 0 10px 20px;
  & + * {
    margin-left: 30px;
  }
  .navbar-top {
    transition: all 0.3s linear;
    .option {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: ${({ bar }) => (bar === "" ? 145 : 50)}%;
      padding-left: ${({ bar }) => (bar ? 5 : 0)}px;
      .active {
        background: #e33aa9;
      }
      & + div {
        margin-top: 5px;
      }
      a {
        height: ${({ bar }) => (bar ? 32 : 38)}px;
        display: flex;
        justify-content: ${({ bar }) => (bar ? "flex-start" : "center")};
        border-radius: ${({ bar }) => (bar ? 18 : 100)}px;
        align-items: center;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.015em;
        color: #fafafa;
        text-decoration: none;
        &.option-link {
          cursor: pointer;
          width: ${({ bar }) => (bar ? "160" : "38")}px;
          margin: 5px 0;
          padding-left: ${({ bar }) => (bar ? 15 : 0)}px;
        }
        p {
          cursor: pointer;
          margin: 0%;
          pointer-events: none;
        }
        img {
          width: 24px;
          & + * {
            margin-left: 12px;
          }
        }
      }
      .option-link:hover {
        background: #e33aa9;
      }
      .sub-option {
        display: flex;
        flex-direction: column;
        padding-left: ${({ bar }) => (bar ? 28 : 20)}px;
        height: auto;
        width: auto;
        a {
          display: flex;
          justify-content: flex-start;
          text-decoration: none;
          position: relative;
          font-size: 13px;
          border-left: 1px solid #f0eef2;
          border-radius: 0;
          color: #f0eef2;
          padding-top: 14px;
          padding-left: 12px;
          height: 25px;
          &::before {
            border-left: 1px solid #e33aa9;
            width: 10px;
            content: "";
            border-radius: 50%;
            height: 10px;
            background-color: white;
            position: absolute;
            bottom: 0;
            left: ${({ bar }) => (bar ? -4 : -34)}%;
          }
          &.active {
            background: none;
            &::before {
              background-color: #e33aa9;
            }
          }
        }
      }
    }
  }
`;
