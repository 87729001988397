import styled from "styled-components";
import { grayS2, grayS4, grayS5, grayS6, grayS7, grayS8, pinkS1, rojoS1 } from "../../variables";

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
  	align-items: center;
	gap: 10px;
  	
	// botones circulares con hover gris por default
	.circle {
    	width: 30px;
    	height: 30px;
    	padding: 0;
    	background-color: transparent;
    	border: none !important;
    	cursor: pointer;
		transition: background-color 0.25s;
		border-radius: 100%;

    	img {
      		width: 100%;
      		height: 100%;
    	}

		&:hover{
			background-color: ${grayS7};
		}

		&.redHoverButton:hover {
			//background-color: ${rojoS1};
		}

		&.pink:hover {
			background-color: #e33aa9;
		}

    //& + * {
    //  margin-left: 10px;
    //}
  	}
	// botones circulares con hover gris por default (icono fontawesome)
	.circleIcon {
    	width: 30px;
    	height: 30px;
    	padding: 0;
    	background-color: transparent;
    	border: 1px solid ${grayS7};
		color: ${grayS6};
    	cursor: pointer;
		transition: background-color 0.25s;
		border-radius: 100%;
		&:hover{
			background-color: ${grayS7};
		}

		&.pink:hover {
			background-color: #e33aa9;
		}
		&.grid:hover {
			background-color: #d4d1d785;
		}
		.grid-icon{
			height: 27px;
		}
  	}

	// boton add
	.tooltip-btnAdd {
		width: 80vw;
		max-width: 350px;
		min-width: 150px;
		border-radius: 8px;
		background-color: white;
		box-shadow: 0px 0px 6px 3px ${grayS7};
		padding: 4px 10px;

		.dropdownAddList{
			background: white;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			width: 100%;

			input{
				border: none;
				font-family: 'Roboto Regular' , 'Segoe UI' , sans-serif;
				font-size: 13px;
				color: #262626;
				min-width: 110px;
				flex-grow: 2;
				line-height: 1.4;
			}

			button{
				margin: 3px 0px;
				font-size: 13px;
				padding: 4px 5px;
				border-radius: 100%;
				border: none;
				color: #00000033;
				background-color: #E6E6E6;
				overflow: hidden;
				cursor: pointer;
				transition: background 0.25s;

				&:hover{
					background-color: ${pinkS1};
					color: white;
				}
			}
		}

	}

	// boton edit
	.buttonEdit {
		position: relative;

		.hiddenComponent{
			display: none;
			position: absolute;
			top: calc(100% + 5px) ;
			right: 0px;
			z-index: 2;
			width: fit-content;
			border-radius: 8px;
			background-color: white;
			box-shadow: 0px 0px 6px 3px ${grayS7};
			padding: 7px 10px;

			&.active{
				display: block;
			}
		}

	}
	
  .pink {
    width: 30px;
    height: 30px;
    background-color: #e33aa9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
    }
  }

  .export-button {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    background-color: #e33aa9;
    color: #fff;
    border: none;
    border-radius: 30px;
    height: 40px;
    padding: 0 45px;
    cursor: pointer;
  }
`;
