import { Table, Column } from "react-virtualized";
import Status from "../../../_utils/TableComponents/Status";
import moment from "moment";
import IconDownloadPDF from "../../../../assets/IconComponents/IconDownloadPDF.js";
import { getMonth } from "../utils";
import {
  tableStyle,
  idColumn,
  quantityColumn,
  checkboxColumn,
  estatusColumn,
  dateColumn,
  downloadColumn,
  MonthColumn,
} from "./template";
const OrdersTable = (props) => {
  return (
    <Table
      {...tableStyle}
      rowCount={props.ordersList.length}
      rowGetter={({ index }) => props.ordersList[index]}
      onRowClick={({ event, rowData }) => {
        event.stopPropagation();
        props?.clickEvents?.rowClicked(rowData.order);
      }}
    >
      <Column
        {...checkboxColumn}
        label={
          <input
            id={"globalHeaderCheckbox"}
            type="checkbox"
            onClick={(evt) => props.clickEvents.checkAll(evt.target.checked)}
          />
        }
        cellRenderer={({ cellData, rowIndex, dataKey }) => (
          <input
            key={`checkbox-${rowIndex}-${props.ordersList[rowIndex].checked}`}
            type="checkbox"
            onClick={(evt) =>
              props.clickEvents.checkboxClicked(
                props.ordersList[rowIndex],
                evt.target.checked
              )
            }
            defaultChecked={props.ordersList[rowIndex].checked}
          />
        )}
      />
      <Column
        label="Mes"
        {...MonthColumn}
        cellDataGetter={({ rowData }) =>
          getMonth(moment(rowData.order.timestamp).format("M"))
        }
      />
      <Column
        label="Orden"
        {...idColumn}
        cellDataGetter={({ rowData }) => rowData.order.id_order}
      />
      <Column
        label="Cant. Productos"
        {...quantityColumn}
        cellDataGetter={({ rowData }) => rowData.order.qtyProducts}
      />
      <Column
        label="Fecha compra"
        {...dateColumn}
        cellDataGetter={({ rowData }) =>
          moment(rowData.order.timestamp).format("DD/MM/YYYY")
        }
      />
      <Column
        label="Estatus"
        {...estatusColumn}
        cellRenderer={({ rowData }) => (
          <Status noHover status={rowData.order.status} />
        )}
      />
      <Column
        label="Entrega estimada"
        {...dateColumn}
        cellDataGetter={({ rowData }) =>
          rowData.order.deliver_date
            ? moment(rowData.order.deliver_date).format("DD/MM/YYYY")
            : "Pendiente"
        }
      />
      <Column
        label="Costo"
        {...quantityColumn}
        cellDataGetter={({ rowData }) =>
          rowData.order.total ? `$${rowData.order.total.toFixed(2)}` : "---"
        }
      />
      <Column
        label="Descarga"
        {...downloadColumn}
        cellRenderer={({ rowData }) => (
          <div>
            PDF <IconDownloadPDF />
          </div>
        )}
      />
    </Table>
  );
};

export default OrdersTable;
