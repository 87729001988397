import { getUniqueCodeLabel } from "../../../../_utils/helper";
export const tableTemplate = [
  {
    header: "checkbox",
    styles: {
      minWidth: "20px",
      centered: true,
      component: "checkbox",
    },
    attributes: {
      type: "checkbox",
    },
    data: "checkbox",
    path: "checked",
  },
  {
    header: "Num. Orden",
    styles: {
      minWidth: "23px",
      centered: true,
    },
    data: "order",
    path: "orderId",
  },
  {
    header: getUniqueCodeLabel(),
    styles: {
      minWidth: "30px",
      centered: true,
    },
    data: "upc",
    path: "article.upc",
  },
  {
    header: "Proveedor",
    styles: {
      minWidth: "35px",
      centered: true,
    },
    data: "company",
    path: "article.company_name",
  },
  {
    header: "Categoría",
    styles: {
      minWidth: "34px",
      centered: true,
      component: "category",
    },
    data: "category",
    path: "article.category",
  },
  {
    header: "Estat.",
    styles: {
      minWidth: "15px",
      display: "flex",
      justifyContentn: "center",
      overflow: "unset",
      noClick: true,
    },
    data: "status",
    path: "article_status",
  },
  {
    header: "Ver.",
    styles: {
      minWidth: "10px",
      centered: true,
    },
    data: "version",
    path: "version",
  },
  {
    header: "Val.",
    styles: {
      minWidth: "15px",
      centered: true,
      noClick: true,
      withoutOverflow: true,
    },
    data: "valid",
    path: "article_status",
  },
  {
    header: "Prio",
    styles: {
      minWidth: "15px",
      centered: true,
      noClick: true,
      withoutOverflow: true,
    },
    articleId: "article.id_article",
    data: "priority",
    path: "prio",
  },
  {
    header: "Fecha de Solicitud",
    styles: {
      minWidth: "30px",
      centered: true,
      component: "requestDate",
    },
    data: "requestDate",
    path: "requestDate",
  },
  {
    header: "Precio",
    styles: {
      minWidth: "80px",
      component: "Precio",
      centered: true,
      direction: "flex-end",
    },
    data: "totalPrice",
    path: "totalPrice",
  },
];
