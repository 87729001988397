import styled from "styled-components";

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;

  .buttons-container {
    display: flex;

    .button-container {
      input {
        display: none;

        &:checked + label {
          border-bottom: 2px solid #e33aa9;
          font-size: 18px;
        }
      }

      label {
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: #503d66;
        display: block;
        border-bottom: 2px solid #d4d1d7;
        height: 28px;
        transition: 0.2s;
      }

      & + * {
        margin-left: 20px;
      }
    }
  }

  .btn-close-container {
    button {
      width: 52px;
      height: 52px;
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      img {
        width: 52px;
        object-fit: fill;
      }
    }
  }

  * {
    box-sizing: border-box;
  }
`;
