export const handleFilterKey = (currentKey) => {
  switch (currentKey) {
    case "trademark":
      return "brand";
    case "Categoria":
    case "Categorías":
    case "Departamentos":
      return "category";
    default:
      return currentKey;
  }
};
